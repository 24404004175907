@use '@iq/react-components/dist/colors.scss' as colors;

.file-list-component {
  .file-component {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    .select-box {
      margin-left: 0.5rem;

      button {
        &:focus {
          outline: 0;
          box-shadow: none;
        }
        &:active:focus {
          outline: 0;
          box-shadow: none;
        }
      }

      .checkbox {
        cursor: pointer;

        &.checked {
          color: colors.$StatusBlue;
        }
      }
    }

    .filetype {
      .filetype-icon-component {
        width: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        flex-shrink: 0;
      }
    }

    .file-info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .filename {
        margin-right: 0.5rem;
        font-size: 0.8rem;
        cursor: pointer;
        word-break: break-all;
        padding-bottom: 0.25rem;
      }

      .file-extras-wrapper {
        flex-grow: 3;
        display: flex;
        align-items: center;
      }

      .file-pill {
        flex-grow: 3;
        margin-right: 0.5rem;

        > div {
          width: fit-content;
        }
      }

      .filesize {
        margin-left: auto;
        margin-right: 0;
        opacity: 0.75;
        font-size: 0.8rem;
        width: fit-content;
        white-space: nowrap;
      }

      @media only screen and (max-width: 1024px) {
        align-items: flex-start;
      }
    }
  }

  .bookmark-list-item {
    margin-bottom: 0;
    padding: 0.5rem 0 0.5rem 1.5rem;
    font-size: 0.8rem;

    &:last-child {
      margin-bottom: 1rem;
    }
  }
}

.light-theme {
  .file-list-component {
    > :nth-child(odd) {
      background: colors.$Grey5;
    }
    > :nth-child(even) {
      background: none;
    }
  }
}

.dark-theme {
  .file-list-component {
    > :nth-child(odd) {
      background: colors.$Grey85;
    }
    > :nth-child(even) {
      background: none;
    }
  }
}
