@use '@iq/react-components/dist/colors.scss' as colors;

.choice-step {
  margin-top: 2rem;
}

.import-option {
  margin-top: 1rem;
  height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.3rem;
}

.option-select-outer {
  width: 1rem;
  height: 1rem;
  border: 2px solid colors.$Grey20;
  border-radius: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-select-inner {
  width: 0.75rem;
  height: 0.75rem;
  background-color: colors.$Grey30;
  border-radius: 0.5rem;

  &.hidden {
    display: none;
  }
}

.dark-theme {
  .import-option {
    background: colors.$Grey85;
  }
}

.light-theme {
  .import-option {
    background-color: white;
  }
}
