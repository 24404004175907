@use '@iq/react-components/dist/colors.scss' as colors;

.avt-react-components--tabs {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .avt-react-components--action {
    margin-left: auto;
  }
}

.avt-react-components--tab {
  margin-right: 1rem;
  font-weight: 500;
  padding: 0.15rem 0;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  line-height: 1.5;

  &.active {
    color: colors.$UIBlue;
    border-bottom-color: colors.$UIBlue;
  }
}
