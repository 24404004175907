@use '@iq/react-components/dist/colors.scss' as colors;

.page-last-updated-at-component {
  color: colors.$Grey60;
  font-size: 80%;
}

.dark-theme {
  .page-last-updated-at-component {
    color: colors.$DarkApplicationPrimaryText;
  }
}
