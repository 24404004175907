@use '@iq/react-components/dist/colors.scss' as colors;

.viewer-component {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .confirmation-dialog-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .confirmation-dialog {
    top: 0;
    display: flex;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    max-width: 45vw;
    position: absolute;
    z-index: 10;

    .title {
      display: none;
    }

    .body {
      padding: 0.5rem 0;

      span {
        font-weight: 700;
      }
    }

    .actions {
      width: auto;
      margin-left: 1rem;
    }
  }

  .avt-pushpins-wrapper .pushpins--tooltip-wrapper {
    padding: 1rem;
    flex-direction: column;
    max-width: 25rem;
    min-width: 18rem;
    border-radius: 0.2rem;

    .pushpin-tooltip--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pushpin-tooltip--description {
        margin-right: 1rem;
        max-width: 85%;

        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        div:first-child {
          font-size: 1.2rem;
          margin-bottom: 0.2rem;
        }
      }
    }

    .pushpin-tooltip--status {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      padding: 0.5rem;
      border-radius: 0.2rem;
      color: colors.$Grey0;

      .pushpin-tooltip--status--icon {
        margin-right: 0.5rem;
      }

      .pushpin-tooltip--status--text {
        white-space: initial;
      }
    }

    .pushpin-tooltip--values {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .suggestion-description > div {
        font-size: 0.75rem;
        font-weight: 500;
        white-space: normal;
        width: 100%;
      }

      .suggestion-description > div:first-child {
        font-weight: 900;
      }
      .suggestion-description {
        .description-text {
          overflow: auto;
          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
        }
      }
      .value-wrapper {
        width: calc(50% - 0.5rem);
        margin-top: 1rem;

        &:first-child {
          margin-right: 1rem;
        }
        &.invalid-data {
          .title {
            display: inline-flex;

            svg {
              margin-left: 2pt;
              width: 14px;
              height: 14px;
            }
          }
          .value {
            opacity: 0.2;
          }
        }

        .title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-transform: none;
        }

        .value {
          font-size: 1.2rem;

          .unit {
            font-size: 0.8rem;
          }
        }

        .suggestion-availability {
          font-size: 0.9rem;
          font-weight: 500;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .datetime {
          font-size: 0.75rem;
          margin-top: 0.25rem;
        }
      }
    }
  }
}

.light-theme {
  .viewer-component {
    .confirmation-dialog {
      box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.5);
    }

    .avt-pushpins-wrapper .pushpins--tooltip-wrapper {
      .pushpin-tooltip--description {
        div:last-child {
          color: colors.$LightApplicationSecondaryText;
        }
      }

      .pushpin-tooltip--values {
        .title,
        .datetime {
          color: colors.$LightApplicationSecondaryText;
        }
      }
    }
  }
}

.dark-theme {
  .viewer-component {
    .confirmation-dialog {
      box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.5);
    }

    .avt-pushpins-wrapper .pushpins--tooltip-wrapper {
      .pushpin-tooltip--description {
        div:last-child {
          color: colors.$DarkApplicationSecondaryText;
        }
      }

      .pushpin-tooltip--values {
        .title,
        .datetime {
          color: colors.$DarkApplicationSecondaryText;
        }
      }
    }
  }
}
