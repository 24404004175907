.hv-tab-content {
  .list-container {
    height: 100%;
  }

  .integration-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .blocked-item {
      margin: 0 0 0 1rem;
    }

    .dropdown-menu--trigger {
      border-radius: 0.3rem;
      padding: 0.1rem 0.5rem;
      font-size: 0.9rem;
      white-space: nowrap;
      display: flex;
      margin: 0;

      &-label {
        text-transform: capitalize;
      }
    }
  }
}

.transfer-button-spinner {
  width: 4rem;
}
