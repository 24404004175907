@use '@iq/react-components/dist/colors.scss' as colors;

.create-item-modal {
  .create-item-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }
  }

  .form-wide-errors {
    text-align: center;
    margin: 2rem auto;
    display: inline-block;
    padding: 0.5rem;
    color: #FFF;
    border-radius: 0.2rem;
    background: colors.$StatusRed;
  }
}
