@use '@iq/react-components/dist/colors.scss' as colors;

.site-filter-component {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 0.5rem;

  .avt-react-components--icon {
    opacity: 0.7;
    height: 12px;
    width: 12px;
  }

  input {
    height: 33px;
  }

  input + .avt-react-components--icon {
    position: absolute;
    top: calc(50% - 2px);
    right: 0.5rem;
    transform: translate(0, -50%);
    cursor: pointer;
    opacity: 1;
  }
}

.dark-theme {
  .site-filter-component {
    color: colors.$DarkApplicationTertiaryText;
  }
}

.light-theme {
  .site-filter-component {
    color: colors.$LightApplicationTertiaryText;
  }
}
