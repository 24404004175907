@use '@iq/react-components/dist/colors.scss' as colors;

.tags-view {
  .manage-heading {
    padding-left: 1rem;
  }

  &__panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  &__select-container {
    flex: 0 1 30rem;
    min-width: 15rem;
    padding: 0.5rem 1rem 0.5rem 0;
  }

  &__tag-actions {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1rem;

    > button:not(:last-child) {
      margin-right: 1rem;
    }

    .avt-react-components--spinner {
      margin-left: 0.5rem;
      color: colors.$DarkApplicationPrimaryText;
    }
  }
}

.confirmation-dialog {
  .tag-container {
    max-height: 23rem;
    margin-top: 1rem;
    overflow: auto;
  }

  .checking-tags {
    display: flex;
    align-items: center;

    span {
      margin-left: 1rem;
    }
  }

  .tag-connections {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 100%;
    padding: 0.3rem;

    span {
      padding-right: 0.5rem;

      &:not(:first-child) {
        color: colors.$StatusRed;
      }
    }
  }
}

.tag-edit-modal {
  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;

    > button:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
