@use '@iq/react-components/dist/colors.scss' as colors;

.version-list-item.styled-item {
  .styled-item__header__info .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.xout {
      text-decoration: line-through;
    }
  }

    .model-version-properties-container {
      display: flex;
    }

    .model-version-properties {
      flex: 1
    }

    .model-version-thumbnail {
      margin-right: 3.5rem;
      height: 10rem;
      width: 10rem;
      border: 2px solid colors.$Grey30;

      img {
        width: 100%;
      }

      .missing-thumbnail-text {
        display: flex;
        align-items: center;
        color: colors.$Grey60;
        height: 100%;
        text-align: center;
      }
    }
}

.dark-theme {
  .version-list-item.styled-item {
    background: rgba(#fff, 0.05);

    .json-editor-component {
      .prop-table .row,
      .key,
      .value {
        border: 1px solid colors.$Grey70;
      }
    }
  }
}
