@use '@iq/react-components/dist/colors.scss' as colors;

.gauge-visualization-component {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.invalid-data {
    .gauge-title {
      display: inline-flex;

      svg {
        margin-top: -2px;
        margin-left: 2px;
      }
    }

    .gauge-wrapper {
      opacity: 0.2;
    }
  }

  .gauge-header {
    position: relative;
    margin-bottom: 1rem;
    font-weight: 500;

    .viz-loading-spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .gauge-wrapper {
    position: relative;
    transform: translateY(12%);

    .value-wrapper {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 49.5%; // recharts responsive container sets width to 99%
      transform: translate(-50%, -50%);

      &--value {
        display: flex;
        align-items: flex-end;

        .value {
          font-size: 1.8rem;
        }

        .unit {
          display: inline-block;
          font-size: 0.9rem;
          margin-left: 0.2rem;
        }
      }
    }
  }

  .gauge-value-marker:last-child {
    display: none;
  }

  .recharts-surface {
    overflow: visible;
  }
}

.light-theme .gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey10;
  }

  .gauge-value-marker {
    > polygon {
      fill: black;
    }
  }
}

.dark-theme .gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey80;
  }
  .gauge-value-marker {
    > polygon {
      fill: colors.$Grey0;
    }
  }
}

#global-modal-container {
  .invalid-data {
    .gauge-header {
      button {
        display: none;
      }
    }
    .gauge-wrapper {
      opacity: 1;
    }
  }
}
