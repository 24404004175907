@use '@iq/react-components/dist/colors.scss' as colors;

.connectivity-container {
  background: white;
  box-shadow: 0 0.5rem 0.5rem #00000026;
  position: absolute;
  top: 100%;
  right: 0.8rem;
  padding: 2rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  border: 1px solid colors.$Grey10;

  .connectivity-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .connectivity-content {
    display: flex;
    flex-direction: column;
  }

  .source-connectivity-item {
    display: flex;

    .connectivity-item-container:first-child span {
      display: none;
    }
  }

  .connectivity-item-container {
    display: flex;
    align-items: center;
    line-height: 1.3rem;

    p {
      margin-left: 1rem;
      width: 8rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.online {
        color: colors.$StatusGreen;
      }

      &.offline {
        color: colors.$StatusRed;
      }

      &.unknown {
        color: colors.$Grey10;
      }
    }

    .connectivity-line {
      width: 5rem;
      height: 2px;
      margin-right: 2rem;

      &.online {
        background-color: colors.$StatusGreen;
      }

      &.offline {
        background-color: colors.$StatusRed;
      }

      &.unknown {
        background-color: colors.$Grey10;
      }
    }
    .connectivity-item {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      width: 10rem;
    }
  }
}

.dark-theme .connectivity-container {
  border: 1px solid colors.$Grey80;
  color: colors.$DarkApplicationPrimaryText;
  background-color: colors.$DarkApplicationPanelBackground;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.35);
}
