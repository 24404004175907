@use "@iq/react-components/dist/colors.scss" as colors;

.manage-task-view {
  padding-left: 4rem;

  .header,
  .panel {
    padding: 0 1rem;
  }
}

.dark-theme {
  .manage-task-view {
    .panel-updated-notice {
      background: colors.$DarkApplicationPanelBackground;
    }
  }
}

.light-theme {
  .manage-task-view {
    .panel-updated-notice {
      background: colors.$LightApplicationPanelBackground;
      border: 1px solid colors.$LightApplicationBorder;
    }
  }
}
