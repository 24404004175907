@use '@iq/react-components/dist/colors.scss' as colors;

.dark-theme {
  .panel-component.floating {
    background-color: rgba(colors.$DarkApplicationPanelBackground, 0.9);
    border: 1px solid colors.$Grey75;
  }
}

.light-theme {
  .panel-component.floating {
    background-color: rgba(#ffff, 0.9);
  }
}

.panel-component {
  border-radius: 0.25rem;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  position: relative;

  &.hide-panel {
    display: none;
  }

  &.viewer-component-type {
    height: 100%;
    // Keep a minimal padding to prevent the viewer from creating ghosting
    // effects due to rounded corners.
    padding: 0.1rem;
    overflow: hidden;
    &.full-screen {
      z-index: 0;
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &.full-screen {
    position: absolute;
    top: 0;
    left: 0.9rem;
    right: 0.9rem;
    bottom: 0.5rem;
    margin: calc(68px + 1rem) 0 0 4rem;
    width: auto;
    // 100vh - header - panel margins
    height: calc(100vh - 68px - 2rem);
  }

  .bound-error {
    height: 100%;
    min-height: 128px;

    &__panel-type {
      font-family: monospace;
      font-weight: lighter;
      padding: 0 3px 1px;
      border-radius: 4px;
    }
  }

  .panel-header {
    margin: 0 0 1rem;
    position: relative;
    display: flex;
    align-items: center;

    .sync-health-toggler {
      color: colors.$StatusOrange;
      display: flex;
      align-items: center;
      cursor: pointer;

      div:last-child {
        margin-left: 0.25rem;
      }
    }

    .tools {
      margin-left: auto;
      display: flex;
      align-items: center;

      button:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    &.transient {
      display: none;
    }

    &.floating {
      position: absolute;
      right: 1rem;
      top: 1rem;
      z-index: 10;
    }
    &.draggable {
      cursor: move;
    }

    &.persistent + .components-panel {
      height: calc(100% - 2rem);
    }

    .panel-title {
      font-size: 1rem;
      font-weight: 500;
      margin-right: 1rem;
    }
  }

  &.components-component-type {
    .panel-header.persistent {
      .panel-title,
      .tools {
        padding-bottom: 1rem;
      }
    }
    &.floating {
      position: absolute;
      height: calc(100% - 0.9rem - 65px) !important;
      width: 360px;
      top: 65px;
      right: 0.9rem;
    }
  }

  &.component-source-properties-component-type {
    width: 350px;
    &.floating {
      height: calc(100% - 0.9rem - 65px);
      position: absolute;
      top: 65px;
      left: 5rem;
    }
  }

  &:hover {
    .panel-header {
      display: flex;

      .tools {
        display: flex;
      }
    }
  }
}

.is-fullscreen .panel-component:not(.floating) {
  display: none;
}

.is-fullscreen .panel-component.full-screen {
  display: block;
}

.dark-theme .panel-component {
  background: colors.$DarkApplicationPanelBackground;

  .bound-error__panel-type {
    background-color: colors.$Grey80;
  }

  .tools a {
    color: colors.$DarkApplicationPrimaryText;
  }
}

.light-theme .panel-component {
  background: colors.$LightApplicationPanelBackground;
  border: 1px solid colors.$LightApplicationBorder;

  .bound-error__panel-type {
    background-color: colors.$Grey15;
  }

  .tools a {
    color: colors.$LightApplicationPrimaryText;
  }
}

.resizable {
  overflow: scroll;
  resize: both;
  max-width: 300px;
  max-height: 460px;
  border: 1px solid black;
  min-width: 50px;
  min-height: 50px;
  background-color: skyblue;
}
