@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

.files-panel-component {
  position: relative;
  height: 100%;
  width: 100%;

  &.updating-files {
    .file-grid-component,
    .file-table-component {
      opacity: 0.3;
    }
  }

  &__selector-actions {
    display: flex;
    justify-content: flex-end;

    > button {
      margin-left: 1rem;
    }
  }

  .overlay-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 1.0;
  }

  .no-files {
    padding-bottom: 1rem;
    text-align: center;
    opacity: 0.8;
    font-style: italic;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    padding: 0.466rem 0.5rem;
    background: transparent;
    border-radius: 0.2rem;
    font-size: 1rem;
  }

  .drop-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    position: relative;

    &:focus {
      outline: none;
    }

    .drop-zone {
      position: absolute;
      padding: 1rem;
      border: 2px dashed gray;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      z-index: 100;
    }
  }
}

.light-theme .files-panel-component {
  input {
    color: colors.$LightApplicationPrimaryText;
  }

  .drop-zone {
    background: color.adjust(colors.$LightApplicationPanelBackground, $alpha: -0.1);
  }
}

.dark-theme .files-panel-component {
  input {
    color: colors.$DarkApplicationPrimaryText;
  }

  .drop-zone {
    background: color.adjust(colors.$DarkApplicationPanelBackground, $alpha: -0.1);
  }
}

.active-drag-item {
  width: auto !important;
  color: #333;

  img {
    display: block;
  }
}

.confirmation-dialog {
  .filename-container {
    max-height: 23rem;
    margin-top: 1rem;
    overflow: auto;

  }

  .filename {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 100%;
    padding: 0.3rem;

    .filetype-icon-component {
      width: 1.2rem;
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    span {
      padding-right: 0.5rem;

      &:not(:nth-child(2)) {
        color: colors.$StatusRed;
      }
    }
  }
}
