@use '@iq/react-components/dist/colors.scss' as colors;

.components-panel {
  height: 100%;
  position: relative;

  &.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .components-panel-header {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

.dark-theme {
  .components-panel {
    .components-panel-header {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}

.light-theme {
  .components-panel {
    .components-panel-header {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}
