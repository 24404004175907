.step-navigator {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;

  > div:last-child {
    display: flex;
    justify-content: flex-end;

    > button:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  &__button-next {
    margin-left: 1rem;
  }
}
