@use '@iq/react-components/dist/colors.scss' as colors;

.manage-source-view {
  padding-left: 4rem;

  .header {
    padding-left: 1rem;
  }

  .panel {
    padding: 0 1rem;
  }
}

.avt-react-components--button.design-regular.activity-primary {
  &.button-create {
    background-color: colors.$StatusBlue;

    &--basic {
      background-color: colors.$StatusBlue;
    }

    &--advanced {
      background-color: colors.$WizardYellow;
    }
  }
}

.dark-theme {
  .manage-source-view {
    .panel-updated-notice {
      background: colors.$DarkApplicationPanelBackground;
    }
  }
}

.light-theme {
  .manage-source-view {
    .panel-updated-notice {
      background: colors.$LightApplicationPanelBackground;
      border: 1px solid colors.$LightApplicationBorder;
    }
  }
}
