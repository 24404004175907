@use '@iq/react-components/dist/colors.scss' as colors;

.permission-legend-component {
  .permissions-table {
    margin-top: 1rem;
    background-color: colors.$Grey0;
    padding: 1rem;
    border-top-right-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;

    table {
      line-height: 150%;
      width: 100%;

      th {
        color: colors.$LightApplicationSecondaryText;
        text-align: center;
        width: 20%;
        height: 2.5rem;
      }

      tbody {
        tr {
          height: 3rem;
        }

        td {
          vertical-align: middle;
        }

        tr > td:first-child {
          font-weight: 500;
          text-transform: capitalize;
        }

        td:not(:first-child) {
          color: colors.$StatusGreen;

          .avt-react-components--icon.s {
            margin: auto;
          }
        }
      }
    }
  }

  .loading-container {
    height: 5rem;
  }
}

.dark-theme {
  .permissions-table {
    background: colors.$Grey85;

    th {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}
