@use '@iq/react-components/dist/colors.scss' as colors;

.step-indicator {
  display: flex;
  color: black;
  justify-content: space-between;
  height: 4.5rem;
  width: 18rem;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  margin: 1rem 0 1rem 2rem;
  list-style: none;
  background: white;
  padding: 0 2rem 0 1rem;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;

  &__title-container {
    display: flex;
    align-items: center;
    width: 14rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &--disabled {
    color: colors.$Grey20;
    cursor: not-allowed;
  }

  &--active {
    background: colors.$LightApplicationBackground;
    box-shadow: none;

    &.step-indicator--advanced {
      .step-indicator__icon,
      .step-indicator__title {
        color: colors.$WizardYellow;
      }
    }

    &.step-indicator--basic {
      .step-indicator__icon,
      .step-indicator__title {
        color: colors.$StatusBlue;
      }
    }
  }

  &__completion {
    margin-left: 1rem;
    color: colors.$LightApplicationInputBorder;

    .avt-react-components--icon {
      height: 1.25rem;
      width: 1.25rem;
    }

    &.checked {
      color: colors.$StatusGreen;
    }
  }
}

.dark-theme {
  .step-indicator {
    color: colors.$DarkApplicationPrimaryText;
    background-color: colors.$DarkApplicationPanelBackground;

    &--disabled {
      color: colors.$Grey50;
    }

    &__completion {
      color: colors.$DarkApplicationInputBorder;

      &.checked {
        color: colors.$StatusGreen;
      }
    }
  }
}
