.video-preview-content {
  height: calc(100% - 5.2rem); // header (buttons + padding) + pad-btm
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 8rem);
  padding: 0 0 1rem 1rem;

  video {
    max-width: 100%;
    height: 100%;
  }

  &.no-padding {
    padding: 0;
  }

  &.media-loading video::-webkit-media-controls {
    visibility: visible;
  }

  &.media-playing video::-webkit-media-controls {
    visibility: hidden;
  }

  &.media-playing video::-webkit-media-controls-enclosure {
    visibility: visible;
  }
}
