@use '@iq/react-components/dist/colors.scss' as colors;

.rjsf {
  .form-group {
    margin-bottom: 1rem;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
  }

  .field-description {
    opacity: 0.65;
    margin-bottom: 1rem;
  }

  .form-group.field-array {
    padding: 1rem;
    background: #ffffff11;

    &.multi-select-array {
      padding: 0;
    }

    .row {
      padding: 1rem;
      background: #ffffff11;

      &:empty {
        display: none;
      }
    }

    .array-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      > *:first-child {
        flex: 1 1 100%;
      }
    }

    .array-item-toolbox {
      flex: 0 1 15rem;
      margin-left: 2rem;
    }

    .btn-add {
      margin-left: auto;
      width: 15rem;
    }
  }

  legend {
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .btn {
    box-sizing: border-box;

    &.btn-add {
      &::before {
        content: '+';
      }
    }

    &.array-item-remove {
      &::before {
        content: '-';
      }
    }

    &.array-item-move-up {
      &::before {
        content: '⬆';
      }
    }

    &.array-item-move-down {
      &::before {
        content: '⬇';
      }
    }
  }
}

.light-theme {
  .rjsf {
    .form-group.field-array {
      border: 1px solid colors.$Grey20;

      .row {
        border: 1px solid colors.$Grey20;
      }

      &.multi-select-array {
        border: none;
        background: none;

        .row {
          border: none;
        }
      }
    }
  }
}

.dark-theme {
  .rjsf {
    .form-group.field-array {
      border: 1px solid colors.$Grey70;

      .row {
        border: 1px solid colors.$Grey70;
      }

      &.multi-select-array {
        border: none;
        background: none;

        .row {
          border: none;
        }
      }
    }
  }
}
