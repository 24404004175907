@use '@iq/react-components/dist/colors.scss' as colors;

.popout-select {
  position: relative;

  .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
    appearance: none;
    background: transparent;
    border-style: solid;
    border: 1px solid;
    border-color: colors.$Grey20;
    border-radius: 0.3rem;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    color: colors.$LightApplicationSecondaryText;

    &.full-height {
      height: 38px;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:first-child) {
        margin-left: 0.5rem;
      }
    }

    &.theme-light:hover {
      border-color: colors.$Grey30;
    }

    .icon-container {
      height: 100%;
      color: colors.$Grey30;
    }

    &:hover {
      .icon-container.avt-react-components--icon.xs {
        color: colors.$Grey35;
      }
    }

    .pill-component {
      height: 0.8rem;
      border-radius: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.2rem;
      margin-left: 0.5rem;

      .text {
        margin: 0;
        padding: 0 0.2rem;
      }
    }

    &.round {
      border-radius: 50%;
      border-color: colors.$Grey25;
      padding: 0.2rem;
      width: 31px;
      height: 31px;
      display: flex;
      justify-content: center;

      &.full-height {
        height: 36px;
        width: 36px;
      }

      &.theme-light:hover {
        border-color: colors.$Grey35;
      }

      .avt-react-components--icon.s {
        height: 1.4rem;
        width: 1.4rem;
      }

      > div:first-child {
        position: absolute;
        top: -25%;
        right: -25%;
      }

      > div:not(:first-child) {
        margin-left: 0;
      }
    }

    &.theme-light:focus,
    &.theme-light:active,
    &.theme-light.active {
      outline: none;
      box-shadow: none;
      border-color: colors.$StatusBlue;
    }
  }

  .popout-select--select-wrapper {
    width: 100%;
    margin-top: 0.5rem;
    position: absolute;
    z-index: 2000;

    &.anchor-right {
      right: 0;
    }

    &.anchor-left {
      left: 0;
    }
  }

  .popout-select--select-mask {
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1999;
  }
}


.dark-theme {
  .popout-select {
    .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
      &.theme-dark {
        border-color: colors.$Grey70;
      }

      &.theme-dark:hover {
        border-color: colors.$Grey60;
      }

      &.theme-dark:focus,
      &.theme-dark:active,
      &.theme-dark.active {
        border-color: #36f;
      }
    }
  }
}

