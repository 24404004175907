@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container {
  .big-modal-overlay {
    position: fixed;
    z-index: 2000; // must be same or greater than PageHeader (.page-header-component)
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    overflow: auto;
    padding: 2rem;

    .big-modal-wrapper {
      box-sizing: border-box;
      border-radius: 0.25rem;
      min-width: 100%;
      flex: 1;
      padding: 1.5rem;
      display: flex;
      cursor: default;
    }
  }

  &:empty {
    display: none;
  }
}

body.no-scroll {
  position: fixed;
  overflow: hidden;
}

.light-theme {
  .big-modal-wrapper {
    background: colors.$LightApplicationBackground;
  }
}

.dark-theme {
  .big-modal-wrapper {
    background: colors.$DarkApplicationPanelBackground;
  }
}
