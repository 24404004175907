@use '@iq/react-components/dist/colors.scss' as colors;

.model-upload {
  &__drop-zone {
    flex-grow: 1;
    margin-bottom: 2rem;
    padding: 7rem;
    border: 2px dashed colors.$DarkApplicationSecondaryText;
    display: flex;
    justify-content: center;
    align-items: center;
    color: colors.$DarkApplicationSecondaryText;

    p {
      font-size: 1.25rem;
    }

    .avt-react-components--icon {
      margin-right: 0.5rem;
      flex-shrink: 0;
    }
  }

  &-file {
    &-wrapper {
      margin: 1rem 0;
      .avt-react-components--label {
        font-weight: 500;
      }
    }

    &-name {
      font-style: italic;
    }

    &-rejection {
      color: colors.$StatusRed;
      margin-bottom: 1rem;
    }
  }
}
