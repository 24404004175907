@use '@iq/react-components/dist/colors.scss' as colors;

.dark-theme {
  .styled-item {
    &.member-item {
      background: colors.$Grey85;
      a {
        color: colors.$DarkApplicationTertiaryText;
      }
    }
  }
}
