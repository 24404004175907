@use '@iq/react-components/dist/colors.scss' as colors;

.support-view-component {
  margin: 0 auto;
  padding-left: 4rem;

  .support-content {
    margin: 0 auto;
    max-width: 60rem;
    padding: 0 1rem;

    p {
      margin: 0.5rem 0;
    }

    img {
      max-width: 100%;
    }
  }

  .support-badge {
    border-radius: 0.25rem;
    padding: 2rem;
    margin: 2rem auto;
    line-height: 150%;
    width: 100%;
    box-sizing: border-box;
  }
}

.light-theme .support-badge {
  background: colors.$LightApplicationPanelBackground;
}

.dark-theme .support-badge {
  background: colors.$DarkApplicationPanelBackground;
}
