@use "@iq/react-components/dist/colors.scss" as colors;

.create-task-content {
  .form-wide-errors {
    text-align: center;
    margin: 2rem auto;
    display: inline-block;
    padding: 0.5rem;
    color: #fff;
    border-radius: 0.2rem;
    background: colors.$StatusRed;
  }

  > *:not(:first-child) {
    margin-top: 20px;
  }

  .upload-modal-choices {
    padding: 1.5rem;

    .upload-error {
      color: colors.$StatusRed;
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid colors.$Grey5;
      }

      .actions {
        margin-left: auto;
      }

      .meta {
        margin-right: 1rem;
        line-height: 130%;
      }
    }
  }

  .json-display-component {
    textarea {
      border-width: 1px;
      border-style: solid;
      border-color: colors.$Grey20;
      border-radius: 0.3rem;

      &:focus {
        box-shadow: 0 0 0 1px colors.$StatusBlue;
        border-color: colors.$StatusBlue;
      }
    }

    #root_triggerConditions,
    #root_outputLabels {
      border: 1px solid colors.$Grey20;
      padding: 1rem;
    }
  }
}

.dark-theme {
  .create-task-content {
    .json-display-component {
      textarea {
        border-color: colors.$Grey70;
        background-color: colors.$Grey90;
        color: colors.$DarkApplicationPrimaryText;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &::placeholder {
          color: colors.$DarkApplicationSecondaryText;
        }

        &:focus {
          box-shadow: 0 0 0 1px colors.$StatusBlue;
          border-color: colors.$StatusBlue;
        }
      }
    }

    #root_triggerConditions,
    #root_outputLabels {
      border: 1px solid colors.$Grey70;
      padding: 1rem;
    }

  }
}
