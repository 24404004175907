@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../scss/_variables.scss' as vars;

.notification {
  position: relative;

  &:not(.stacked) {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  .notification-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-radius: 0.2rem;
    width: 28rem;
    height: fit-content;
    height: -moz-fit-content;

    &.margin-left {
      margin-left: 105px;
    }
  }

  .notification--body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0 1rem;

    div:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .message {
      font-size: 14px;
    }

    .muted {
      font-size: 12px;
      opacity: 0.7;
    }
  }

  .clear,
  .expand {
    cursor: pointer;
  }

  &.removable {
    .notification--body {
      .title {
        width: calc(100% - 4rem);
      }
    }
  }

  &.filled {
    .notification-wrapper {
      &.event-info,
      &.event-warning {
        background-color: colors.$Grey0;
        border: 1px solid colors.$LightApplicationBorder;
      }

      &.event-info {
        .notification--icon {
          color: colors.$StatusBlue;
        }
      }
      &.event-warning {
        .notification--icon {
          color: colors.$StatusRed;
        }
      }

      &.info {
        background-color: colors.$StatusBlue;
        border-color: color.adjust(colors.$StatusBlue, $lightness: -10%);
        color: colors.$Grey10;
      }

      &.success {
        background-color: colors.$StatusGreen;
        border-color: color.adjust(colors.$StatusGreen, $lightness: -10%);
        color: colors.$Grey10;
      }

      &.warning {
        background-color: colors.$StatusOrange;
        border-color: color.adjust(colors.$StatusOrange, $lightness: -10%);
        color: colors.$Grey0;
      }

      &.error {
        background-color: colors.$StatusRed;
        border-color: color.adjust(colors.$StatusRed, $lightness: -10%);
        color: colors.$Grey10;
      }

      .avt-react-components--button.theme-dark.design-outlined.activity-secondary {
        &.clear,
        &.expand {
          border-color: colors.$DarkApplicationPrimaryText;
          color: colors.$DarkApplicationPrimaryText;
        }
      }
    }
  }

  &:not(.filled) {
    border-width: 1px;
    border-style: solid;

    .notification--icon {
      &.info,
      &.event-info {
        color: colors.$StatusBlue;
      }

      &.success {
        color: colors.$StatusGreen;
      }

      &.warning {
        color: colors.$StatusOrange;
      }

      &.error,
      &.event-warning {
        color: colors.$StatusRed;
      }
    }
  }

  &.stacked {
    position: absolute;
  }

  &.read {
    opacity: 0.7;
  }
}

.notifications-panel .notification.stacked {
  position: relative;
  width: auto;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.dark-theme {
  .notification {
    &:not(.filled) {
      background-color: colors.$Grey85;
      border-color: colors.$Grey80;

      .notification-wrapper {
        .avt-react-components--button.theme-dark.design-outlined.activity-secondary {
          &.clear,
          &.expand {
            border-color: colors.$Grey80;
            color: colors.$DarkApplicationSecondaryText;
          }
        }
      }
    }

    &.filled {
      .notification-wrapper {
        box-shadow: vars.$BoxShadowDark;
        &.event-info,
        &.event-warning {
          background-color: colors.$Grey85;
          border: 1px solid colors.$DarkApplicationInputBorder;
        }
      }
    }
  }
}

.light-theme {
  .notification {
    &:not(.filled) {
      background-color: colors.$Grey0;
      border-color: colors.$Grey10;

      .notification-wrapper {
        .avt-react-components--button.theme-dark.design-outlined.activity-secondary {
          &.clear,
          &.expand {
            border-color: colors.$Grey10 !important;
            color: colors.$LightApplicationSecondaryText !important;
          }
        }
      }
    }

    &:not(.stacked) {
      &.filled {
        .notification-wrapper {
          box-shadow: vars.$BoxShadowLight;
        }
      }
    }
  }
}
