@use '@iq/react-components/dist/colors.scss' as colors;

.manage-panels-component {
  .panel-list {
    display: flex;

    .panel-handles,
    .panel-editor {
      flex: 0 0 50%;
      box-sizing: border-box;
    }

    .panel-handles {
      padding: 0.5rem;
    }

    .panel-editor {
      padding: 1rem;

      .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 5rem;
        opacity: 0.5;
      }
    }
  }
}

.dark-theme {
  .manage-panels-component {
    .panel-list {
      background: rgba(#fff, 0.05);

      .panel-handle {
        background: colors.$Grey80;

        &.selected {
          background: colors.$Grey70;
        }
      }

      .panel-editor {
        background: colors.$Grey70;

        .json-editor-component {
          .prop-table .row,
          .key,
          .value {
            border: 1px solid colors.$Grey70;
          }
        }
      }
    }
  }
}

.light-theme {
  .manage-panels-component {
    .panel-list {
      background: colors.$Grey5;

      .panel-handle {
        background: colors.$Grey10;

        &.selected {
          background: colors.$Grey15;
        }
      }

      .panel-editor {
        background: colors.$Grey10;

        .json-editor-component {
          .prop-table .row,
          .key,
          .value {
            border: 1px solid colors.$Grey20;
          }
        }
      }
    }
  }
}
