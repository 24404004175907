@use '@iq/react-components/dist/colors.scss' as colors;

.pdf-preview-component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__error,
  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 1.5rem;
  }

  .page-guide-pages {
    text-align: center;
    width: 150px;
    padding-bottom: 10px;
    line-height: 40px;
  }

  .react-pdf__Document {
    display: flex;
    position: relative;

    .page-loader-component {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      position: absolute;
      top: 7px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .pages-preview {
      padding: 1rem 0.65rem 1rem 1rem;

      .preview-page {
        width: 150px !important;
      }

      .obtrusive-scrollbar {
        box-sizing: content-box;
        padding-right: 20px;
      }

      .page-number {
        display: flex;
        padding-top: 4px;
        justify-content: center;

        span {
          padding: 2px 3px;
          border-radius: 2px;
        }
      }

      .react-pdf__Page {
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 100% !important;
      }
    }

    .main-window {
      overflow: auto;
      padding-top: 1rem;
      width: 100%;

      .obtrusive-scrollbar {
        box-sizing: content-box;
        padding-right: 8px;
      }

      .page-guide {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 1rem 0.8rem 1rem;

        .title {
          flex: 1;
          font-size: 1.2rem;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 120%;
        }

        .change-file {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          margin-left: 0.5rem;
        }

        .pdf-tools-wrapper {
          width: 190px;
          display: flex;
          justify-content: center;
          > div {
            width: 150px !important;
          }
          .pdf-size
          {
            line-height: 1.3rem;
          }
        }
      }

      .zooming {
        >div {
          transform-origin: 0 0;
        }
      }

      div {
        box-sizing: border-box;
      }

      .react-pdf__Page__canvas {
        margin: auto;
      }

      .react-pdf__Page__textContent{
        left: 0 !important;
        top: 0 !important;
        transform: none !important;
        cursor: text;
      }
    }
  }
}

.pdf-preview-component:fullscreen .main-window .page-guide .title,
.pdf-preview-component:fullscreen .main-window .page-guide .change-file  {
  display: none;
}

.light-theme .file-preview-modal {
  .page-loader-component {
    background: rgba(colors.$LightApplicationBackground, 0.6);
  }

  .pages-preview {
    border-right: 1px solid colors.$LightApplicationBorder;

    .preview-page {
      canvas {
        padding: 7px;
      }

      &.active {
        canvas {
          padding: 0;
          border: 7px solid colors.$Grey30;
          border-radius: 4px;
        }

        .page-number span {
          color: colors.$Grey0;
          background-color: colors.$UIBlue;
        }
      }
    }
  }
}

.dark-theme .file-preview-modal {
  .page-loader-component {
    background: rgba(colors.$DarkApplicationBackground, 0.4);
  }
  .pages-preview {
    border-right: 1px solid colors.$DarkApplicationBorder;

    .preview-page {
      canvas {
        padding: 7px;
      }

      &.active {
        canvas {
          padding: 0;
          border: 7px solid colors.$Grey60;
          border-radius: 4px;
        }

        .page-number span {
          background-color: colors.$UIBlue;
        }
      }
    }
  }
}
