@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container {
  .simple-modal-container {
    .form-group.field.field-object {
      .date-time-picker {
        .absolute {
          width: 100%;
          padding: 0 1rem;
        }

        .select-period-component {
          width: 100%;
        }

        .title-actions {
          display: flex;
          div {
            margin-left: 0.5rem;
          }
        }

        .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1.25rem;
          align-items: flex-end;

          label {
            margin-bottom: 0;
            display: flex;
            align-items: flex-end;
          }
        }

        &.editing {
          .title {
            margin-bottom: 0.4rem;
          }

          &:not(.upper) {
            margin-bottom: 0.4rem;
            margin-top: 1.4rem;
          }
        }

        &__picker {
          display: flex;
          justify-content: space-between;

          .content {
            bottom: -1px;

            .absolute-time-picker {
              padding: 0;
            }

            .title {
              padding: 0;
            }
          }

          .date-picker {
            width: 50%;
            margin-right: 0.25rem;

            .SingleDatePicker {
              width: 100%;
            }

            .SingleDatePickerInput {
              display: flex;
              justify-content: space-between;

              input.DateInput_input {
                color: colors.$LightApplicationPrimaryText;
              }
            }
          }

          .time-picker {
            width: 50%;
            margin-left: 0.25rem;
            display: flex;
            justify-content: space-between;

            &__time {
              margin-top: 0;
            }
          }
        }

        .errors {
          color: colors.$StatusRed;
        }

        &.upper {
          margin-bottom: 0.5rem;
        }

        &:first-child:not(.upper) {
          margin-top: -0.5rem;
        }
      }
    }
  }
}

.dark-theme {
  #global-modal-container {
    .simple-modal-container .form-group.field.field-object .date-time-picker {
      .date-picker {
        .SingleDatePickerInput {
          input.DateInput_input {
            color: colors.$DarkApplicationPrimaryText;
          }
        }
      }
    }
  }
}
