@use '@iq/react-components/dist/colors.scss' as colors;

.site-detail-view-component {
  padding-left: 4rem;
  min-width: calc(768px - 4rem);
  a {
    color: rgba(#0000, 0.5);
  }
}

.quit-wizard {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  background-color: transparent;

  &.simple-modal-wrapper {
    background: transparent;
  }
}

.quit-wizard-content {
  display: flex;
  align-items: center;

  &--left,
  &--right {
    background-color: colors.$Grey5;
    height: 2rem;
    align-items: center;
    border-radius: 0.3rem;
    display: flex;
    padding: 0.2rem;
  }

  &--right {
    justify-content: center;
    min-width: 2rem;
    margin-left: 0.3rem;

    .avt-react-components--icon {
      transform: rotate(90deg);
    }
  }

  &--left {
    padding-right: 1rem;
    padding-left: 0.5rem;
    justify-content: space-between;

    .avt-react-components--icon {
      margin-right: 0.5rem;
    }
  }
}

.dark-theme {
  .quit-wizard-content {
    &--left,
    &--right {
      background-color: colors.$Grey85;
    }
  }
}
