@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container {
  .simple-modal-container {
    .simple-modal-overlay {
      .simple-modal-wrapper.component-modal {
        background: colors.$Grey0;
        max-height: 72vh;
        width: 100%;

        .simple-modal-body {
          .modal-column-2 {
            max-height: calc(72vh - 70px);

            .modal-column {
              .rjsf .field-object .form-group {
                margin-bottom: 2rem;

                @media only screen and (max-height: 1080px) {
                  margin-bottom: 1rem;
                }
              }

              &--content {
                padding: 1.25rem 2rem 2rem 2rem;
                height: calc(100% - 94px);

                .HvTab-root {
                  font-size: 1rem;
                }

                .tab-content {
                  padding-top: 2rem;
                  height: calc(100% - 5px);

                  &.custom-thin-scrollbar {
                    overflow-y: scroll;
                  }

                  &--header {
                    display: flex;
                    justify-content: flex-end;
                  }
                }

                .models-list-body {
                  .list-item__col {
                    .model-thumbnail {
                      height: 3rem;
                      width: 3rem;
                    }

                    &:nth-child(3) {
                      justify-content: center;
                    }
                  }
                }

                .list-container {
                  padding: 2rem 0 0;
                  height: calc(100% - 33px);
                  background-color: colors.$LightApplicationPanelBackground;

                  .custom-thin-scrollbar {
                    height: calc(100% - 48px);
                    overflow-y: scroll;
                  }
                }

                .empty {
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: colors.$LightApplicationSecondaryText;
                }
              }
            }

            & > .custom-thin-scrollbar {
              overflow-y: scroll;
            }
          }
        }

        .tooltip-container {
          cursor: help;

          .tooltip {
            background-color: colors.$Grey0;
            border: 1px solid colors.$LightApplicationInputBorder;
          }
        }
      }

      .simple-modal-wrapper.component-sub-modal {
        background: colors.$Grey0;
        width: 100%;
      }
    }
  }
}

.dark-theme {
  #global-modal-container {
    .simple-modal-container {
      .simple-modal-overlay {
        .simple-modal-wrapper.component-modal {
          background: colors.$Grey90;

          .simple-modal-body {
            .modal-column-2 {
              .modal-column {
                &--content {
                  .list-container {
                    background-color: colors.$Grey90;
                    border: none;
                  }

                  .empty {
                    color: colors.$DarkApplicationSecondaryText;
                  }
                }
              }
            }
          }

          .tooltip-container {
            .tooltip {
              background-color: colors.$Grey90;
              border: 1px solid colors.$DarkApplicationInputBorder;
            }
          }
        }

        .simple-modal-wrapper.component-sub-modal {
          background: colors.$Grey90;
        }
      }
    }
  }
}
