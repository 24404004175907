@use '@iq/react-components/dist/colors.scss' as colors;

.recurrence-container {
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .recurrence-options-container {
    margin-top: -1rem;

    label {
      font-weight: 400 !important;
      font-size: 0.9rem;
    }

    .recurrence-options-section:not(:first-child) {
      margin-top: 1rem;
    }
  }

  .toggle-recurrence.avt-react-components--button.theme-light.design-text.activity-primary {
    color: gray;

    .avt-react-components--icon.m {
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      top: -11px;

      .toggle-on {
        color: colors.$StatusBlue;
      }
    }
  }

  .interval-label {
    font-weight: 400;
  }

  .recurrence-options {
    display: flex;
    justify-content: space-around;

    .recurrence-interval {
      width: 3rem;
      margin-right: 1.64rem; // = space between days @2.55rem per
    }

    .frequency-select {
      width: 100%;
    }
  }

  .until-label {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.4rem;

    label {
      margin-bottom: 0;
    }
  }

  .until-container {
    .select-period-component .header {
      width: 100%;

      .label {
        display: flex;
        flex: 1;
        height: 21px;
        align-items: center;
      }
    }
  }

  .errors {
    color: colors.$StatusRed;
  }
}

.day-container {
  display: flex;
  justify-content: space-between;

  .avt-react-components--button.day-label {
    display: inline-block;
    font-size: 0.9rem;
    border: 1px solid colors.$Grey20;
    border-radius: 0.2rem;
    height: 2.55rem;
    width: 2.55rem;
    line-height: 2.55rem;
    text-align: center;
    cursor: pointer;
    color: colors.$LightApplicationPrimaryText;
    transition:
      background-color 0.2s,
      color 0.2s;

    &:hover {
      background-color: colors.$Grey20;
      color: colors.$Grey0;
    }

    &.selected {
      border-radius: 0.2rem;
      background-color: colors.$StatusBlue;
      color: colors.$Grey0;
    }
  }
}

#global-modal-container .simple-modal-container .form-group.field.field-string .until-container {
  .select-period-component {
    width: 100%;

    .content {
      width: 100%;
      padding-top: 1rem;
      bottom: -1px;

      .absolute {
        width: 100%;
        padding: 0 1rem;

        .title {
          padding: 0;
        }

        .absolute-time-picker {
          padding: 0;

          .date-picker {
            width: 50%;
            margin-right: 0.25rem;

            .SingleDatePicker {
              width: 100%;
            }

            .SingleDatePickerInput {
              display: flex;
              justify-content: space-between;
            }
          }

          .time-picker {
            width: 50%;
            margin-left: 0.25rem;
            display: flex;
            justify-content: space-between;

            &__time {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

.dark-theme {
  .toggle-recurrence {
    .toggle-on {
      color: colors.$LightApplicationBackground;
    }
  }

  .day-container {
    display: flex;
    justify-content: space-between;

    .avt-react-components--button.theme-dark.day-label {
      border: 1px solid colors.$Grey50;

      color: colors.$DarkApplicationPrimaryText;

      &:hover {
        background-color: colors.$LightApplicationBackground;
        color: colors.$LightApplicationPrimaryText;
      }
    }
  }
}
