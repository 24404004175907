@use '@iq/react-components/dist/colors.scss' as colors;

.component-import-step {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__button-row {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__info-trigger {
    cursor: help;
    display: flex;
    align-items: center;
    font-weight: 500;

    .avt-react-components--icon.m {
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 0.4rem;
    }
  }

  &__info-tooltip {
    display: none;
    background: white;
    width: 30rem;
    border: 1px solid colors.$Grey20;
    border-radius: 0.3rem;
    padding: 1rem;
    top: 0;
    right: 7rem;
    line-height: 1.2rem;

    p:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }

  &__drop-zone {
    flex-grow: 1;
    margin: 1rem 0;
    border: 2px dashed colors.$DarkApplicationSecondaryText;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      width: 45%;
      color: colors.$DarkApplicationSecondaryText;

      .avt-react-components--icon {
        margin-right: 0.5rem;
        flex-shrink: 0;
      }

      p {
        font-size: 1.25rem;
      }
    }
  }
}

.icon-green {
  color: colors.$StatusGreen;
}

.setup-wizard-component {
  &--basic .component-import-step {
    .icon-wiz-color {
      color: colors.$StatusBlue;
    }

    &__info-trigger {
      .avt-react-components--icon.m {
        color: colors.$WizardYellow !important;
      }

      &:hover + div {
        display: block;
        position: absolute;
      }
    }
  }

  &--advanced .component-import-step {
    .icon-wiz-color {
      color: colors.$WizardYellow;
    }

    &__info-trigger {
      .avt-react-components--icon.m {
        color: colors.$StatusBlue !important;
      }

      &:hover + div {
        display: block;
        position: absolute;
      }
    }
  }
}

.setup-wizard-errors.confirmation-dialog {
  .subtitle {
    h3 {
      text-transform: none;
    }
  }
}

.light-theme {
  .component-import-step {
    &__info-trigger {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme {
  .component-import-step {
    &__info-tooltip {
      background: colors.$Grey85;
      border-color: colors.$Grey80;
    }

    &__info-trigger {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}
