@use '@iq/react-components/dist/colors.scss' as colors;

.event-filters-component {
  position: relative;

  .header {
    border-radius: 0.25rem;
    padding: 0.5rem 0.7rem;
    cursor: pointer;
    align-items: center;
    box-sizing: border-box;
    width: 7rem;
    display: flex;
    justify-content: space-between;

    .icon-container {
      height: 100%;
      color: colors.$Grey30;
    }
  }

  .content {
    min-width: 20rem;
    z-index: 1000;
    box-sizing: border-box;
    display: none;
    white-space: nowrap;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    border-radius: 0.5rem;
    border: 1px solid colors.$Grey20;

    .headers {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid colors.$Grey20;
      .iot {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-inline: 1rem;
        padding-block: 0.5rem;
        border-right: 1px solid colors.$Grey20;

        .show-events {
          cursor: pointer;
          &.on {
            fill: colors.$StatusBlue;
          }
        }
      }
      .user {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-inline: 1rem;
        padding-block: 0.5rem;
        border-right: 1px solid colors.$Grey20;
        .show-events {
          cursor: pointer;
          &.on {
            fill: colors.$StatusBlue;
          }
        }
      }
    }
    .body {
      display: flex;
      .iot {
        width: 20rem;
        border-right: 1px solid colors.$Grey20;
        padding-top: 1rem;
        padding-inline: 0.75rem;
      }
      .user {
        width: 20rem;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-inline: 0.75rem;
        .quick-filter-title {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding-bottom: 0.25rem;
        }
        .json-display-component {
          #root {
            & > div:first-child {
              margin-bottom: 0;
            }
            & > div:nth-child(2) {
              border-bottom: 1px solid colors.$Grey20;
              padding: 0 0.75rem 1rem;
              margin-inline: -0.75rem;
            }
          }
        }
        .avt-react-components--button.design-text.activity-primary {
          &.theme-dark,
          &.theme-light {
            color: colors.$Grey50;
          }
        }
      }
    }

    @media screen and (max-width: 770px) {
      min-width: 12rem;
      width: 18rem;
    }

    .actions {
      display: flex;
      border-bottom: 1px solid colors.$Grey20;
      padding: 1rem;
      align-items: center;
      justify-content: space-between;
      .actions-button {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;
      }
    }
  }

  &.open {
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.dark-theme .event-filters-component {
  .header {
    background: colors.$DarkApplicationPanelBackground;
    color: colors.$DarkApplicationSecondaryText;
    border: 1px solid colors.$Grey70;
  }

  .content {
    background-color: colors.$DarkApplicationPanelBackground;
  }
}

.light-theme .event-filters-component {
  .header {
    color: colors.$LightApplicationSecondaryText;
    border: 1px solid colors.$Grey20;
  }
  .content {
    background-color: colors.$LightApplicationPanelBackground;
  }
}
