.tfr-view {
  .tfr-view-details {
    .signal-viewer {
      padding-left: 0px;
    }

    .select-period-component {
      .header {
        min-height: 38px;
        width: 100%;
        justify-content: flex-start;

        &:hover {
          border-color: #b3b3b3;
        }

        .label {
          display: flex;
          flex: 1;
          height: 21px;
          align-items: center;
        }

        > div:last-child {
          margin-left: 8px;
          margin-right: 0;
          position: relative;
          color: #cccccc;

          svg {
            transform: scale(1.4);
          }

          :hover {
            color: #999999;
          }
        }
      }
    }
  }
}
