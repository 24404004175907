@use '@iq/react-components/dist/colors.scss' as colors;

.page-header-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.9rem 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 2000;
  box-sizing: border-box;
  flex-direction: column;

  .page-header-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    box-sizing: border-box;
    min-width: 768px;
    flex-direction: row;
  }

  .page-header-left {
    display: flex;
    align-items: center;
  }

  .page-header-icon-wrapper {
    display: flex;
    align-items: center;
    // width is sidebar width - header padding
    width: 3rem;
    margin-right: 0.8rem;
    padding: 0 0.2rem;
    box-sizing: border-box;

    .avt-react-components--icon {
      width: 1.8rem;
      height: 1.8rem;
    }

    a {
      color: colors.$Grey70;
    }
  }

  .site-name {
    color: colors.$LightApplicationPrimaryText;

    a {
      text-decoration: none;
      color: colors.$LightApplicationPrimaryText;
    }

    a:visited {
      text-decoration: none;
      color: colors.$LightApplicationPrimaryText;
    }
  }

  .page-title-container.sites-list {
    display: flex;

    .page-title {
      height: 3rem;
      position: relative;
      left: -1.25rem;
      margin-right: 1rem;
    }
  }

  .page-title {
    display: flex;
    font-size: 1.25rem;
    margin: 5px 0px 0px 0px;

    &--breadcrumb {
      a,
      div {
        text-decoration: none;
        color: black;
        display: inline;
        cursor: pointer;
      }

      a:visited {
        text-decoration: none;
        color: black;
      }

      span {
        margin: 0 0.5rem;
      }
    }

    &--current {
      font-weight: 700;
    }
  }

  img.page-title {
    width: 8rem;

    @media only screen and (min-width: 1024px) {
      width: 12rem;
    }
  }

  .site-list-tools {
    display: flex;
    align-items: center;
    width: 30rem;
  }

  .tools {
    display: flex;
    align-items: center;

    svg {
      align-items: center;
      vertical-align: top;
      height: 18px;
      width: 18px;
    }

    > div:not(.user-info-panel):not(.notifications-panel) {
      min-width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
    }

    > .page-last-updated-at-component {
      min-width: 12rem !important;
    }

    .userinfo:last-child {
      margin-right: 1rem;
    }
  }
}

.dark-theme {
  .page-header-component {
    background: colors.$DarkApplicationPanelBackground;
    box-shadow: 0 0 0.5rem 0.2rem rgba(0, 0, 0, 0.4);

    .page-header-icon-wrapper {
      a {
        color: colors.$DarkApplicationPrimaryText;
      }
    }
    .site-name {
      color: colors.$DarkApplicationPrimaryText;

      a {
        text-decoration: none;
        color: colors.$DarkApplicationPrimaryText;
      }

      a:visited {
        text-decoration: none;
        color: colors.$DarkApplicationPrimaryText;
      }
    }

    .avt-react-components--button.theme-dark.design-regular.activity-secondary.refresh-variables-button {
      background: colors.$DarkApplicationPanelBackground;
      color: colors.$DarkApplicationSecondaryText;
    }

    &.sites-list {
      background: colors.$Grey85;
      border-bottom: solid 1px colors.$DarkApplicationBorder;
    }

    .header {
      background: colors.$Grey80 !important;
    }
  }

  .page-title {
    &--breadcrumb {
      a {
        text-decoration: none;
        color: colors.$Grey20;
      }

      a:visited {
        text-decoration: none;
        color: colors.$Grey20;
      }
    }

    .page-header-icon-wrapper a {
      color: colors.$Grey20;
    }
  }
}

.light-theme {
  .page-header-component {
    background: colors.$LightApplicationPanelBackground;
    box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid #e6e6e6;

    &.sites-list {
      background: colors.$LightApplicationPanelBackground;
      box-shadow: 0 0 0.3rem 0.1rem rgba(0, 0, 0, 0.15);
    }
  }
}

.page-setting {
  margin-top: 3px;
  margin-left: 5px;
  background: #ffffff00 !important;
}

.page-setting-title {
  display: flex;
}
