@use "@iq/react-components/dist/colors.scss" as colors;

.status-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .status-visualization-label {
    height: 1.25rem;
    font-size: 1.25em;
    margin-bottom: 0.25em;
  }

  > .avt-react-components--icon {
    width: 4rem;
    height: 4rem;
  }
}

.dark-theme .status-visualization-component {
  .label {
    color: colors.$DarkApplicationSecondaryText;
  }
  & svg {
    color: colors.$DarkApplicationRegularButton;
  }
}

.light-theme .status-visualization-component {
  .label {
    color: colors.$LightApplicationSecondaryText;
  }
  & svg {
    color: colors.$LightApplicationRegularButton;
  }
}
