@use '@iq/react-components/dist/colors.scss' as colors;

.custom-role-modal {
  .custom-role-modal-button-container {
    display: flex;
    justify-content: space-between;
  }

  .custom-role-form {
    padding: 1rem;
  }
}