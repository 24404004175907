@use "@iq/react-components/dist/colors.scss" as colors;

.site-thumbnail-component {
  height: 100%;

  img {
    width: 100%;
    height: auto;
  }

  .thumbnail-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: colors.$Grey5;
    background-color: colors.$Grey15;
    font-size: 2rem;
    text-transform: capitalize;
  }
}

.dark-theme {
  .site-thumbnail-component {
    .thumbnail-placeholder {
      border-color: colors.$DarkApplicationBorder;
      color: colors.$Grey60;
      background-color: colors.$Grey90;
    }
  }
}

.light-theme {
  .site-thumbnail-component {
    .thumbnail-placeholder {
      border-color: colors.$LightApplicationBorder;
    }
  }
}
