@use '@iq/react-components/dist/colors.scss' as colors;

.comment-form {
  margin-top: 0.2rem;

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__input {
    flex: 1;
  }

  .drop-zone-active {
    position: relative;
    &:after {
      content: "Drop attachments here";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(20,20,20,0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__attachment {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    button {
      flex: 0;
      margin-right: 0.5rem;
    }
    span {
      flex: 1;
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.5rem;

    .avt-react-components--button {
      margin-left: 0.5rem;
    }
  }

  &__comments {
    margin-top: 1rem;
  }

  &__comment {
    margin-bottom: 1.5rem;
    position: relative;
    border-radius: 4px;
    display: flex;

    &:last-child {
      margin-bottom: 1rem;
    }

    &:hover {
      .comment-form__comment-delete {
        display: block;
      }
    }
  }

  &__badge {
    height: 100%;
    margin-right: 1rem;

    div {
      margin-top: 1rem;
    }
  }

  &__content {
    flex: 1;
  }

  &__byline {
    display: flex;
    height: 1rem;
    font-size: 0.75rem;
    color: colors.$LightApplicationSecondaryText;
  }

  &__created-by {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__created-at {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    padding-left: 1rem;
  }

  &__body {
    white-space: pre-wrap;
    padding: 0.5rem;
    background-color: colors.$Grey5;
    border-radius: 0.2rem;
    position: relative;
    word-break: break-word;
  }

  &__comment-delete {
    display: none;
    position: absolute;
    right: 2px;
    top: 6px;
  }

  &__attachments {
    margin-top: 0.3rem;
    font-size: 0.75rem;
    color: colors.$LightApplicationSecondaryText;

    .event-file-link {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.dark-theme {
  .comment-form {
    &__byline {
      color: colors.$DarkApplicationSecondaryText;
    }

    &__body {
      background-color: colors.$Grey80;
    }

    &__attachments {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}
