@use '@iq/react-components/dist/colors.scss' as colors;

.file-upload-status {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  width: 350px;
  box-sizing: border-box;
  z-index: 10000;

  .header {
    padding: 1.25rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;

    .header-left {
      display: flex;
    }

    .label {
      box-sizing: border-box;
      padding-right: 0.5rem;
    }
  }

  .files {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-height: 300px;
    overflow: auto;

    .file {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem;
      box-sizing: border-box;

      .file-icon {
        flex: 0 1 auto;

        .filetype-icon-component {
          width: 1rem;
        }
      }

      .filename {
        padding: 0 1rem;
        flex: 1 0 auto;
        width: 60px;
        height: 18px;
        white-space: nowrap;
        vertical-align: middle;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-status {
        flex: 0 1 auto;
      }
    }
  }
}

.light-theme {
  .file-upload-status {
    border: 1px solid colors.$LightApplicationBorder;
    background: white;

    .files {
      border-top: solid 2px colors.$LightApplicationBorder;

      .file:not(:last-child) {
        border-bottom: solid 1px colors.$LightApplicationBorder;
      }
    }
  }
}

.dark-theme {
  .file-upload-status {
    border: 1px solid colors.$DarkApplicationBorder;
    background: colors.$DarkApplicationPanelBackground;

    .files {
      border-top: solid 2px colors.$DarkApplicationBorder;

      .file:not(:last-child) {
        border-bottom: solid 1px colors.$DarkApplicationBorder;
      }
    }
  }
}
