.section-component {
  margin: 0 0.5rem 0.6rem;

  .section-title {
    margin: 0 0.6rem 1rem;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .section-content {
    display: flex;
    height: 100%;

    > * {
      padding: 0 0.3rem;
      box-sizing: border-box;
      flex: 0 0 100%;
    }
  }

  &.align-height {
    .section-content {
      align-items: stretch;

      .column-component {
        .column-content {
          height: 100%;

          .panel-component {
            height: 100%;

            &.full-screen {
              height: calc(100% - 0.9rem - 65px);
            }

            .component-renderer-component {
              height: 100%;
              overflow: auto;
            }
          }
        }
      }
    }
  }

  &.with-2-columns .section-content {
    > *:first-child {
      flex-basis: 66.66666%;
      max-width: 66.66666%;
    }

    > *:last-child {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
  }

  &.with-3-columns .section-content {
    > * {
      flex-basis: 33.3333%;
      max-width: 33.3333%;
    }
  }

  &.with-4-columns .section-content {
    > * {
      flex-basis: 25%;
      max-width: 25%;
    }
  }

  &.with-5-columns .section-content {
    > * {
      flex-basis: 20%;
      max-width: 20%;
    }
  }
}
