@use '@iq/react-components/dist/colors.scss' as colors;

.add-user-modal-component {
  .add-user-modal {
    .top-toolbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 1rem;

      .avt-react-components--icon {
        cursor: pointer;
      }
      h2 {
        font-size: 1.2rem;
      }
    }

    .invite-row {
      display: flex;
      align-items: center;

      > div:nth-child(1) {
        width: 40%;
        margin-right: 1rem;

        .dropdown-menu--trigger {
          height: 21px;

          &.type-admin {
            background-color: colors.$StatusOrange !important;
          }
          &.type-contributor {
            background-color: colors.$StatusBlue !important;
          }
          &.type-viewer {
            background-color: colors.$StatusGreen !important;
          }
          &.type-collaborator {
            background-color: colors.$StatusMagenta !important;
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex: 0 1 100%;

        > div {
          flex-grow: 1;
        }
      }
    }

    .add-button {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}
