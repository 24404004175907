.version-options-wrapper {
  display: block;
  min-width: auto;
  position: relative;
  bottom: auto;
  margin: auto;

  .submit {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  .field {
    margin-bottom: 0.5rem;
  }

  .help {
    line-height: 1.2rem;
    padding-top: 0.25rem;
  }
}
