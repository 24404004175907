@use '@iq/react-components/dist/colors.scss' as colors;

.wizard-type-step {
  display: flex;
  justify-content: space-between;
  // height: 40rem; // revert when re-adding advanced wizard option
  height: 100%;

  .wizard-select {
    border-radius: 0.5rem;
    cursor: pointer;
    // width: calc(50% - 1rem); // revert when re-adding advanced wizard option
    width: 100%;
    transition: box-shadow 0.6s ease-in;
    border: 1px solid colors.$DarkApplicationBorder;

    &__vertical-container {
      display: grid;
      grid-template:
        'header' 2fr
        'content' 6fr
        'button' 1fr;
      height: calc(100% - 7rem);
      padding: 4rem 3rem 3rem;
    }

    &__header {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 2rem;
      margin-right: 2rem;
      align-self: center;
    }

    &__icon {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    &__heading {
      grid-area: header;
    }

    &__subheader {
      display: flex;
      justify-content: space-between;
      position: relative;

      > div:first-child {
        max-width: 70%;
      }

      .avt-react-components--icon {
        width: 1.25rem;
        height: 1.25rem;
      }

      &__info-trigger {
        cursor: help;
      }

      &__info-tooltip {
        display: none;
        background: white;
        width: 18rem;
        border: 1px solid colors.$Grey20;
        border-radius: 0.3rem;
        padding: 0.5rem;
        top: 0;
        right: 1.5rem;
      }
    }

    &__description,
    ul {
      padding-top: 2rem;
    }

    &__description {
      grid-area: content;
      min-width: 14rem;

      li {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        &.checked-step,
        &.checked-step--checked {
          > div:first-child .avt-react-components--icon {
            margin-right: 0.5rem;
            height: 1.25rem;
            width: 1.25rem;
          }

          div {
            flex-shrink: 0;
          }
        }

        &.checked-step--checked > div:first-child {
          color: colors.$StatusGreen;
        }
      }
    }

    &__button-container {
      grid-area: button;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    button {
      padding: 0 2rem;
    }

    &.wizard-select-advanced {
      .wizard-select__header {
        color: colors.$WizardYellow;
      }

      .wizard-select__subheader {
        &__info-trigger {
          color: colors.$WizardYellow;

          &:hover + div {
            display: block;
            position: absolute;
          }
        }
      }

      &:hover {
        box-shadow: inset 0px 0px 0px 1px colors.$WizardYellow;
      }

      button {
        background-color: colors.$WizardYellow;
      }
    }

    &.wizard-select-basic {
      .wizard-select__header {
        color: colors.$StatusBlue;
      }

      .wizard-select__subheader {
        &__info-trigger {
          color: colors.$StatusBlue;

          &:hover + div {
            display: block;
            position: absolute;
          }
        }
      }

      &:hover {
        box-shadow: inset 0px 0px 0px 1px colors.$StatusBlue;
      }

      button {
        background-color: colors.$StatusBlue;
      }
    }
  }
}

.light-theme {
  .wizard-select {
    background: colors.$LightApplicationPanelBackground;
    box-shadow: 0px 3px 6px #00000029;
    border: none;

    &__description li.checked-step > div:first-child {
      color: colors.$LightApplicationInputBorder;
    }
  }
}

.dark-theme {
  .wizard-select {
    &__description li.checked-step > div:first-child {
      color: colors.$DarkApplicationInputBorder;
    }
  }
}
