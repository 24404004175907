@use '@iq/react-components/dist/colors.scss' as colors;

.integration-modal-button-group {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.integration-modal {
  .integration-modal-header {
    margin-bottom: 1rem;

    > div:not(:last-child) {
      margin-bottom: 1rem;
    }

    .integration-modal-name,
    .template-picker {
      display: flex;
      align-items: center;

      h3 {
        margin-right: 1rem;
        width: 10rem;
      }

      input,
      .template-picker-select {
        width: 60%;
      }
    }
  }

  .integration-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.75rem;
    background: white;
    padding: 2rem;

    &:focus {
      outline: none;
    }

    #template-tabs {
      margin-bottom: 3rem;
    }

    .size-small {
      text-transform: capitalize;
    }

    .integration-config-loader {
      display: flex;
      height: 5rem;
      justify-content: center;
      align-items: center;
    }

    .tab-errors {
      color: colors.$StatusRed;
    }
  }

  .main-template {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    font-weight: 500;

    .avt-react-components--icon {
      margin-right: 0.5rem;
    }

    .checkbox {
      cursor: pointer;

      &.checked {
        color: colors.$StatusBlue;
      }
    }
  }

  .payload-errors {
    margin-left: 1rem;
    display: inline-block;
    color: colors.$StatusRed;
  }
}

.integration-cancel-modal {
  .integration-cancel-modal-text {
    margin: 2rem 0 3rem 0;
  }
}

.dark-theme {
  .integration-modal {
    background-color: colors.$Grey90;

    .integration-modal-content {
      background-color: colors.$DarkApplicationPanelBackground;
    }
  }
}
