@use '@iq/react-components/dist/colors.scss' as colors;

.components-panel--tree {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 2px;

  .tree-toolbar {
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    .avt-react-components--input {
      line-height: 1rem;
      height: calc(1rem + 1px);
      box-sizing: content-box;
      padding: 0.5rem;

      &:focus,
      &:active {
        outline: none;
        box-shadow: 0 0 0 1px colors.$StatusBlue;
        border-color: colors.$StatusBlue;
      }
    }

    .tree-filter {
      background-color: transparent;
      border-radius: 0.2rem;
      cursor: pointer;

      .avt-react-components--icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  & > .tree-root {
    overflow: auto;
    min-height: calc(100% - 4rem);
    width: 100%;

    &.full {
      min-height: 100%;
    }
  }

  .tree-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.2rem 0 0;
    box-sizing: border-box;
    user-select: none;
    width: 100%;
    position: relative;

    .avt-react-components--icon {
      cursor: pointer;
    }

    .tree-item-content {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 0.2rem 0.4rem;
      border-radius: 0.2rem;

      .content-left {
        display: flex;
        align-items: center;
        width: 100%;
        overflow: hidden;

        .component-type {
          width: 1rem;

          .avt-react-components--icon {
            margin-top: 3px;
            cursor: default;
          }
        }

        > div:not(:first-child) {
          margin-left: 0.7rem;
        }
      }

      .tree-item-name {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        cursor: default;
        width: calc(100% - 3.4rem);
        line-height: 1.3rem;

        > :first-child {
          padding-right: 0.5rem;
          white-space: nowrap;
        }

        .tree-item-name-long {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &.leaf .tree-item-name {
        width: calc(100% - 2.4rem);
      }

      .tree-item-model {
        .avt-react-components--icon {
          margin-left: 0.5rem;
          padding: 0 0.1rem;
          border-radius: 0.1rem;
        }

        &.active .avt-react-components--icon {
          background-color: colors.$StatusBlue;
          color: colors.$DarkApplicationPrimaryText;
        }

        &.viewer-loading {
          cursor: wait;

          .avt-react-components--icon {
            cursor: wait;
          }
        }
      }

      .content-right {
        display: none;
        justify-content: flex-end;
        align-items: center;
        width: 0%;
      }

      .tree-item-menu {
        display: none;
      }

      &.selected {
        background-color: colors.$StatusBlue;
        border: none;
        border-radius: 0.2rem;

        .tree-item-model.active .avt-react-components--icon {
          background-color: colors.$StatusBlue;
        }
      }

      &:hover,
      &.menu-active {
        .content-left {
          width: calc(100% - 2.5rem);
        }

        .content-right {
          display: flex;
          width: 2.5rem;

          &.status-icon-padding {
            padding-left: 0.9rem;
          }
        }

        .tree-item-menu {
          display: block;
        }
      }

      @function levelPad($level, $leaf: false, $base: 0.3rem, $leafBase: 1.4rem, $step: 0.8rem) {
        @if $leaf {
          @return ($step * ($level - 1)) + $leafBase;
        }
        @return ($step * ($level - 1)) + $base;
      }

      &.level-1 {
        padding-left: levelPad(1);

        &.leaf {
          padding-left: levelPad(1, true);
        }
      }

      &.level-2 {
        padding-left: levelPad(2);

        &.leaf {
          padding-left: levelPad(2, true);
        }
      }

      &.level-3 {
        padding-left: levelPad(3);

        &.leaf {
          padding-left: levelPad(3, true);
        }
      }

      &.level-4 {
        padding-left: levelPad(4);

        &.leaf {
          padding-left: levelPad(4, true);
        }
      }

      &.level-5 {
        padding-left: levelPad(5);

        &.leaf {
          padding-left: levelPad(5, true);
        }
      }

      &.level-6 {
        padding-left: levelPad(6);

        &.leaf {
          padding-left: levelPad(6, true);
        }
      }

      &.level-7 {
        padding-left: levelPad(7);

        &.leaf {
          padding-left: levelPad(7, true);
        }
      }

      &.level-8 {
        padding-left: levelPad(8);

        &.leaf {
          padding-left: levelPad(8, true);
        }
      }

      &.level-9 {
        padding-left: levelPad(9);

        &.leaf {
          padding-left: levelPad(9, true);
        }
      }

      &.level-10 {
        padding-left: levelPad(10);

        &.leaf {
          padding-left: levelPad(10, true);
        }
      }

      &.flat-list {
        padding: 0.5rem 1rem;
        border-radius: 0.2rem;
        margin-bottom: 0.3rem;

        .content-left {
          .component-type {
            width: 2rem;
          }

          .tree-item-name {
            flex-direction: column;
            align-items: flex-start;

            div:first-child {
              padding-bottom: 0.2rem;
            }
          }
        }

        .content-right {
          display: flex;
          width: 1.6rem;
        }
      }
    }

    .tree-item-menu {
      .tree-item-model {
        display: flex;

        .avt-react-components--icon {
          margin-left: 1rem;
          padding: 0 0.1rem;
          border-radius: 0.1rem;
        }

        &.active .avt-react-components--icon {
          background-color: colors.$StatusBlue;

          svg {
            color: colors.$DarkApplicationPrimaryText;
          }
        }

        &.viewer-loading {
          cursor: wait;

          .avt-react-components--icon {
            cursor: wait;
          }
        }
      }
    }
  }
}

.dark-theme {
  .components-panel--tree {
    .tree-toolbar {
      .avt-react-components--input {
        color: colors.$DarkApplicationSecondaryText;

        &:hover:not(:focus):not(:active) {
          border-color: colors.$Grey60;
        }

        &::placeholder {
          color: colors.$DarkApplicationSecondaryText;
        }
      }

      svg {
        color: colors.$DarkApplicationSecondaryText;
      }
    }

    .tree-filter-panel {
      border: 1px solid colors.$DarkApplicationBorder;
      background-color: colors.$Grey85;

      .filters {
        border-top: 1px solid colors.$DarkApplicationBorder;

        .filter-group {
          .avt-react-components--icon {
            .toggle {
              &.toggle-off {
                color: colors.$Grey20;
              }
            }
          }
        }
      }
    }

    .tree-item {
      .tree-item-content {
        svg {
          color: colors.$DarkApplicationPrimaryText;
        }

        .tree-item-name {
          .tree-item-name-long {
            color: colors.$DarkApplicationPrimaryText;
          }
        }

        .tree-item-model {
          .avt-react-components--icon {
            background-color: colors.$Grey70;
          }

          &.active .avt-react-components--icon {
            background-color: colors.$StatusBlue;
          }
        }

        &:hover:not(.selected),
        &.menu-active:not(.selected) {
          background-color: colors.$Grey85;
        }

        &.selected {
          .tree-item-name-long {
            color: colors.$DarkApplicationPrimaryText;
          }

          .tree-item-name div:last-child {
            color: colors.$UIBlue40 !important;
          }

          .tree-item-model.active .avt-react-components--icon {
            background-color: colors.$StatusBlue;
          }
        }

        &.flat-list {
          color: colors.$DarkApplicationPrimaryText;

          .tree-item-name {
            .tree-item-name-long {
              color: colors.$DarkApplicationPrimaryText;
            }

            div:last-child {
              color: colors.$DarkApplicationPrimaryText;
            }
          }
          &:not(.selected) {
            background-color: colors.$Grey85;
          }

          &:hover:not(.selected) {
            background-color: colors.$Grey75;
          }
        }
      }
    }
  }
}

.light-theme {
  .components-panel--tree {
    .tree-toolbar {
      color: colors.$LightApplicationSecondaryText;

      .avt-react-components--input {
        &:hover:not(:focus):not(:active) {
          border-color: colors.$Grey30;
        }

        &:focus,
        &:active {
          outline: none;
          box-shadow: 0 0 0 1px colors.$StatusBlue;
          border-color: colors.$StatusBlue;
        }

        &::placeholder {
          color: colors.$LightApplicationSecondaryText;
        }
      }

      svg {
        color: colors.$LightApplicationSecondaryText;
      }
    }

    .tree-filter-panel {
      border: 1px solid colors.$LightApplicationBorder;
      background-color: colors.$Grey0;

      .filters {
        border-top: 1px solid colors.$LightApplicationBorder;

        .filter-group {
          .avt-react-components--icon {
            .toggle {
              &.toggle-off {
                color: colors.$Grey70;
              }
            }
          }
        }
      }
    }

    .tree-item {
      .tree-item-content {
        svg {
          color: colors.$LightApplicationSecondaryText;
        }

        .tree-item-name {
          .tree-item-name-long {
            color: colors.$LightApplicationPrimaryText;
          }
        }

        .tree-item-model {
          .avt-react-components--icon {
            background-color: colors.$Grey20;
          }

          &.active {
            .avt-react-components--icon {
              background-color: colors.$StatusBlue;
            }

            svg {
              color: colors.$DarkApplicationPrimaryText;
            }
          }
        }

        &:hover:not(.selected),
        &.menu-active:not(.selected) {
          background-color: colors.$Grey10;
        }

        &.selected {
          .content-left {
            color: colors.$DarkApplicationPrimaryText;
          }

          svg {
            color: colors.$DarkApplicationPrimaryText;
          }

          .tree-item-name {
            .tree-item-name-long {
              color: colors.$DarkApplicationPrimaryText !important;
            }

            div:last-child {
              color: colors.$UIBlue40;
            }
          }

          .tree-item-model {
            svg {
              color: colors.$LightApplicationSecondaryText;
            }

            &.active {
              svg {
                color: colors.$DarkApplicationPrimaryText;
              }
            }
          }

          .tree-item-model.active .avt-react-components--icon {
            background-color: colors.$StatusBlue;
          }

          .selected {
            color: inherit !important;
          }
        }

        &.flat-list {
          color: colors.$LightApplicationPrimaryText;

          .tree-item-name {
            .tree-item-name-long {
              color: colors.$LightApplicationPrimaryText;
            }
          }

          &:not(.selected) {
            background-color: colors.$Grey5;
          }

          &:hover:not(.selected) {
            background-color: colors.$Grey10;
          }
        }
      }
    }
  }
}
