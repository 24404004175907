@use '@iq/react-components/dist/colors.scss' as colors;

.source-list-item.styled-item {
  .styled-item__body > button {
    margin-top: 0.5rem;
    margin-left: auto;
  }
}

.dark-theme {
  .source-list-item.styled-item {
    background: colors.$Grey85;

    .json-editor-component {
      .prop-table .row,
      .key,
      .value {
        border: 1px solid colors.$Grey70;
      }
    }
  }
}
