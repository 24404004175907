@use '@iq/react-components/dist/colors.scss' as colors;

.export-events-modal {
  .select-period-component {
    width: 100%;
  }

  .field-array {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
  }

  .actions {
    right: 31% !important;
  }
}

.event-export-modal-error--wrapper {
  display: flex;
  background-color: rgba(255, 115, 0);
  border-radius: 0.2rem;
  padding: 0.6rem;
  color: colors.$Grey0;
  margin-bottom: 1.3rem;
  align-items: stretch;

  .event-export-modal-error--icon {
    margin: 0.5rem;
  }

  .title {
    font-size: 15px;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 26px;
  }
}
