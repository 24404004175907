@use '@iq/react-components/dist/colors.scss' as colors;

.tenant-admin-panel {
  border-radius: 0.3rem;
  margin-top: 1rem;
  min-height: 90vh;

  .loading-container {
    height: 100%;
  }

  .list-selector {
    display: flex;
    padding: 1rem;

    > *:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .list-container {
    padding: 0;
    height: 100%;
  }

  .info-icon {
    color: colors.$StatusBlue;

    &--basic {
      color: colors.$StatusBlue;
    }

    &--advanced {
      color: colors.$WizardYellow;
    }
  }

  .role-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .blocked-item {
      margin: 0 0 0 1rem;
      overflow: visible;
    }

    .dropdown-menu--trigger {
      border-radius: 0.3rem;
      padding: 0.1rem 0.5rem;
      font-size: 0.9rem;
      white-space: nowrap;
      display: flex;
      margin: 0;

      &-label {
        text-transform: capitalize;
      }
    }
  }

  .avt-react-components--icon {
    .delete-icon {
      cursor: pointer;
    }
  }

  .avt-react-components--button.design-regular.activity-primary {
    &.role-description,
    &.button-create {
      background-color: colors.$StatusBlue;

      &--basic {
        background-color: colors.$StatusBlue;
      }

      &--advanced {
        background-color: colors.$WizardYellow;
      }
    }
  }

  .heading {
    input.user-filter {
      width: 18rem;
    }
  }
}

.light-theme {
  .tenant-admin-panel {
    background-color: colors.$LightApplicationPanelBackground;
  }

  .list-selector {
    background-color: colors.$Grey10;

    button.avt-react-components--button.active {
      background-color: colors.$Grey50;
      color: colors.$Grey0;
    }
  }

  .role-selector {
    .dropdown-menu--trigger {
      color: colors.$LightApplicationPrimaryText;
    }
  }
}

.dark-theme {
  .tenant-admin-panel {
    background-color: colors.$DarkApplicationPanelBackground;
  }

  .list-selector {
    background-color: colors.$Grey80;

    button.avt-react-components--button.active {
      background-color: colors.$Grey50;
      color: colors.$Grey0;
    }
  }

  .role-selector {
    .dropdown-menu--trigger {
      color: colors.$DarkApplicationPrimaryText;
    }
  }
}
