.job-list-item.styled-item {
  .job-status-bar {
    width: 1rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }

  .styled-item__header {
    cursor: default;
  }

  .job-status {
    width: 120px; // handles longest status, "DOWNLOADING"
  }


  .job-error {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;

    &__content {
      padding: 1rem;

      pre {
        margin-top: 10px;
        padding: 0.5rem;
      }
    }
  }
}
