.generate-qr-code {
  .redirect-button a {
    color: white;
  }

  .select-row {
    margin-bottom: 1rem;

    & > div > div > div > div {
      line-height: 1.3rem;
    }
  }
  h3 {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0px;
  }
}
