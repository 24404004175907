#global-modal-container .simple-modal-overlay .simple-modal-wrapper.event-file-modal-wrapper.size-m {
  max-width: 70rem;
}

.event-file-modal-wrapper {
  &__field-group,
  &__errors > div {
    margin-bottom: 1rem;
  }

  &__multi-field-group {
    display: flex;
    justify-content: space-between;

    > * {
      flex: 0 0 48%;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;

    button:first-child {
      margin-right: 1rem;
    }
  }
}

