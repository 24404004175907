@use '@iq/react-components/dist/colors.scss' as colors;

.site-create-view-component {
  display: flex;

  .avt-react-components--button.variation-with-icon-left .avt-react-components--icon,
  .avt-react-components--button.variation-with-icon-right .avt-react-components--icon {
    height: 16px;
    width: 16px;

    &:hover {
      opacity: 0.75;
    }
  }

  .loader-component {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.light-theme {
  .site-create-view-component {
    .loader-component {
      background: rgba(colors.$LightApplicationBackground, 0.6);
    }
  }
}

.dark-theme {
  .site-create-view-component {
    .loader-component {
      background: rgba(colors.$DarkApplicationBackground, 0.6);
    }
  }
}
