@use '@iq/react-components/dist/colors.scss' as colors;

.metric-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.preview {
    align-items: center;

    .wrapper {
      max-width: 20rem;
    }
  }

  &.invalid-data {
    .wrapper {
      .label {
        display: inline-flex;

        svg {
          margin-top: 1px;
          width: 16px;
          height: 16px;
        }
      }

      .value-wrapper {
        opacity: 0.2;
      }
    }
  }

  .wrapper {
    max-height: 10rem;
    min-width: 10rem;
    display: grid;
    grid-template-areas:
      'label label label label'
      'value value value icon';
    align-items: baseline;
    row-gap: 0.5rem;

    .label {
      display: block;
      font-size: 0.9rem;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 150%;
      position: relative;
      grid-area: label;
      top: 0.25rem;
    }

    .value-wrapper {
      font-weight: 200;
      display: flex;
      align-items: flex-end;
      grid-area: value;
      line-height: 1.8rem;

      .value {
        font-size: 1.8rem;
      }

      .unit {
        display: inline-block;
        font-size: 0.9rem;
        margin-left: 0.2rem;
      }
    }

    .avt-react-components--spinner,
    .avt-react-components--icon {
      grid-area: icon;
      justify-self: end;
    }
  }
}

.dark-theme .metric-visualization-component {
  .label {
    color: colors.$DarkApplicationSecondaryText;
  }
}

.light-theme .metric-visualization-component {
  .label {
    color: colors.$LightApplicationSecondaryText;
  }
}

#global-modal-container {
  .invalid-data {
    .wrapper {
      button {
        display: none;
      }

      .value-wrapper {
        opacity: 1;
      }
    }
  }
}
