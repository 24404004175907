@use "@iq/react-components/dist/colors.scss" as colors;

.upload-status-component {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border-radius: 0.2rem;
  padding: 1.5rem;
  background: white;
  z-index: 10000;
}

.progress-component {
  display: flex;
  align-items: center;

  .meta {
    .filename {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      padding-right: 1.0rem;
      box-sizing: border-box;
      margin-bottom: 0.5rem;
    }

    .status {
      text-transform: lowercase;
      color: colors.$Grey40;
      font-size: 0.9rem;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .icon {
    position: relative;
    height: 1.6rem;
    width: 1.6rem;
    margin-left: auto;
  }

  &:not(:first-child) {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid colors.$Grey5;
  }
}

.dark-theme {
  .upload-status-component {
    background: colors.$DarkApplicationPanelBackground;

    .progress-component {
      background: colors.$DarkApplicationPanelBackground;
    }

    .filename {
      color: colors.$DarkApplicationPrimaryText;
    }
  }
}

.light-theme {
  .upload-status-component {
    background: colors.$LightApplicationPanelBackground;
    border: 1px solid colors.$LightApplicationBorder;

    .progress-component {
      background: colors.$LightApplicationPanelBackground;
    }

    .filename {
      color: colors.$LightApplicationPrimaryText;
    }
  }
}
