@use '@iq/react-components/dist/colors.scss' as colors;

.polling-refresh {
}

.refresh-variables-button {
  background-color: transparent !important;
  .polling {
    animation: spinner--spin 1000ms infinite linear;
  }
}

@keyframes spinner--spin {
  0% {
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  50% {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dark-theme .refresh-variables-button {
  background: colors.$DarkApplicationPanelBackground;
  color: colors.$DarkApplicationSecondaryText;
}
