@use '@iq/react-components/dist/colors.scss' as colors;

.info-icon {
  color: colors.$StatusBlue;

  &--basic {
    color: colors.$StatusBlue;
  }

  &--advanced {
    color: colors.$WizardYellow;
  }
}

.avt-react-components--button.design-regular.activity-primary {
  &.role-description,
  &.button-create {
    background-color: colors.$StatusBlue;

    &--basic {
      background-color: colors.$StatusBlue;
    }

    &--advanced {
      background-color: colors.$WizardYellow;
    }
  }
}

#tabs-content-change {
  padding: 2rem 2rem 0rem 2rem;
}

.site-admin--member-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1rem;
}

.site-admin--member-view__content {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .list-container {
    padding: 0;
  }

  & > .list-item--header {
    display: flex;

    .avt-react-components--button {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border-radius: 0;
      font-weight: 500;
      padding-left: 0;
    }
  }

  .sync-column {
    color: colors.$StatusGreen;
  }

  .role-select-column {
    width: 19.5rem;
  }

  .ellipsed-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .empty-header-column {
    width: 7rem;
    display: flex;
  }

  .dropdown-menu {
    width: 100%;

    .dropdown-menu--trigger .dropdown-menu--trigger-content-left {
      width: 16rem;

      .dropdown-menu--trigger-label {
        display: block;
        width: 12rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .avt-react-components--icon {
    cursor: pointer;
  }

  .heading {
    input.user-filter {
      width: 16rem;
    }

    #role-filter div:first-child {
      line-height: 1.75rem;
      min-width: 9rem;
    }
  }
}

.invitation-tab {
  @container (width < 90rem) {
    .heading {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      & > :first-child {
        padding-top: 1rem;
        width: 100%;
        justify-content: flex-start;
      }

      & > :last-child {
        width: 100%;
        justify-content: flex-end;
      }
    }
  }
}

.light-theme {
  .site-admin--member-view {
    background-color: colors.$LightApplicationPanelBackground;
  }

  .list-item--header {
    .avt-react-components--button {
      background-color: colors.$LightApplicationBackground !important;
      color: colors.$LightApplicationPrimaryText !important;
    }
  }

  .empty-header-column {
    background-color: colors.$LightApplicationBackground;
  }
}

.dark-theme {
  .site-admin--member-view {
    background-color: colors.$DarkApplicationPanelBackground;
  }

  .list-container {
    background-color: colors.$Grey90;
  }

  .avt-react-components--input {
    &::placeholder {
      color: colors.$DarkApplicationSecondaryText !important;
    }
  }

  .list-item--header {
    .avt-react-components--button {
      background-color: colors.$Grey85 !important;
      color: colors.$DarkApplicationPrimaryText !important;
    }
  }

  .empty-header-column {
    background: colors.$Grey85;
  }
}
