@use "@iq/react-components/dist/colors.scss" as colors;

.file-list-bookmarks {
  margin-top: 0.5rem;
  margin-left: 1rem;

  .bookmark-list-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.2rem;
    align-items: center;
    cursor: pointer;

    .bookmark-name {
      line-height: 130%;
      display: flex;
      align-items: center;

      > p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 44rem;
      }

      .avt-react-components--icon {
        margin: 0 0.5rem;

        svg {
          fill: colors.$StatusBlue;
        }
      }
    }

    .bookmark-context {
      margin: 0 1rem;
      opacity: 0.7;
      line-height: 130%;
      flex-shrink: 0;
    }
  }
}
