#global-modal-container {
  .simple-modal-container {
    .simple-modal-overlay {
      .simple-modal-wrapper {
        &.export-progress-modal {
          margin-top: 5rem;

          .selected-signals-label {
            padding-top: 1rem;
            font-weight: bold;
          }

          .progress-text {
            display: flex;
            justify-content: center;
            padding-top: 2rem;
          }
          .progress-bar-initial {
            width: 100%;
            margin: 0.5rem 0rem 2rem 0rem;
            background-color: #ccced0;
          }
          .export-progress-content {
            margin-top: 1rem;
            overflow-x: hidden;
            max-height: 40rem;

            > div {
              padding: 0.5rem 0;
            }
          }

          .cancel {
            margin-top: 2rem;
          }
        }

        &.export-variables-modal {
          margin-top: 5rem;

          .tooltip-container {
            cursor: help;
          }
        }
      }
    }
  }
}
