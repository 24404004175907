.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: white;
}

.containerHeader {
  padding: 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.printButton {
  padding: '5rem';
  float: right;
  margin-left: 20px;
}

.containerBody {
  border-collapse: collapse;
}

.containerBody::after {
  display: ' ';
  clear: both;
}

.generatedQrComponent {
  padding: 2em;
  width: 100%;
  border-collapse: collapse;
  vertical-align: bottom;
}
.showCuttingLines .generatedQrComponent {
  border: 2px dashed gray;
}
.elemsInRow_1 td {
  font-size: 1.2em;
  width: 100%;
}
.elemsInRow_2 td {
  font-size: 1em;
  width: 50%;
}
.elemsInRow_3 td {
  font-size: 0.8em;
  width: 33.3%;
}
.elemsInRow_4 td {
  font-size: 0.6em;
  width: 25%;
}
.elemsInRow_5 td {
  font-size: 0.6em;
  width: 20%;
}
.elemsInRow_6 td {
  font-size: 0.5em;
  width: 16.6%;
}

.componentTitle {
  text-align: center;
}

.componentTitle > h2 {
  margin: 0px 0px 0.4em;
}
.titleLower {
  font-weight: normal;
}

.generatedQrComponent > img {
  max-width: 100%;
  min-width: 100%;
  height: auto;
  image-rendering: pixelated;
}

@media print {
  .containerHeader {
    display: none;
  }
  .generatedQrComponent {
    break-inside: avoid;
  }
  #global-message-container {
    display: none;
  }
  .base-view .panel {
    padding-bottom: 0px;
  }
}
