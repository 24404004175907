@use '@iq/react-components/dist/colors.scss' as colors;

#tabs-content-change {
  padding: 2rem 2rem 0rem 2rem;
}

.tenant-admin-content-tabs-container {
  padding: 1rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 4rem);

  .manage-integrations-body {
    padding: 0;
  }

  & > .list-item--header {
    display: flex;

    .avt-react-components--button {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      border-radius: 0;
      font-weight: 500;
      padding-left: 0;
    }
  }

  .sync-column {
    color: colors.$StatusGreen;
  }

  .ellipsed-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .empty-header-column {
    width: 7rem;
    display: flex;
  }
}
