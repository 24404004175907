@use '@iq/react-components/dist/colors.scss' as colors;

.bar-gauge-visualization-component {
  width: 100%;
  height: 100%;

  &.invalid-data {
    .bar-gauge-title {
      display: inline-flex;

      svg {
        margin-top: -2px;
        margin-left: 2px;
      }
    }
    .bar-gauge-wrapper {
      opacity: 0.2;
    }
  }

  .bar-gauge-header {
    position: relative;
    margin-bottom: 1rem;
    font-weight: 500;

    .viz-loading-spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .bar-gauge-wrapper {
    position: relative;
  }

  .bar-gauge-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &-item {
      display: flex;
      margin-left: 1rem;
      align-items: center;
      justify-content: space-between;

      .bar-gauge-legend-area-threshold,
      .bar-gauge-legend-linear-threshold {
        margin-right: 0.5rem;
      }
    }
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-default-tooltip {
    border-radius: 0.2rem;
    border-color: colors.$Grey10 !important;
  }

  // svg <text /> element
  .recharts-label {
    fill: colors.$Grey100;
  }
}

.light-theme .bar-gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey15;
  }
}

.dark-theme .bar-gauge-visualization-component {
  .recharts-bar-background-rectangle {
    fill: colors.$Grey80;
  }

  .recharts-default-tooltip {
    background-color: colors.$Grey85 !important;
    border: none !important;
  }

  .recharts-label {
    fill: colors.$Grey15;
  }
}

#global-modal-container {
  .invalid-data {
    .bar-gauge-header {
      button {
        display: none;
      }
    }
    .bar-gauge-wrapper {
      opacity: 1;
    }
  }
}
