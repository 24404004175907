@use '@iq/react-components/dist/colors.scss' as colors;

.rjsf .form-group.thumbnail-field {
  margin-top: 2rem;
}

.component-model-thumbnail {
  height: 10rem;
  width: 10rem;
  border: 1px solid colors.$Grey30;

  &.missing {
    display: flex;
    align-items: center;
    text-align: center;
    color: colors.$Grey60;
  }
}
