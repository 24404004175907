@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container .big-modal-overlay .big-modal-wrapper.model-edit-modal {
  padding: 0;
}

.model-edit-modal {
  display: flex;
  flex-direction: column;
  min-width: 800px;
  min-height: 300px;
  position: relative;
  justify-content: space-between;
  width: 100%;

  .top-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 1rem;

    .avt-react-components--icon {
      cursor: pointer;
    }

    h2 {
      margin: 0;
      font-size: 1.2rem;
      flex: 1;
    }
  }

  .model-edit-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: calc(100% - 1.5rem);

    &:focus {
      outline: none;
    }

    .model-viewer {
      .avt-progress-bar {
        left: 75px;
      }
    }
  }
}

.clear-mappings-modal {
  display: block;
  width: 35rem;
  min-width: auto;
  position: relative;
  bottom: auto;
  margin: auto;

  .body {
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    line-height: normal;

    > div:not(:last-child) {
      padding-bottom: 0.5rem;
    }

    > div:last-child {
      padding-top: 1.5rem;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }
}

.dark-theme {
  .model-edit-modal {
    .top-toolbar {
      border-bottom: 1px solid colors.$Grey85;
    }
  }
}

.light-theme {
  .model-edit-modal {
    .top-toolbar {
      border-bottom: 1px solid colors.$Grey10;
    }
  }
}
