@use '@iq/react-components/dist/colors.scss' as colors;

.state-visualization-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.preview {
    align-items: center;

    .wrapper {
      max-width: 20rem;
    }
  }

  &.invalid-data:not(.preview) {
    .wrapper {
      .label {
        display: inline-flex;

        svg {
          width: 16px;
          height: 16px;
        }

        .avt-react-components--icon {
          opacity: 1;
        }
      }
    }
    .state-text,
    .avt-react-components--icon {
      opacity: 0.2;
    }
  }

  .wrapper {
    display: grid;
    grid-template-areas:
      'icon label label'
      'name name name';
    grid-template-columns: 2.5rem;
    grid-template-rows: 2.5rem;
    align-items: center;

    .label {
      display: block;
      font-size: 0.9rem;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 150%;
      position: relative;
      grid-area: label;
    }

    .state-text {
      grid-area: name;
      font-weight: 600;
      font-size: large;
    }

    .avt-react-components--spinner,
    .avt-react-components--icon {
      grid-area: icon;
    }
  }
}

.dark-theme .metric-visualization-component {
  .label {
    color: colors.$DarkApplicationSecondaryText;
  }
}

.light-theme .metric-visualization-component {
  .label {
    color: colors.$LightApplicationSecondaryText;
  }
}
