#global-modal-container {
  .simple-modal-container {
    &.signal-viewer-template-modal,
    &.tfr-template-modal {
      z-index: 10000;

      .simple-modal-overlay {
        .simple-modal-wrapper {
          margin-top: 5rem;

          &.confirmation-dialog {
            margin-top: 5rem;

            &.delete-template {
              .template-name {
                font-weight: bold;
              }
            }

            .body {
              padding: 0.5rem 0;

              span {
                font-weight: 700;
              }
            }

            .actions {
              width: auto;
              margin-left: 1rem;
            }
          }
        }
      }

      .text-danger {
        margin-top: 0.5rem;
      }
    }
  }
}

