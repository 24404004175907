@use '@iq/react-components/dist/colors.scss' as colors;

.time-picker {
  border: 1px solid colors.$Grey20;
  border-radius: 0.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.466rem 0.5rem 0.466rem 0.3rem;
  background-color: colors.$Grey0;

  &__time {
    width: 7rem;
    display: flex;
    align-items: center;
    color: colors.$LightApplicationPrimaryText;
  }

  &:focus-within {
    border-color: colors.$StatusBlue;
    box-shadow: 0 0 0 1px colors.$StatusBlue;
  }

  .avt-react-components--icon.m {
    width: 20px;
    height: 20px;
  }
}

.dark-theme .time-picker {
  border: 1px solid colors.$Grey70;
  background-color: colors.$Grey90;

  &__time {
    color: colors.$DarkApplicationPrimaryText;
  }
}
