/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.site-edit-modal-component {
  .spinner-active {
    color: transparent !important;

    .avt-react-components--spinner {
      position: absolute;
      width: calc(100% - 19px);
    }
  }
}
