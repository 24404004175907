.delete-component {
  text-align: center;
  margin: 1rem 0;

  .title,
  .confirmation {
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    justify-content: center;

    .avt-react-components--button {
      margin: 0 0.5rem;
    }
  }
}
