.permission-denied-wrapper {
  color: red;
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
