@use '@iq/react-components/dist/colors.scss' as colors;

.file-bookmarks {
  overflow-y: auto;
  height: calc(100% - 56px);

  .no-bookmarks {
    opacity: 0.8;
    font-style: italic;
  }

  .bookmark-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid transparent;
    }

    .bookmark-name {
      line-height: 130%;
      cursor: pointer;
    }

    .bookmark-context {
      opacity: 0.7;
      line-height: 130%;
    }

    .bookmark-list-item--menu {
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }

    &:hover {
      .bookmark-list-item--menu {
        visibility: visible;
      }
    }
  }
}

.light-theme {
  .file-bookmarks {
    .bookmark-list-item {
      border-color: colors.$LightApplicationBorder;
    }
  }
}

.dark-theme {
  .file-bookmarks {
    .bookmark-list-item {
      border-color: colors.$DarkApplicationBorder;
    }
  }
}
