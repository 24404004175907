@use '@iq/react-components/dist/colors.scss' as colors;

.type-label-component {
  text-transform: uppercase;
  font-size: 0.8rem;
  color: #ffffff;
  font-weight: 500;
  padding: 0 0.4rem;
  border-radius: 0.15rem;
  height: 1.6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.type-page,
  &.type-contributor,
  &.color-blue {
    background-color: colors.$StatusBlue;
  }

  &.type-section,
  &.type-viewer,
  &.color-green {
    background-color: colors.$StatusGreen;
  }

  &.type-column,
  &.type-collaborator {
    background-color: colors.$StatusMagenta;
  }

  &.type-panel,
  &.type-admin,
  &.color-orange {
    background-color: colors.$StatusOrange;
  }

  &.color-red {
    background-color: colors.$StatusRed;
  }

  &.color-yellow {
    background-color: colors.$StatusYellow;
  }

  &.type-layout,
  &.color-grey {
    background-color: colors.$Grey50;
  }
}

