@use '@iq/react-components/dist/colors.scss' as colors;

.section-list-item.styled-item {
  .properties-title,
  .columns-title {
    font-weight: 500;
    margin: 1rem 0;
  }
}

.dark-theme {
  .section-list-item.styled-item {
    background: rgba(#fff, 0.075);

    .json-editor-component {
      .prop-table .row,
      .key,
      .value {
        border: 1px solid colors.$Grey80 !important;
      }
    }
  }
}
