@use '@iq/react-components/dist/colors.scss' as colors;

.cp-panel-component {
  &.empty {
    text-align: center;
    padding: 1rem;
  }

  .component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1.3rem;

    .component-name {
      font-size: 1.2rem;
      font-weight: 500;
    }
    .component-id {
      margin-top: 0.2rem;
    }

    .avt-react-components--icon {
      margin-right: 0.3rem;
    }
  }

  .properties {
    table {
      table-layout: fixed;
      width: 100%;

      th {
        color: colors.$Grey50;
        text-align: left;
        font-weight: 400;
        padding: 0.5rem 0;
      }

      td {
        padding: 0.5rem;
      }

      &:not(:first-child) {
        th {
          padding-top: 1rem;
        }
      }
    }
  }
}

.light-theme {
  .cp-panel-component {
    tr.property:nth-child(odd) {
      td {
        background-color: colors.$Grey5;
      }
    }
  }
}

.dark-theme {
  .cp-panel-component {
    tr.property:nth-child(odd) {
      td {
        background-color: colors.$Grey85;
      }
    }
  }
}
