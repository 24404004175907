@use '@iq/react-components/dist/colors.scss' as colors;

.visualizationeditor-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .visualization-header {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    box-sizing: border-box;

    .name {
      font-size: 1.1rem;
      font-weight: medium;
    }

    .avt-react-components--button.variation-with-icon-left .avt-react-components--icon,
    .avt-react-components--button.variation-with-icon-right .avt-react-components--icon {
      cursor: pointer;
      height: 12px;
      width: 12px;
    }

    .avt-react-components--button.variation-with-icon-right .avt-react-components--icon {
      position: relative;
      bottom: 5px;
    }
  }

  .visualization-preview {
    height: 35%;
    max-height: 500px;
    min-height: 100px;
  }

  .reset-save {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }

  .visualization-config {
    box-sizing: border-box;
    padding-top: 1.5rem;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .avt-react-components--tabs {
      flex: 0 0 auto;
      background-color: transparent;
    }

    .wrapper {
      box-sizing: border-box;
      flex: 0 1 100%;
      overflow: auto;

      .hidden {
        display: none;
      }
    }

    &--no-variables,
    &--loading {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -30%;
      position: relative;
    }

    .field-range-wrapper {
      display: flex;
      flex-direction: column;
    }
    input[type='range'] {
      width: auto;
    }
  }

  .is-template-checkbox {
    fill: colors.$LightApplicationPrimaryText;

    &.on {
      fill: colors.$StatusBlue;
    }
  }
}

.visualization-type-selector-component {
  display: grid;
  grid-template-columns: repeat(auto-fit, 6rem);
  grid-gap: 1rem;
  margin-bottom: 2rem;

  .visualization-type {
    height: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0.2rem;
    border: 1px solid transparent;

    .type {
      margin-top: 0.5rem;
    }

    &.selected {
      border-color: colors.$UIBlue;
    }
  }
}

.light-theme {
  .visualization-type-selector-component {
    .visualization-type:not(.selected) {
      border-color: colors.$LightApplicationBorder;
    }
  }
}

.dark-theme {
  .visualization-type-selector-component {
    .visualization-type:not(.selected) {
      border-color: colors.$DarkApplicationBorder;
    }
  }

  .is-template-checkbox {
    fill: colors.$Grey20;

    &.on {
      fill: colors.$StatusBlue;
    }
  }
}
