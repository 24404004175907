@use '@iq/react-components/dist/colors.scss' as colors;

.visualizations-panel-header {
  margin-bottom: 1rem;

  .visualizations-panel-toolbar {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    .signal-viewer-tools {
      display: flex;
      align-items: center;
      button {
        margin-left: 0.5rem;
      }
    }

    &.primary {
      .filter-tools {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        gap: 0.5rem;

        .avt-react-components--input {
          line-height: 1rem;
          height: calc(1rem + 1px);
          box-sizing: content-box;
          padding: 0.5rem;
          width: 12rem;

          &:hover:not(:focus) {
            border-color: colors.$Grey30;
          }

          &::placeholder {
            color: colors.$LightApplicationSecondaryText;
            font-weight: 400;
          }
        }
      }
    }

    .select-period-component .header {
      width: auto;

      .label {
        white-space: nowrap;
      }
    }
    .signal-viewer-tools-select-all-label {
      display: flex;
      .select-all {
        &.checked {
          color: colors.$StatusBlue;
        }
      }
      .title {
        margin-left: 0.5rem;
      }
    }
  }

  .avt-react-components--input.visualizations-panel-search {
    flex: 1 1 100%;
    width: 100%;
    max-width: 16rem;
    line-height: 1rem;
    height: calc(1rem + 1px);
    box-sizing: content-box;
    padding: 0.5rem;
  }

  @media only screen and (max-width: 1675px) {
    &.narrow {
      .visualizations-panel-toolbar.primary {
        flex-direction: column;

        .filter-tools {
          padding-top: 0.5rem;
          justify-content: flex-end;
          margin-left: 0;
          width: 100%;

          .visualizations-panel-search {
            margin-right: 0;
            max-width: none;
            min-width: 8rem;
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1275px) {
    &.narrow {
      .visualizations-panel-toolbar.primary {
        .filter-tools {
          flex-wrap: wrap;

          .visualizations-panel-search {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.dark-theme {
  .visualizations-panel-header {
    .visualizations-panel-toolbar {
      .filter-tools {
        .avt-react-components--input {
          &:hover:not(:focus) {
            border-color: colors.$Grey60;
          }

          &::placeholder {
            color: colors.$DarkApplicationSecondaryText;
            font-weight: 400;
          }
        }
      }
    }
  }
}
