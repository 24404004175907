@use '@iq/react-components/dist/colors.scss' as colors;

.edit-page-component {
  .properties-title,
  .sections-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 1rem 0;
  }

  .delete-page {
    padding: 1rem;
    text-align: center;
  }
}

.dark-theme {
  .edit-page-component {
    .json-editor-component {
      .prop-table .row,
      .key,
      .value {
        border: 1px solid colors.$Grey90;
      }
    }
  }
}
