@use '@iq/react-components/dist/colors.scss' as colors;

.user-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  height: 2rem;
  width: 2rem;
  z-index: 2;
  font-size: 0.85rem;
}

.dark-theme {
  .user-badge {
    background-color: colors.$UIBlue60;
  }
}

.light-theme {
  .user-badge {
    background-color: colors.$UIBlue20;
  }
}
