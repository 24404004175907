@use '@iq/react-components/dist/colors.scss' as colors;

.sidebar-component {
  position: fixed;
  top: 4.5rem;
  bottom: 0;
  left: 0;
  width: 4rem;
  overflow: hidden;
  transition: width 300ms ease-in-out;
  z-index: 2000;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;

  nav {
    padding: 0.6rem 0;
    box-sizing: border-box;

    &.main-nav {
      flex: 1 1 auto;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 1.5rem 0;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.bottom-nav {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    ul {
      display: flex;
      flex-direction: column;

      &.pages {
        li:first-child {
          padding-top: 0;
          margin-top: 0;
        }
      }

      li {
        width: auto;
        transition: width 300ms ease-in-out;
        padding: 0.3rem 1.2rem;
        margin-bottom: 1rem;

        &:first-child {
          margin-top: 0.3rem;
        }

        &:last-child {
          margin-bottom: 0.3rem;
        }

        &.disabled {
          opacity: 0.4;
        }

        &.separator {
          height: 1px;
          padding: 0;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          text-align: center;

          &:active,
          &:focus {
            outline: 0;
          }

          .avt-react-components--icon {
            padding: 0 0.15rem;
            width: 1.8rem;
            height: 1.8rem;
            flex: 0 0 auto;
          }

          span {
            opacity: 0;
            flex: 0;
            margin-left: 0.5rem;
            white-space: nowrap;
            transition: opacity 200ms ease;
          }
        }
      }
    }
  }

  .user-badge-component {
    .user-avatar {
      margin-left: 0.1rem;
    }

    .user-info-text {
      visibility: hidden;
    }
  }

  .toggle-errors,
  .toggle-settings {
    margin-bottom: 0.2rem;
  }

  .toggle-notifications {
    position: relative;
  }

  .unread-notifications-label {
    background: red;
    width: 14px;
    height: 14px;
    position: absolute;
    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 32px;
    bottom: 0;
  }

  &:hover {
    width: 18rem;

    nav ul li a {
      span {
        opacity: 1;
      }
      div.filename {
        opacity: 1;
        flex: auto;
      }
    }

    .user-badge-component {
      .user-info-text {
        visibility: visible;
      }
    }
  }

  &.collapse {
    width: 4rem;

    nav ul li a {
      span {
        opacity: 0;
      }
    }

    .user-badge-component {
      .user-info-text {
        visibility: hidden;
      }
    }
  }
}

.dark-theme .sidebar-component {
  background: colors.$DarkApplicationPanelBackground;
  color: colors.$Grey20;
  border-right: 1px solid transparent;

  &:hover {
    box-shadow: 5px 0 40px rgba(0, 0, 0, 0.5);
  }

  li {
    &.separator {
      background: colors.$DarkApplicationBorder;
    }

    a {
      color: colors.$Grey20;

      &:hover {
        color: colors.$Grey0;
      }

      &.active {
        color: colors.$UIBlue;
      }
    }
  }
}

.light-theme .sidebar-component {
  background: colors.$LightApplicationPanelBackground;
  color: colors.$Grey90;
  border-right: 1px solid colors.$LightApplicationBorder;

  li {
    &.separator {
      background: colors.$LightApplicationBorder;
    }

    a {
      color: colors.$Grey70;

      &:hover {
        color: colors.$Grey90;
      }

      &.active {
        color: colors.$UIBlue;
      }
    }
  }
}
