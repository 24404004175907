.panel-pagination {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 0.5rem;
  }
}
