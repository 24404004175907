@use '@iq/react-components/dist/colors.scss' as colors;

.system-message-item {
  padding: 20px;
  position: relative;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 20px;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.light-theme {
  .system-message-item {
    background: colors.$LightApplicationPanelBackground;
    border: 1px solid colors.$LightApplicationBorder;
  }
}

.dark-theme {
  .system-message-item {
    background: colors.$DarkApplicationPanelBackground;
    border: 1px solid colors.$DarkApplicationBorder;
  }
}
