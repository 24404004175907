@use '@iq/react-components/dist/colors.scss' as colors;

.files-panel-header {
  margin-bottom: 1rem;

  .files-panel-toolbar {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0.5rem;

    &.primary,
    &.secondary {
      > :not(:last-child) {
        margin-right: 0.5rem;
      }
    }

    &.primary {
      .files-panel-toolbar-btn-wrapper {
        display: flex;

        & > * + * {
          margin-left: 0.5rem;
        }
      }

      .filter-tools {
        display: flex;
        align-items: center;
        margin-left: auto;

        & > * + * {
          margin-left: 0.5rem;
        }

        .avt-react-components--input {
          line-height: 1rem;
          height: calc(1rem + 1px);
          box-sizing: content-box;
          padding: 0.5rem;
          width: 12rem;

          &:hover:not(:focus) {
            border-color: colors.$Grey30;
          }

          &::placeholder {
            color: colors.$LightApplicationSecondaryText;
            font-weight: 400;
          }
        }

        .filter-tools-select-wrapper {
          display: flex;

          & > * + * {
            margin-left: 0.5rem;
          }
        }
      }
    }

    &.secondary {
      margin: 1rem 0;
      padding-top: 1rem;
      border-top: 1px solid colors.$LightApplicationBorder;
      display: flex;

      .select-all-label {
        display: flex;
        align-items: center;
        height: 2.2rem;
      }

      .avt-react-components--icon {
        margin-right: 0.5rem;
      }

      .select-all {
        cursor: pointer;

        &.checked {
          color: colors.$StatusBlue;
        }
      }

      .handle-selected-files {
        display: flex;

        > :not(:last-child) {
          margin-right: 0.5rem;
        }

        > :first-child {
          margin-left: 0.5rem;
        }

        .avt-react-components--icon {
          margin: 0;
        }
      }
    }
  }

  .avt-react-components--input.files-panel-search {
    flex: 1 1 100%;
    width: 100%;
    max-width: 16rem;
    line-height: 1rem;
    height: calc(1rem + 1px);
    box-sizing: content-box;
    padding: 0.5rem;
  }

  &.narrow {
    .files-panel-toolbar.primary {
      button:nth-child(2) {
        padding: 0 0.4rem;

        .avt-react-components--icon {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 1275px) {
        .filter-tools {
          flex-wrap: wrap;

          .files-panel-search {
            margin-bottom: 0.5rem;
          }
        }
      }

      @media only screen and (max-width: 1675px) {
        flex-direction: column;

        .files-panel-toolbar-btn-wrapper {
          justify-content: space-between;
          width: 100%;
          margin-right: 0;
        }

        .filter-tools {
          padding-top: 0.5rem;
          justify-content: flex-end;
          margin-left: 0;
          width: 100%;

          .files-panel-search {
            margin-right: 0;
            max-width: none;
            min-width: 8rem;
            width: 100%;
          }
        }
      }
    }
  }

  &.wide {
    .files-panel-toolbar.primary {
      @media only screen and (max-width: 1024px) {
        flex-direction: column;

        .files-panel-toolbar-btn-wrapper {
          width: 100%;
          margin-right: 0;
        }

        .filter-tools {
          padding-top: 0.5rem;
          justify-content: flex-end;
          margin-left: 0;
          width: 100%;

          .files-panel-search {
            margin-right: 0;
            max-width: none;
            min-width: 8rem;
            width: 100%;
          }
        }
      }
    }
  }
}

.dark-theme {
  .files-panel-header {
    .files-panel-toolbar {
      .filter-tools {
        .avt-react-components--input {
          &:hover:not(:focus) {
            border-color: colors.$Grey60;
          }

          &::placeholder {
            color: colors.$DarkApplicationSecondaryText;
            font-weight: 400;
          }
        }
      }

      &.secondary {
        border-color: colors.$DarkApplicationBorder;
      }
    }
  }
}
