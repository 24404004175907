@use '@iq/react-components/dist/colors.scss' as colors;

.dark-theme {
  .column-list-item.styled-item {
    background: rgba(#fff, 0.05);

    .json-editor-component {
      .prop-table .row,
      .key,
      .value {
        border: 1px solid colors.$Grey70 !important;
      }
    }
  }
}
