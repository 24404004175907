@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

.file-upload-item {
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 20px;

  &:first-child {
    padding-top: 20px;
  }

  .button-row {
    margin-top: 10;
    margin-bottom: 10;
    display: flex;
    flex-direction: row;

    button {
      margin-right: 5px;
    }
  }

  .item-uploaded-overlay,
  .item-uploading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .file-info {
    & :not(:last-child) {
      margin-bottom: 15px;
    }

    .filename {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .filesize {
      font-size: 1rem;
    }
  }

  .column {
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;

    label {
      font-size: 1rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      line-height: 200%;
      font-weight: bold;
      padding-bottom: 0.8rem;
    }

    .error-text {
      padding-top: 0.8rem;
      font-size: 0.9rem;
      color: colors.$StatusRed;
    }
  }

  .confirm-delete-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    .row {
      margin-bottom: 10px;
    }
  }
}

.light-theme .file-upload-item {
  &:not(:last-child) {
    border-bottom: 2px solid colors.$LightApplicationBorder;
  }

  .file-upload-item {
    .item-uploaded-overlay,
    .item-uploading-overlay,
    .confirm-delete-overlay {
      background: color.adjust(colors.$LightApplicationBackground, $alpha: 0.8);
    }
  }
}

.dark-theme .file-upload-item {
  &:not(:last-child) {
    border-bottom: 2px solid colors.$DarkApplicationBorder;
  }

  .file-upload-item {
    .item-uploaded-overlay,
    .item-uploading-overlay,
    .confirm-delete-overlay {
      background: color.adjust(colors.$DarkApplicationBackground, $alpha: 0.8);
    }
  }
}
