@use '@iq/react-components/dist/colors.scss' as colors;

.file-upload-modal {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  position: relative;
  justify-content: space-between;
  width: 100%;

  .file-upload-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &:focus {
      outline: none;
    }

    .drop-zone {
      position: absolute;
      padding: 1rem;
      border: 2px dashed gray;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      z-index: 100;
    }
  }

  .file-upload-item-wrapper .file-detail:not(:first-child) {
    >div .copy-all {
      display: none;
    }
  }

  .form-wide-errors {
    text-align: center;
    margin: 2rem auto;
    display: inline-block;
    padding: 0.5rem;
    color: #FFF;
    border-radius: 0.2rem;
    background: colors.$StatusRed;
  }

  .bottom-toolbar {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding-top: 2rem;

    .primary-actions {
      margin-left: auto;
      display: flex;

      button {
        margin-left: 0.5rem;
      }
    }
  }

  @media (max-width: 30rem) {
    .bottom-toolbar {
    display: block;
    }

    button {
      margin: 0.5rem 0.5rem 0 0rem !important;
    }
 }
}

.light-theme .file-upload-modal {
  .drop-zone {
    background: colors.$LightApplicationBackground;
  }
}

.dark-theme .file-upload-modal {
  .drop-zone {
    background: colors.$DarkApplicationPanelBackground;
  }
}
