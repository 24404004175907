@use '@iq/react-components/dist/colors.scss' as colors;

.pie-visualization-component {
  width: 100%;
  height: 100%;

  .pie-header {
    position: relative;
    margin-bottom: 1rem;
    font-weight: 500;

    .spinner {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .pie-wrapper {
    position: relative;
  }

  .recharts-surface {
    overflow: visible;
  }

  .recharts-default-tooltip {
    border-radius: 0.2rem;
    border-color: colors.$Grey10 !important;
  }

  .pie-legend {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .pie-legend-item {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0 0.5rem;
      line-height: 1.3rem;

      svg {
        margin-right: 0.4rem;
      }
    }
  }
  .recharts-legend-wrapper {
    bottom: 0px !important;
  }
  .recharts-tooltip-label {
    margin: 0 !important;
  }
}

.dark-theme .pie-visualization-component {
  .recharts-default-tooltip {
    background-color: colors.$Grey85 !important;
    border: none !important;
  }

  .recharts-tooltip-item {
    color: #e6e6e6 !important;
  }
}
