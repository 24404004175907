@use '@iq/react-components/dist/colors.scss' as colors;

.substep-indicator {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  color: colors.$LightApplicationSecondaryText;

  &__step {
    cursor: pointer;
  }

  &__spacer {
    margin: 0 1rem;
    border-bottom: 1px solid colors.$Grey30;
    flex-grow: 1;
  }
}

.setup-wizard-component {
  &--basic {
    .substep-indicator__step--active {
      color: colors.$StatusBlue;
    }
  }

  &--advanced {
    .substep-indicator__step--active {
      color: colors.$WizardYellow;
    }
  }
}

.dark-theme {
  .substep-indicator {
    color: colors.$DarkApplicationPrimaryText;
  }
}
