@use '@iq/react-components/dist/colors.scss' as colors;

.text-preview-content {
  overflow: auto;
  width: 98%;
  height: 100%;
  max-height: calc(100% - 9rem);
  padding-left: 1rem;

  pre {
    white-space: pre-wrap;
    padding: 2rem;
    border-radius: 3px;
  }
}

.light-theme .text-preview-content {
  pre {
    background: colors.$LightApplicationBorder;
  }
}

.dark-theme .text-preview-content {
  pre {
    background: colors.$DarkApplicationBorder;
  }
}
