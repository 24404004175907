@use '@iq/react-components/dist/colors.scss' as colors;

.tfr-panel-component {
  width: 100%;
  box-sizing: border-box;

  .tfr-list-title {
    font-size: 1.2rem;
  }

  .tfr-online-selector {
    margin-bottom: 1rem;

    &--heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      align-items: center;

      input {
        width: 25%;
        min-width: 10rem;
      }
    }

    .tfr-list-loading {
      display: flex;
      align-items: center;

      .avt-react-components--spinner {
        margin-left: 1rem;
      }
    }

    table {
      width: 100%;

      thead {
        th {
          font-weight: normal;
          padding-bottom: 0.2rem;
          text-align: left;

          &.rate,
          &.name,
          &.duration,
          &.size {
            padding-left: 1rem;
          }
        }
      }

      tbody {
        tr {
          &:not(:first-child) {
            td {
              border-top: 1px solid transparent;
            }
          }

          td {
            text-align: left;
            overflow: hidden;
            padding: 0.6rem 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;

            &.rate,
            &.name,
            &.duration,
            &.size {
              padding-left: 1rem;
            }

            &.view {
              display: flex;
              text-align: right;
              padding-left: 1rem;
              .view-button {
                min-width: 53px;
                justify-content: center;
              }
              .avt-react-components--button {
                &:first-child {
                  margin-left: auto;
                }
                &:not(first-child) {
                  margin-left: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .tfr-local-selector {
    margin-bottom: 3rem;

    .tfr-list-title {
      margin-bottom: 2rem;
    }

    .select-files-component {
      display: flex;
    }

    .select-files--field {
      display: inline-block;
      border-radius: 0.25rem;
      padding: 0.6rem 1.2rem;
      margin-right: 1rem;
      cursor: pointer;
    }

    .trf-viewer--input {
      display: none;
    }
  }
}

.notifications--overlay {
  z-index: 2002;
  position: fixed;
  top: 7rem;
  left: 50vw;
  transform: translateX(-50%);
  width: 30rem;

  &:empty,
  &.hidden {
    display: none;
  }

  .collapser {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .notification-wrapper {
    .avt-react-components--button {
      &.clear,
      &.expand {
        border-color: colors.$DarkApplicationPrimaryText;
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }
}

.comtrade-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.tfr-view {
  .tfr-sidebar {
    height: 100%;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    border-right: 2px solid #e6e6e6;
    width: 45px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &.show {
      display: block;
      width: 350px;
    }
    .tfr-sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px 24px 12px;
      &.show div:nth-child(2) {
        display: block;
      }
      div:nth-child(2) {
        display: none;
      }
      div {
        cursor: pointer;
      }
    }
    .tfr-sidebar-body {
      display: none;
      &.show {
        display: block;
      }
      .tfr-online-selector--heading {
        padding: 0px 16px;
      }

      .tfr-list {
        .tfr-list-header {
          display: flex;
          justify-content: start;
          font-weight: 600;
          align-items: center;
          height: 3rem;
          margin: 0.25rem 0 -0.2rem 1rem;
        }

        .selected-tfr {
          background-color: colors.$LightApplicationBackground;
        }

        .tfr-item {
          height: 50px;
          border-bottom: 1.5px solid colors.$LightApplicationBackground;
          display: flex;
          align-items: center;
          padding: 0px 16px;
          font-size: 14px;
          width: 350px;
          cursor: pointer;
          &:hover {
            background-color: colors.$LightApplicationBackground;
          }
        }
      }
    }
  }
  .tfr-view-details {
    .comtrade-viewer {
      padding-left: 45px;
    }

    &.show {
      .comtrade-viewer {
        transition: 0.5s;
        padding-left: 350px;
      }

      .template-header {
        @media only screen and (max-width: 1390px) {
          display: block;
          .comtrade-metadata {
            margin-bottom: 10px;
            position: static;
          }
        }
      }
    }
  }
}

.tfr-page-loader {
  z-index: 3000;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .loader,
  .loader-text {
    transform: translate(0, 50%);
  }

  .loader-component.overlay {
    top: -33px;
    padding-left: 350px;
  }

  .avt-react-components--spinner svg {
    z-index: 2000;
    position: fixed;
  }
}

.dark-theme {
  .tfr-view {
    .tfr-sidebar {
      background: colors.$DarkApplicationBackground;
      border-right: 2px solid colors.$DarkApplicationBorder;
      .tfr-list {
        .selected-tfr {
          background-color: colors.$Grey75;
        }
        .tfr-item {
          border-bottom: 1.5px solid colors.$DarkApplicationBorder;
          &:hover {
            background-color: colors.$Grey75;
          }
        }
      }
    }
  }

  .tfr-online-selector table thead th {
    color: colors.$DarkApplicationSecondaryText;
  }

  .tfr-online-selector table tbody tr:not(:first-child) td {
    border-top-color: colors.$DarkApplicationBorder;
  }

  .tfr-local-selector .select-files--field {
    background: colors.$Grey80;
  }

  .comtrade-viewer {
    background: colors.$DarkApplicationBackground;
  }
}

.light-theme {
  .tfr-online-selector table thead th {
    color: colors.$LightApplicationSecondaryText;
  }

  .tfr-online-selector table tbody tr:not(:first-child) td {
    border-top-color: colors.$LightApplicationBorder;
  }

  .tfr-local-selector .select-files--field {
    background: colors.$Grey20;
  }

  .comtrade-viewer {
    background: colors.$LightApplicationPanelBackground;
  }
}
