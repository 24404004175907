.page-component {
  display: flex;
  flex-direction: column;
  // 100vh - header - panel margins
  min-height: calc(100vh - 69px - 2rem);
  box-sizing: border-box;
  margin-top: 1rem;

  > .bound-error {
    height: 90vh;
  }

  .dock-tools {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100px;
    bottom: 3%;
    left: 50%;
    margin-left: -50px;
    div {
      background-color: white;
      display: flex;
      box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.5);
      padding: 0.25rem;
      border-radius: 5px;
    }
    button {
      font-weight: 300;
      background-color: white !important;
      color: black !important;
      margin-left: 5px;
      box-shadow: none;

      &.active {
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.6) !important;
      }
      &:hover {
        border: 1px solid #3366ff;
        color: #3366ff !important;
      }
    }
  }
}

.draggable-container {
  position: fixed;
  width: calc(100% - 0.9rem);
  height: calc(100% - 0.9rem);
  padding-top: 65px;
  padding-left: 5rem;
}

.page-component-error {
  margin-top: 65px;
  padding: 1rem;
}

.page-loader {
  z-index: 1001;
  top: 70px;

  .loader,
  .loader-text {
    transform: translateY(calc(50% - 30px));
  }
}
