@use "@iq/react-components/dist/colors.scss" as colors;

.sync-health-component {
  color: colors.$Grey90;
  border-radius: 0.2rem;
  margin-bottom: 1.5rem;
  line-height: 150%;

  &--text {
    font-weight: 500;

    &:first-child {
      margin-bottom: 2rem;
    }
  }

  .source {
    font-weight: 500;
  }

  .error-display {
    margin-bottom: 2rem;
    .time {
      margin: 0 0.4rem;
    }
  }
}
