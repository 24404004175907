@use '@iq/react-components/dist/colors.scss' as colors;

.attributes-header {
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  input {
    width: 15rem;

    &:nth-child(2) {
      min-height: 36px;
    }
  }

  .asset-select {
    width: 15rem;
    font-weight: 400;
    font-size: 1rem;
  }
}

.attributes-header,
.manage-attributes-body {
  .avt-react-components--icon {
    margin-right: 0.5rem;
  }
}

.manage-attributes-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0 1rem;

  .attributes {
    margin: 1rem 0.25rem;
    font-size: 0.75rem;
  }

  .list-container {
    padding: 0rem 0rem 2rem 0rem;
    background-color: #fff;
    border-radius: 0.25rem;

    .list-item--header {
      display: flex;
    }

    .list-item__col {
      .blocked-item-container {
        max-width: 15rem;
      }
    }

    .ellipsed-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty-header-column {
      width: 7rem;
      display: flex;
    }
  }

  .panel-pagination {
    margin: 1rem 0;
  }
}


.light-theme {
  .manage-attributes-body {
    background-color: colors.$Grey0;

    .list-item--header {
      .avt-react-components--button {
        background-color: colors.$LightApplicationBackground;
        color: colors.$LightApplicationPrimaryText;
      }
    }

    .empty-header-column {
      background-color: colors.$LightApplicationBackground;
    }
  }

}

.dark-theme {
  .attributes-header {
    .avt-react-components--input {
      &::placeholder {
        color: colors.$DarkApplicationSecondaryText;
      }
    }
  }

  .manage-attributes-body {
    background-color: colors.$DarkApplicationPanelBackground;
    border: 0.5px solid colors.$DarkApplicationInputBorder;

    .list-container {
      background-color: colors.$Grey90;

      .list-item--header {
        .avt-react-components--button {
          background-color: colors.$Grey85;
          color: colors.$DarkApplicationPrimaryText;
        }
      }

      .empty-header-column {
        background: colors.$Grey85;
      }
    }
  }
}
