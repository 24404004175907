@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container .file-preview-modal-overlay {
  padding: 3.4rem 1rem 1rem;

  .big-modal-wrapper {
    padding: 0;
  }

  .close-preview-modal {
    position: absolute;
    top: 0.9rem;
    right: 2.3rem;
    cursor: pointer;

    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.file-preview-modal {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  position: relative;
  transition: width 500ms ease-in-out;

  .file-preview-loader {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .file-preview-icon-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .file-preview-icon {
      width: 25%;
      max-width: 180px;
    }
  }

  .change-file {
    display: flex;

    > :last-child {
      margin-left: 0.5rem;
    }
  }

  .top-toolbar {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    justify-content: space-between;
    padding: 1rem 0 1rem 1rem;

    .title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    > :last-child {
      cursor: pointer;
    }
  }

  .preview-area {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;

    .preview-content {
      flex: 0 1 100%;
      position: relative;

      &.non-pdf-padding {
        padding-right: 1rem;
      }
    }

    .preview-sidebar {
      flex: 0 0 18rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: auto;

      @media (max-width: 800px) {
        flex: 0 0 14rem;
      }

      &.contracted {
        flex: 0 0 2rem;

        button {
          width: 2.2rem;
        }
      }

      .sidebar-icons {
        > :first-child {
          margin-bottom: 0.5rem;
        }

        &.pdf-top-margin {
          > :first-child {
            margin-top: 0.25rem;
          }
        }
      }

      .tab-button {
        &:focus {
          outline: 0;
          box-shadow: none;
        }

        &:active:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }

  .bottom-toolbar {
    padding: 1.5rem 0 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    button {
      margin: 0 0.25rem;
    }
  }

  .bottom-toolbar-contracted {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin: 0.25rem 0;
    }
  }

  .create-bookmark-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .bookmark-created-notification {
    padding: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.related-files-component {
  overflow-y: auto;
  height: calc(100% - 56px);
}

.light-theme {
  .file-preview-modal {
    .loader-component {
      background: rgba(colors.$LightApplicationBackground, 0.6);
    }
    .create-bookmark-backdrop {
      background: rgba(colors.$LightApplicationBackground, 0.9);
    }

    .bookmark-created-notification {
      border: 1px solid colors.$LightApplicationBorder;
      background: colors.$LightApplicationBorder;
    }
  }

  .preview-sidebar {
    border-left: 1px solid colors.$LightApplicationBorder;
  }
}

.dark-theme {
  .file-preview-modal {
    .loader-component {
      background: rgba(colors.$DarkApplicationBackground, 0.6);
    }
    .create-bookmark-backdrop {
      background: rgba(colors.$DarkApplicationBackground, 0.9);
    }

    .bookmark-created-notification {
      border: 1px solid colors.$DarkApplicationBorder;
      background: colors.$DarkApplicationBorder;
    }
  }

  .preview-sidebar {
    border-left: 1px solid colors.$DarkApplicationBorder;
  }
}
