@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

// EventTimelinePanel.js
.event-timeline-component-type.full-screen .event-timeline-panel {
  // 100vh - header - layout margins - panel margins - panel header - panel header margins
  height: calc(100vh - 69px - 2rem - 2rem - 56px - 2rem);

  .event-timeline-event-list {
    .split {
      min-height: 200px;
    }
  }
}

.event-timeline-panel {
  user-select: none;

  .drop-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 15rem;
    position: relative;

    &:focus {
      outline: none;
    }

    > div:not(:first-child):not(.drop-zone) + div {
      margin-top: 1rem;
    }

    .drop-zone {
      position: absolute;
      padding: 1rem;
      border: 2px dashed gray;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      z-index: 100;
    }
  }

  .event-timeline-view {
    > div > svg {
      cursor: crosshair;
    }
  }

  .event-timeline-brush {
    svg {
      overflow: visible;
    }
  }
}

// EventTimelinePanel.js > EventTimelineDomainDisplay
.event-timeline-info-header {
  display: flex;
  justify-content: space-between;
  color: colors.$Grey50;
  font-size: 0.75rem;
  margin-bottom: 0.25rem;

  &.over-chart div:first-child {
    margin-left: 70px;
  }

  &.over-list div:first-child {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .event-timeline-legend {
    li {
      float: left;
      margin-right: 0.5rem;
      font-size: 0.85rem;
      svg {
        margin-right: 0.25rem;
      }
    }
  }

  .event-timeline-info {
    .limited-event-info {
      position: relative;
      display: flex;
      align-items: center;

      .info-text {
        margin-right: 0.5rem;
      }

      &-trigger.user,
      &-trigger.list {
        .event-info-icon {
          color: colors.$StatusBlue;
        }

        &:hover + div {
          display: block;
          position: absolute;
          top: -5rem;
        }
      }

      &-tooltip {
        display: none;
        background: white;
        width: 30rem;
        border: 1px solid colors.$Grey20;
        border-radius: 0.3rem;
        padding: 1rem;
        top: 9.5rem;
        right: 0;
        line-height: 1.2rem;

        p:not(:last-child) {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}

// EventTooltips.js
.event-timeline-tooltip {
  padding: 1rem;
  font-size: 1rem;
  z-index: 1000;
  background-color: color.adjust(colors.$Grey0, $alpha: -0.15);
  color: colors.$LightApplicationPrimaryText;
  min-width: 20rem;

  > * + * {
    margin-top: 0.5rem;
  }

  &__fixed-width-label {
    width: 50px;
    display: inline-block;
  }

  &__severity {
    text-transform: capitalize;
    color: #000;
    font-weight: 500;

    &--critical {
      color: colors.$StatusRed;
    }

    &--warning {
      color: colors.$StatusYellow;
    }
  }
  &__details {
    margin: 0.5rem 1.25rem;
    line-height: 1.25;
    div {
      span {
        min-width: 6rem;
        display: inline-block;
        font-size: 14px;
        &:first-child {
          color: #808080;
        }
        &:last-child {
          color: #000000;
        }
      }
    }
  }

  &__severity-groups {
    color: #808080;
    margin-top: 1rem;

    > * + * {
      margin-top: 0.5rem;
    }
  }

  &__timestamp {
    color: #808080;
  }

  &__duration {
    display: flex;
    align-items: center;

    .avt-react-components--icon {
      display: flex;
      align-items: center;
      width: 9px;
      height: 9px;
      margin: 0 0.3rem;
    }
  }

  &__name {
    font-weight: 500;
  }

  &__indicators {
    display: flex;
    justify-content: flex-end;

    &--comments,
    &--attachments {
      position: relative;
      margin-right: 1rem;

      .pill-component {
        position: absolute;
        top: -0.4rem;
        right: -0.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.4rem;
        height: 1.1rem;
        border-radius: 50%;

        .text {
          margin-left: 0;
        }
      }
    }
  }
}

.tooltip-box {
  border-radius: 0.25rem;
  font-size: 0.86rem;
  padding: 0.35rem 0.5rem 0.4rem 0.5rem;
  word-break: keep-all;
  white-space: normal;
  text-align: center;
  max-width: none;

  &.theme-light {
    background-color: colors.$Grey80;
    color: colors.$Grey0;
  }

  &.theme-dark {
    background-color: colors.$Grey5;
    color: colors.$Grey100;
  }
}

body.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// UserTimeline.js & IotTimeline.js
.event-timeline-chart-area {
  position: relative;
  margin-bottom: 1rem;

  .iot-event-option {
    display: flex;
    align-items: center;

    div:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.timeline-chart-event {
  cursor: pointer;
}

// EventList.js
.event-timeline-list-container {
  overflow-y: hidden;
  height: 100%;

  &.limited {
    overflow-x: hidden;
  }

  &:not(.limited) {
    position: relative;
  }
}

.event-timeline-event-list {
  border: 1px solid rgba(89, 89, 89, 0.3);
  height: 100%;

  &.limit-list {
    border: none;
  }

  &--empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .list-view {
    width: 100% !important;
    overflow-y: scroll !important;
  }
}

.event-timeline-event-list-headers {
  display: flex;
  font-weight: 500;
  background: colors.$Grey5;
  color: colors.$LightApplicationPrimaryText;
  border-bottom: 1px solid rgba(89, 89, 89, 0.3);

  .list-header {
    padding: 0.5rem 0.7rem 0.5rem 0.5rem;
    border-right: 0.2rem solid colors.$Grey0;
    box-sizing: border-box;

    &:last-child,
    &:nth-last-child(2) {
      border-right: none;
    }

    &.date-column {
      flex: 4 0 215px;
      min-width: 215px;

      &.avt-react-components--button {
        padding: 0.5rem 0.7rem 0 0.5rem;
        flex: 4 0 215px;
        min-width: 215px;
        border-top: none;
        border-left: none;
        align-items: flex-start;
        line-height: 16px;

        .avt-react-components--icon {
          display: flex;
          flex-direction: column;
          top: 2px;
          left: 4px;
          position: relative;
        }
      }
    }

    &.active-column,
    &.activity-column,
    &.state-column {
      flex: 1 0 85px;
      min-width: 85px;
    }

    &.type-column,
    &.pointId-column,
    &.group-column,
    &.sender-column,
    &.assignedTo-column {
      flex: 4 1 165px;
      min-width: 150px;
    }

    &.message-column {
      flex: 10 4 350px;
      min-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.actions-column {
      flex: 1 0 85px;
      min-width: 85px;
      padding: 0.5rem;
    }

    &.scroll-spacer {
      flex: 0 0 6px;
      padding: 0;
    }
  }
}

// EventList.js > EventListItem
.event-timeline-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  background-color: colors.$Grey0;
  border-bottom: 1px solid rgba(89, 89, 89, 0.3);
  border-right: 1px solid rgba(89, 89, 89, 0.3);
  width: calc(100% - 2px);

  &.overdue {
    background-color: #ec575b;
  }
  &.todo {
    background-color: #ede27f;
  }

  &__date {
    display: flex;
    flex: 4 0 215px;
    min-width: 215px;
    align-items: center;
    line-height: 1.3rem;
    padding: 0 0.5rem;
  }

  &__type {
    flex: 4 1 165px;
    min-width: 150px;
    line-height: 1.3rem;
    text-transform: capitalize;
    padding: 0 0.5rem;
  }

  &__message {
    flex: 10 4 350px;
    min-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3rem;
    padding: 0 0.5rem;
  }

  &__active,
  &__activity,
  &__state,
  &__actions {
    flex: 1 0 85px;
    min-width: 85px;
    padding: 0 0.5rem;
  }

  &__sender,
  &__assignedTo,
  &__group,
  &__pointId {
    flex: 4 1 165px;
    min-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3rem;
    padding: 0 0.5rem;
  }

  &--limited-list {
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 0.3rem;
    height: 60px;
    background-color: colors.$Grey5;

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 0 0.5rem;

      &:not(:last-child) {
        padding-bottom: 0.3rem;
      }
    }

    .event-timeline-list-item_type {
      flex: 1;
    }

    .event-timeline-list-item__date,
    .event-timeline-list-item__actions {
      flex: 1;
      justify-content: flex-end;
    }

    .event-timeline-list-item__actions {
      .event-timeline-user-event-status {
        justify-content: flex-end;
      }
    }
  }

  &--limited-list-narrow {
    @media only screen and (max-width: 1330px) {
      justify-content: flex-start;
      height: 77px;

      > div {
        align-items: flex-start;

        &:not(:last-child) {
          flex-direction: column;
          padding-bottom: 0;
        }

        > div {
          padding-bottom: 0.5rem;
          padding-left: 0;
        }
      }

      .event-timeline-list-item__date {
        padding-bottom: 0.4rem;
      }
    }

    @media only screen and (min-width: 1200px) and (max-width: 1330px) {
      height: 82px;
    }
  }

  &--loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--clickable {
    cursor: pointer;
  }

  &--critical {
    background-color: #f8d9d8;
  }

  &--warning {
    background-color: #fef7de;
  }

  &--hovering {
    box-shadow: inset 0px 0px 0.5px 1.5px #3366ff;
    z-index: 1;
  }

  &--limited-list#{&}--hovering {
    box-shadow: none;
    background-color: colors.$Grey10;
  }
}

.limited-list-wrapper {
  display: block;

  .event-timeline-list-item {
    &__message {
      display: flex;
      align-items: center;
      max-width: 75%;

      > div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

// EventFilters.js
.event-timeline-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;

  @media only screen and (min-width: 1770px) {
    margin-left: 0;
  }

  .popout-select--trigger.round svg {
    color: colors.$Grey30;
  }

  .avt-react-components--input {
    line-height: 1rem;
    height: calc(1rem + 1px);
    box-sizing: content-box;
    padding: 0.5rem;
    width: 12rem;

    &:hover:not(:focus) {
      border-color: colors.$Grey30;
    }

    &::placeholder {
      color: colors.$LightApplicationSecondaryText;
      font-weight: 400;
    }
  }

  .select-period-component {
    .header {
      box-sizing: border-box;
      width: 100%;
    }
  }
}

// EventTimeLineHeader.js
.event-timeline-panel-header {
  margin-bottom: 1rem;

  .event-timeline-panel-toolbar {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    .event-timeline-panel-btn-wrapper {
      display: flex;
      gap: 0.5rem;
    }

    .event-timeline-filters {
      .event-timeline-search {
        display: flex;
        align-items: flex-end;
        width: 23%;

        .avt-react-components--input {
          width: 100%;
          height: 1rem;
        }
      }
    }
  }

  &.wide {
    .event-timeline-panel-toolbar {
      // 1305px where overflow occurs if all filters have options selected
      @media only screen and (max-width: 1305px) {
        .event-timeline-filters {
          .avt-react-components--input {
            width: 100%;
          }
        }
      }
    }
  }

  &.widest {
    .event-timeline-panel-toolbar {
      button:nth-child(2) {
        // 840px where overflow occurs if all filters have options selected
        @media only screen and (max-width: 840px) {
          padding: 0 0.4rem;

          .avt-react-components--icon {
            margin-right: 0;
          }

          .button-text {
            display: none;
          }
        }
      }
    }
  }
}

// UserEventStatus.js
.event-timeline-user-event-status {
  display: flex;
  justify-content: space-evenly;
}

// Indicator.js
.severity-indicator {
  margin-right: 8px;

  &--panel {
    margin-right: 0;
  }
}

// RelatedEvents.js
.related-events {
  height: 124.5px;

  &.empty {
    height: 0px;
  }

  // RelatedEvents.js > EventListDisplay
  &__list {
    .avt-react-components--button,
    .event-count {
      margin-top: 1rem;
      font-size: 0.75rem;
    }

    .event-count {
      display: flex;
      justify-content: center;
      color: colors.$LightApplicationSecondaryText;
    }
  }

  &__display-item {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;

    &.clickable {
      cursor: pointer;
    }

    & > *:first-child {
      flex: 0 0 auto;
      margin: 0 10px;
    }
  }

  p {
    min-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__see-all {
    display: block;
    margin: 0 auto;
  }

  // RelatedEvents.js > RelatedEvents
  &__buttons {
    display: flex;
    margin-top: 1rem;

    button {
      margin-right: 0.5rem;
    }
  }

  &__list-container {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}

// RelatedEvents.js > RelatedEventsModal
.related-events-modal {
  &__event-filters {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .related-events__search {
      padding-right: 0.5rem;
      width: 19rem;

      .avt-react-components--input {
        height: 2.2rem;
      }
    }

    .popout-select {
      .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
        background: colors.$Grey0;
      }
    }

    .select-period-component {
      background-color: colors.$Grey0;
    }

    .event-timeline-filters {
      width: auto;
    }
  }

  &__date-span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    > .avt-react-components--icon {
      margin: 0 0.5rem;
    }
  }

  &__date-time {
    display: flex;

    .date-picker {
      .SingleDatePickerInput {
        padding: 0.3rem 0.5rem;
        color: colors.$LightApplicationPrimaryText;

        input {
          width: auto;
        }
      }
    }

    .time-picker {
      padding: 0.3rem 0.5rem 0.3rem 0.3rem;
    }

    > * + * {
      margin-left: 0.5rem;
    }
  }

  .valid-date {
    .DateInput_input {
      color: colors.$LightApplicationPrimaryText;
    }
  }

  &__date-time + &__date-time {
    margin-left: 0.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      margin-right: 0.5rem;
    }
  }

  @media only screen and (max-width: 1350px) {
    &__event-filters {
      flex-direction: column;
      align-items: flex-end;

      .related-events__search {
        margin-bottom: 0.5rem;
        padding-right: 0;
      }
    }

    &__date-span {
      margin-bottom: 0.5rem;
    }
  }
}

.light-theme {
  // EventTimelinePanel.js
  .drop-zone {
    background: color.adjust(colors.$LightApplicationPanelBackground, $alpha: -0.1);
  }
}

.dark-theme {
  // EventTimelinePanel.js

  .drop-zone {
    background: color.adjust(colors.$DarkApplicationPanelBackground, $alpha: -0.1);
  }

  // EventTimelinePanel.js > EventTimelineDomainDisplay
  .event-timeline-domain-display {
    color: colors.$Grey60;
  }

  // EventTooltips.js
  .event-timeline-tooltip,
  .tooltip-detail {
    background-color: color.adjust(colors.$Grey85, $alpha: -0.15);
    color: colors.$DarkApplicationPrimaryText;
  }

  // EventList.js

  .event-timeline-event-list-headers {
    background: colors.$Grey85;
    color: colors.$DarkApplicationPrimaryText;

    :not(:last-child) {
      border-right: 0.2rem solid colors.$Grey90;
    }
  }

  // EventList.js > EventListItem
  .event-timeline-list-item {
    background-color: colors.$Grey90;
    border-bottom: 1px solid rgba(colors.$Grey35, 0.3);

    &--critical {
      background-color: rgba(colors.$StatusRed, 0.15);
    }

    &--warning {
      background-color: rgba(colors.$StatusYellow, 0.1);
    }

    &--limited-list {
      border: 0;
    }

    &.event-timeline-list-item--limited-list {
      background-color: colors.$Grey85;

      &.event-timeline-list-item--hovering {
        box-shadow: none;
        background-color: colors.$Grey75;
      }
    }
  }

  // EventFilters.js
  .event-timeline-filters {
    .popout-select--trigger.round svg {
      color: colors.$Grey60;
    }

    .avt-react-components--input {
      &:hover:not(:focus) {
        border-color: colors.$Grey60;
      }

      &::placeholder {
        color: colors.$Grey60;
      }
    }
  }

  // RelatedEvents.js
  .related-events {
    &__list {
      .event-count {
        color: colors.$DarkApplicationSecondaryText;
      }
    }
  }

  // RelatedEvents.js > RelatedEventsModal
  .related-events-modal {
    &__event-filters {
      .popout-select {
        .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
          background: colors.$Grey90;
        }
      }
    }

    &__date-time {
      .date-picker {
        .SingleDatePickerInput {
          color: colors.$DarkApplicationPrimaryText;
        }
      }
    }

    .valid-date {
      .DateInput_input {
        color: colors.$DarkApplicationPrimaryText;
      }
    }

    &__date-span {
      > div {
        > input::-webkit-calendar-picker-indicator {
          filter: invert(1);
        }
      }
    }
  }

  .event-timeline-tooltip {
    &__details {
      div {
        span {
          color: colors.$DarkApplicationPrimaryText;
        }
      }
    }

    &__severity {
      color: colors.$DarkApplicationPrimaryText;
    }
  }
}
