@use '@iq/react-components/dist/colors.scss' as colors;

.create-algorithm-content {
  .form-wide-errors {
    text-align: center;
    margin: 2rem auto;
    display: inline-block;
    padding: 0.5rem;
    color: #fff;
    border-radius: 0.2rem;
    background: colors.$StatusRed;
  }

  .upload-modal-choices {
    padding: 1.5rem;

    .upload-error {
      padding-bottom: 1rem;
      color: colors.$StatusRed;

      div:first-child {
        padding-bottom: 0.5rem;
      }
    }

    li {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid colors.$Grey5;
      }

      .actions {
        margin-left: auto;
      }

      .meta {
        margin-right: 1rem;
        line-height: 130%;

        .title {
          margin-bottom: 0.5rem;
        }

        .description {
          max-width: 20rem;
          color: colors.$Grey40;
        }
      }
    }
  }
}
