@use '@iq/react-components/dist/colors.scss' as colors;

.file-grid-component {
  .filename-column {
    display: flex;
    align-items: center;

    .avt-react-components--icon {
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    .filename {
      flex: 1 1 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 500;
    }
  }

  .filetype-badge {
    display: inline-block;
    border-radius: 0.2rem;
    padding: 0.25rem 0.35rem;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}

.light-theme .file-grid-component {
  .filetype-badge {
    background: colors.$Grey5;
  }
}

.dark-theme .file-grid-component {
  .filetype-badge {
    background: colors.$Grey80;
  }
}

.grid {
  display: flex;
  flex-flow: column;

  .grid-content {
    display: grid;
    grid-gap: 1rem;

    &.narrow {
      grid-template-columns: repeat(4, 1fr);
    }

    &.widest {
      grid-template-columns: repeat(12, 1fr);

      @media only screen and (max-width: 2000px) {
        grid-template-columns: repeat(10, 1fr);
      }

      @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(8, 1fr);
      }

      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.wide {
      grid-template-columns: repeat(10, 1fr);

      @media only screen and (max-width: 2000px) {
        grid-template-columns: repeat(8, 1fr);
      }

      @media only screen and (max-width: 1500px) {
        grid-template-columns: repeat(6, 1fr);
      }

      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media only screen and (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    min-width: 0;
    box-sizing: border-box;

    .checkbox-wrapper,
    .dropdown-wrapper {
      display: none;
      position: absolute;
      top: 0.5rem;
      padding: 0.15rem;
      background: #0006;
      border-radius: 0.2rem;
      z-index: 998;

      &.display {
        display: block;
      }

      .avt-react-components--icon {
        width: 20px;
        height: 20px;
      }
    }

    .pill-component {
      position: absolute;
      right: 0.5rem;
      bottom: 0.5rem;
    }

    .checkbox-wrapper {
      left: 0.5rem;
      color: colors.$Grey0;
    }

    .dropdown-wrapper {
      right: 0.5rem;
      padding: 0;

      .avt-react-components--icon {
        width: 15px;
        height: 15px;
      }
    }

    &:hover {
      .checkbox-wrapper,
      .dropdown-wrapper {
        display: block;
      }
    }

    .grid-image {
      position: relative;
      width: 100%;
      padding-top: 100%;
      box-sizing: border-box;

      &.loading {
        top: initial;
        left: initial;
        transform: none;
      }

      &.type-video {
        &::after {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          content: "";
          display: block;
          width: 1.5rem;
          height: 1.5rem;
          box-sizing: border-box;
          border-style: solid;
          border-width: .75rem 0px .75rem 1.5rem;
          border-color: transparent transparent transparent #fff;
          opacity: 0.7;
        }

        &:hover::after {
          opacity: 1;
        }
      }

      .filetype-icon-component {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
          display: block;
          width: auto;
          height: 70%;
        }
      }

      .avt-react-components--spinner,
      .filetype-icon-component {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 130%;
      }

      > img {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        border: 2px solid transparent;
      }

      button {
        &:focus {
          outline: 0;
          box-shadow: none;
        }
        &:active:focus {
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .grid-item-footer {
      margin-top: 0.5rem;
      width: 100%;
      box-sizing: border-box;

      .grid-item-title {
        line-height: 130%;
        white-space: normal;
        word-break: break-all;
        font-size: 0.8rem;
        text-align: center;
      }
    }

    .bookmark-icon-wrapper {
      position: absolute;
      z-index: 10;
      top: 0;
      bottom: 25px;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.light-theme {
  .bookmark-icon-wrapper {
    background: rgba(colors.$LightApplicationBackground, 0.6);
  }

  .grid-item {
    .grid-image {
      &:not(.loading) img {
        border-color: colors.$Grey20;
      }

      &.loading {
        background: colors.$LightApplicationBackground;
      }
    }
  }

  .grid-item-footer {
    &:hover {
      span {
        background: rgba(colors.$LightApplicationPanelBackground, 0.8);
      }
    }
  }
}

.dark-theme {
  .bookmark-icon-wrapper {
    background: rgba(colors.$DarkApplicationBackground, 0.6);
  }

  .grid-item {
    .grid-image {
      &:not(.loading) img {
        border-color: colors.$Grey0;
      }

      &.loading {
        background: colors.$Grey85;
      }
    }
  }

  .grid-item-footer {
    &:hover {
      span {
        background: rgba(colors.$DarkApplicationPanelBackground, 0.8);
      }
    }
  }
}
