@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

.DateInput {
  overflow: hidden;
  max-width: 115px;
}

.DateInput_input {
  font-size: 0.9rem;
  padding: 5px;
  font-weight: 500;
  color: colors.$Grey60;
}

.DayPicker_weekHeader {
  top: 55px;
}

.DayPicker__withBorder {
  box-shadow: none;
  border: 1px solid #eee;
}

.CalendarDay__default {
  vertical-align: middle;
}

.DateInput_input__focused {
  border-bottom: 2px solid colors.$UIBlue60;
}

.light-theme {
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: colors.$UIBlue60;
    border: 1px double colors.$UIBlue60;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    color: #fff;
    background: colors.$UIBlue40;
    border: 1px double colors.$UIBlue40;
  }
}

.dark-theme {
  .DayPickerNavigation_button__default {
    background: #1f1f1f;
    border: 1px solid #525252;
    &:hover {
      background: color.adjust(#1f1f1f, $lightness: 10%);
    }
  }

  .CalendarMonth_caption {
    color: colors.$DarkApplicationPrimaryText;
  }

  .DateRangePickerInput,
  .DateInput,
  .DateRangePicker_picker,
  .DayPicker__horizontal,
  .CalendarMonth,
  .CalendarMonthGrid {
    background: #1f1f1f;
    color: #333;
  }

  .DayPicker_weekHeader_ul {
    color: colors.$DarkApplicationPrimaryText;
  }

  .DateInput_input {
    background: #1f1f1f;
    color: colors.$DarkApplicationPrimaryText;
  }

  .DayPicker__withBorder {
    border: 1px solid #525252;
    color: colors.$DarkApplicationPrimaryText;
  }

  .CalendarDay__default {
    background: #1f1f1f;
    color: colors.$DarkApplicationPrimaryText;
    border: 1px solid #525252;
    &:hover {
      background: color.adjust(#1f1f1f, $lightness: 10%);
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    color: colors.$DarkApplicationPrimaryText !important;
    background: colors.$UIBlue60;
    border: 1px double colors.$UIBlue60;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    color: #fff;
    background: colors.$UIBlue40;
    border: 1px double colors.$UIBlue40;
  }
}
