@use '@iq/react-components/dist/colors.scss' as colors;

#global-modal-container .simple-modal-overlay .simple-modal-wrapper.event-modal-wrapper.size-m {
  max-width: 70rem;
}

// File preview modal (user events)
#global-modal-container .event-modal {
  .big-modal-wrapper {
    padding: 0 0 0 1.5rem;
    background-color: white;
    .json-display-component {
      width: 100%;

      .heading {
        padding-left: 1.5rem;
      }
      #root_main_general__title,
      #root_main_time__title,
      #root_main_planning__title,
      #root_main_execution__title {
        font-size: 1.25rem;
        width: 100%;
        height: 2rem;
        border-bottom: 1px solid colors.$Grey30;
      }
    }
  }

  .close-preview-modal {
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

// Event modal Tags in header
#global-modal-container
  .simple-modal-overlay
  .simple-modal-wrapper.event-modal-wrapper
  .simple-modal-header
  .simple-modal-main-header__left
  .title {
  .popout-select,
  .tag-display-container {
    font-weight: 400;
    font-size: 1rem;
  }

  .tag-display-container {
    font-size: 0.9rem;
    display: flex;
  }
}

#global-modal-container .simple-modal-wrapper.event-modal-wrapper .simple-modal-header {
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-bottom: none;

  .close {
    position: relative;
    right: 4px;
  }
}

.simple-modal-wrapper.event-modal-wrapper {
  background-color: colors.$Grey0;

  &.view,
  &.edit {
    .event-modal-menu {
      bottom: 2px;
      right: 1rem;
    }
  }

  .series-cta {
    display: flex;
    align-items: center;

    .series-link {
      margin-left: 0.25rem;

      .avt-react-components--button.series-link-btn {
        color: colors.$StatusBlue;
      }
    }

    .saved-series-info {
      cursor: help;
      display: flex;
      align-items: center;
      font-weight: 500;

      .avt-react-components--icon.m {
        height: 1.4rem;
        width: 1.4rem;
        margin-left: 0.4rem;
      }

      &:hover + .saved-series-info--outer {
        display: block;
        position: relative;
      }

      &--outer {
        display: none;
        width: 0;
        height: 0;
        top: -5rem;
        z-index: 1000;
        position: relative;
      }

      &--inner {
        background: white;
        border: 1px solid colors.$Grey20;
        border-radius: 0.3rem;
        padding: 1rem;
        line-height: 1.2rem;
        width: 22rem;

        p:not(:last-child) {
          padding-bottom: 0.5rem;
        }
      }
    }
  }

  .event-modal-header {
    display: grid;
    grid-gap: 0.5rem;
    align-items: center;
    grid-auto-flow: column;
    border-bottom: none;

    svg {
      margin-right: 0;
    }

    .tag-display-container {
      width: 100%;

      .blocked-item {
        border-radius: 0.3rem;
        padding: 0.1rem 0.5rem;
        margin-right: 0.5rem;
        line-height: 1.3rem;
        background-color: colors.$Grey20;
      }
    }
  }

  .duration {
    padding-top: 0.5rem;
    padding-left: calc(16px + 0.5rem);
    display: flex;
    align-items: center;
    color: colors.$LightApplicationSecondaryText;

    &.shifted {
      padding-left: calc(42px + 1rem);
    }

    .avt-react-components--icon {
      padding: 0 0.5rem;
    }
  }
}

.event-modal {
  flex: 1;
  padding: 2px;
  background-color: colors.$Grey0;

  .rjsf .form-group {
    margin-bottom: 2rem;
  }

  &.view,
  &.edit-limited {
    textarea {
      border: none;
      resize: none;
    }

    .form-group.field.field-string {
      .view-widget {
        font-weight: unset;
        border: none;
        padding: 0.75rem 0 0 0;
        background-color: transparent;
      }
    }

    .form-group.field.field-object {
      .date-time-picker:not(.editing) {
        .title {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &.view {
    .body-section {
      padding-bottom: 1rem;
    }
  }

  .top-section {
    display: flex;
    background-color: colors.$Grey0;
    height: 9rem;

    .top-section-field {
      width: 25%;
      align-items: 'center';
      padding: 1rem 4rem 1rem 1rem;
    }

    &__content {
      width: 100%;
      background-color: colors.$Grey10;
      position: absolute;
      left: 0rem;
      padding: 1rem 1.5rem;

      &.iot {
        display: flex;
        justify-content: space-between;

        .event-modal__attribute-group {
          width: calc(50% - 1rem);
          padding-top: 0;
          margin-bottom: 1rem;
        }
      }

      fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-group.field.field-string {
          width: calc(25% - (6rem / 4));

          & label {
            display: flex;
            margin: 0.5rem 0;
          }
        }
      }

      .form-group.field.field-string {
        margin-bottom: 1rem;
      }
    }
  }

  .body-section {
    display: flex;
    justify-content: space-between;
    transition: transform 0.3s ease-out;

    > div {
      width: calc(50% - 1rem);
      padding-top: 1rem;
    }

    &.show-planning {
      > div:not(:first-child) {
        width: calc((50% - 3rem) / 2);
      }
    }

    &.iot {
      > div {
        padding-top: 0;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &__row {
    display: flex;
  }

  &__column {
    width: 70%;

    > .event-modal__form-field-group:first-child {
      padding-top: 0;
    }
  }

  &__section {
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: colors.$Grey0;

    > .event-modal__attribute-group:first-child {
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__attribute-group {
    margin-bottom: 2rem;
    white-space: pre-line;

    .value-container {
      display: flex;
      align-items: center;
      word-break: break-all;
      margin-top: 0.75rem;

      &.empty-value {
        color: colors.$LightApplicationSecondaryText;
      }

      .simple-user-badge {
        height: 1.5rem;
        width: 1.5rem;
        font-size: 0.7rem;
        margin-right: 0.5rem;
      }
    }

    .value-list {
      &.inline {
        display: flex;
        flex-wrap: wrap;
      }

      &__value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding-bottom: 0.4rem;

        &.distinct {
          width: fit-content;
          border-radius: 0.2rem;
          background-color: colors.$Grey10;
          margin: 2px;
          font-size: 85%;
          padding: 0.2rem 0.4rem;
        }
      }
    }
  }

  &__form-field-inline-groups {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .event-modal__form-field-group {
      flex: none;

      &:only-child {
        flex: 1;
      }
    }

    > .avt-react-components--icon {
      align-self: flex-end;
      bottom: 0.5rem;
      position: relative;
    }
  }

  &__label-row {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 0.5rem;

    .button-group {
      display: flex;
      button:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  &__file-options {
    display: flex;
    position: absolute;
    top: 100%;
    right: 0;
    border-width: 1px;
    border-color: colors.$Grey20;
    background-color: colors.$Grey0;
    border-style: solid;
    padding: 0.5rem;

    > button:first-child {
      margin-right: 0.5rem;
    }
  }

  label {
    font-weight: 500;
  }

  input.hidden {
    visibility: hidden;
  }

  .avt-react-components--textarea {
    max-width: 100%;
    min-width: 100%;
    max-height: 20rem;
    resize: vertical;
  }

  .form-buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    > button + button {
      margin-left: 1rem;
    }
  }

  &__checkbox-toggler {
    fill: colors.$LightApplicationPrimaryText;

    &.on {
      fill: colors.$StatusBlue;
    }
  }

  .datetime-group-start {
    display: flex;
    align-items: center;

    > div:last-child:not(:only-child) {
      flex: 1;
      display: flex;
      justify-content: center;
    }
  }

  .datetime-group {
    display: flex;
    align-items: center;

    input {
      width: auto;
      height: 22px;
      box-sizing: content-box;
    }

    &.valid-date {
      .DateInput_input {
        color: colors.$LightApplicationPrimaryText;
      }
    }

    > * + * {
      margin-left: 1rem;
    }
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: end;
    align-items: center;
    button:first-child {
      margin-right: 1rem;
    }
  }

  &__group-header {
    border-bottom: 1px solid colors.$Grey20;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: #535353;
  }

  &__attachments {
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;

      .filetype-icon-component {
        width: 1.5rem;
        margin-right: 0.5rem;

        img {
          width: 1.5rem;
        }
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 2rem);
        line-height: normal;
      }

      .avt-react-components--button {
        display: none;
      }

      &:hover {
        .avt-react-components--button {
          justify-self: flex-end;
          display: block;
        }

        span {
          padding-right: 1rem;
          width: calc(100% - (3rem + 24px));
        }
      }
    }
  }

  &__attachment-thumbnails {
    display: flex;
    justify-content: center;
    padding-top: 1rem;

    .file-grid-component {
      width: 100%;

      .grid {
        .grid-content.event-attachments {
          grid-template-columns: repeat(5, 1fr);
        }
      }
    }
  }

  &__file-input {
    .drop-zone {
      visibility: hidden;
      min-height: 5rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed colors.$Grey20;
    }

    &.drop-zone-active .drop-zone {
      visibility: visible;
    }
  }

  &__files {
    &.editable {
      cursor: pointer;
    }
  }
}

.remove-event-confirm {
  div {
    padding-bottom: 1rem;
  }
}

.light-theme {
  #global-modal-container {
    .simple-modal-wrapper.file-selector-modal {
      background-color: colors.$Grey0;
    }

    .event-modal {
      .rjsf {
        .form-group.field-array {
          border: none;
          padding: 0rem;
        }
      }
    }
  }
}

.dark-theme {
  #global-modal-container {
    .event-modal-wrapper {
      background-color: colors.$Grey80;
      .event-modal {
        background-color: colors.$Grey80;

        .avt-react-components--button.theme-dark.activity-secondary.design-regular {
          background-color: colors.$Grey60;
        }

        .button-group,
        &__label-row,
        &__actions {
          .activity-secondary {
            background-color: colors.$Grey60;
          }
        }

        .top-section {
          background-color: colors.$Grey80;
        }

        &__group-header {
          color: colors.$DarkApplicationPrimaryText;
        }

        .top-section__content {
          background-color: colors.$Grey75;
        }

        .rjsf .form-group.field-array,
        .rjsf .form-group.field-array .row {
          background-color: colors.$Grey80;
          padding: 0;
        }

        &__section {
          background-color: colors.$Grey85;
        }

        &__checkbox-toggler {
          fill: colors.$DarkApplicationPrimaryText;

          &.on {
            fill: colors.$StatusBlue;
          }
        }

        .datetime-group {
          &.valid-date {
            .DateInput_input {
              color: colors.$DarkApplicationPrimaryText;
            }
          }
        }

        .drop-zone {
          border-color: colors.$Grey70;
        }

        &__attribute-group {
          .value-list__value.distinct {
            background-color: colors.$Grey80;
          }

          .empty-value {
            color: colors.$DarkApplicationSecondaryText;
          }
        }

        &__file-options {
          border-color: colors.$Grey70;
          background-color: colors.$Grey90;
        }
      }

      .event-modal-header {
        .duration {
          color: colors.$DarkApplicationSecondaryText;
        }

        .popout-select {
          .avt-react-components--button.theme-dark.activity-tertiary.design-outlined.popout-select--trigger {
            border-color: colors.$Grey50;
            > div {
              color: colors.$DarkApplicationPrimaryText;
              border-color: colors.$DarkApplicationPrimaryText;
            }
          }
        }

        .tag-display-container .blocked-item {
          background-color: colors.$Grey50;
        }
      }

      .saved-series-info {
        &--inner {
          background: colors.$Grey85;
          border-color: colors.$Grey80;
        }
      }
    }
  }
}
