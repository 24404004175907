.image-preview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 20vw;
  height: 100%;
  text-align: center;
  max-height: calc(100vh - 8rem);
  padding: 0 0 1rem 1rem;

  img {
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .image-pan-wrapper {
    width: 100%;
    max-height: calc(100vh - 20rem);
  }
}

.image-preview-button-row {
  display: flex;
  display: none;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  margin: 15px 0;

  button:first-child {
    margin-right: 10px;
  }
}
