@use '@iq/react-components/dist/colors.scss' as colors;

.bim-extract-modal {
  .error-text {
    color: colors.$StatusRed;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .message-text {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .json-editor {
    padding-top: 0.5rem;
    .rjsf {
      .form-group.field-boolean {
        margin-top: 1.5rem;
      }

      .message--container {
        border: 1px solid colors.$LightApplicationInputBorder;
        display: flex;
        border-radius: 0.2rem;
        color: colors.$LightApplicationSecondaryText;
        margin-bottom: 2rem;
        align-items: stretch;
        vertical-align: middle;

        .message--icon {
          padding: 0.75rem;
        }

        .message--message {
          font-size: 12px;
          font-family: 'Open Sans', Arial, Helvetica, sans-serif;
          font-weight: 400;
          padding: 0.5rem 0.5rem 0.5rem 0;

          div {
            line-height: 1rem;
            padding-bottom: 0.5rem;

            & :last-child {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
