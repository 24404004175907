@use '@iq/react-components/dist/colors.scss' as colors;

.export-event-stats {
  .report {
    display: flex;
    background-color: colors.$LightApplicationBackground;
    padding: 2rem;
    gap: 2rem;

    .chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgb(255, 255, 255);
      padding: 1rem;
      min-width: 800px;
      min-height: 400px;

      .label {
        font-weight: 700;
        font-size: 1.2rem;
      }

      h1 {
        font-size: 0.75rem;
        font-style: italic;
        margin: 1rem 0 -5rem 0;
        font-weight: normal;
      }

      .list-container {
        padding: 3rem 0;
      }
    }
  }

  .noEvents {
    position: absolute;
    top: 45%;
    font-size: 0.85rem;
  }
}

.dark-theme {
  .report {
    background-color: colors.$DarkApplicationBackground;
    color: colors.$DarkApplicationPrimaryText;
    .chart {
      background-color: colors.$DarkApplicationBackground;
      color: colors.$DarkApplicationPrimaryText;
      label {
        color: colors.$DarkApplicationPrimaryText;
      }
      h1 {
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }
}
