@use '@iq/react-components/dist/colors.scss' as colors;

.manage-states-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0 1rem 0.5rem;

  .list-container {
    padding: 1rem 0;
    background-color: white;
    border-radius: 0.25rem;
    height: 100%;

    .list-item-header {
      display: flex;

      .header-column {
        display: inline-flex;
        margin-right: 0.5rem;
        padding: 0.45rem 0.5rem;
        background-color: colors.$LightApplicationBackground;
      }
    }

    .state-color {
      display: inline-block;
      height: 1rem;
      width: 1rem;
      border-radius: 0.25rem;
      margin-right: 0.5rem;
      vertical-align: middle;
    }

    .state-text {
      word-break: break-word;
      hyphens: auto;
    }

    .state-description {
      word-break: break-word;
      hyphens: auto;
      padding-bottom: 0.5rem;
    }

    .state-accordion-content {
      display: flex;
    }

    .ellipsed-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty-header-column {
      width: 7rem;
      display: flex;
    }

    input[type='color'] {
      width: 8rem;
      height: 2rem;
      padding: 0;
      margin: 0 1rem;
      vertical-align: middle;
    }

    .stateset-type-info-trigger {
      cursor: help;
      display: flex;
      align-items: center;
      font-weight: 500;

      .avt-react-components--icon.s {
        height: 1.4rem;
        width: 1.4rem;
        margin-left: 0.4rem;
        color: colors.$StatusBlue !important;
      }

      &:hover + .stateset-type-info-tooltip--outer {
        display: block;
        position: relative;
      }
    }

    .stateset-type-info-tooltip {
      &--outer {
        display: none;
        width: 0;
        height: 0;
        top: -5rem;
        left: 4rem;
      }

      &--inner {
        background: colors.$Grey0;
        border: 1px solid colors.$Grey20;
        border-radius: 0.3rem;
        padding: 1rem;
        line-height: 1.2rem;
        width: 22rem;

        p:not(:last-child) {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
}

.light-theme {
  .manage-states-body {
    background-color: colors.$Grey0;
  }
}

.dark-theme {
  .manage-states-body {
    background-color: colors.$DarkApplicationPanelBackground;
    border: 0.5px solid colors.$DarkApplicationInputBorder;

    .list-container {
      background-color: colors.$Grey90;
      .stateset-type-info-tooltip {
        &--inner {
          background: colors.$Grey85;
          border-color: colors.$Grey80;
        }
      }
    }
  }
}
