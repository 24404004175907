@use '@iq/react-components/dist/colors.scss' as colors;

.visualizations-header {
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  .avt-react-components--button.design-regular.activity-primary {
    &.button-create {
      background-color: colors.$StatusBlue;

      &--basic {
        background-color: colors.$StatusBlue;
      }

      &--advanced {
        background-color: colors.$WizardYellow;
      }
    }
  }
}

.manage-visualizations-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0 1rem 0.5rem;
  // 52 + 22.5 + 22.5 (page header + padding) + 67 (app header) + 1rem (bottom)
  height: calc(100vh - 164px - 1rem);
  overflow: hidden;

  .visualizations {
    margin: 1rem 0;
    font-size: 0.75rem;
  }

  .selection-toggler {
    display: flex;
    height: 2rem;
    align-items: center;
    padding-bottom: 1rem;

    label.select-all-label {
      display: flex;
      margin-right: 0.5rem;
      font-weight: 500;
      font-size: 100%;
      div {
        margin-right: 0.5rem;
      }
    }
  }

  .list-container {
    // total cols (with min-width of first) + # cols * 0.2rem + actions col
    min-width: calc(62rem + 1.6rem + 7rem);
    // 46 (count display) + 45 (selection toggler)
    height: calc(100% - 91px);
    overflow: hidden;

    .list-item--header {
      display: flex;

      .avt-react-components--button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-radius: 0;
        font-weight: 500;
        padding-left: 0;

        &.button-header {
          justify-content: center;
          padding: 0;
          cursor: default;

          .avt-react-components--icon {
            margin-right: 0;
          }
        }
      }
    }

    .custom-thin-scrollbar {
      overflow-y: scroll;
      // 51 (list header)
      height: calc(100% - 51px);
    }

    .dropdown-font {
      font-size: 0.9rem;
    }

    .ellipsed-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty-header-column {
      width: 7rem;
      display: flex;
    }

    .list-item {
      &__col:nth-child(2) {
        min-width: 16rem;
      }

      &__col:nth-child(n + 4) {
        justify-content: center;
      }

      .label-with-icon {
        display: flex;
        div {
          margin-top: 2px;
        }
      }
    }
  }
}

.light-theme {
  .manage-visualizations-body {
    background-color: colors.$Grey0;

    .list-container {
      background-color: colors.$Grey0;

      .list-item--header {
        .avt-react-components--button {
          background-color: colors.$LightApplicationBackground;
          color: colors.$LightApplicationPrimaryText;
        }
      }

      .dropdown-font {
        color: colors.$LightApplicationPrimaryText;
      }

      .empty-header-column {
        background-color: colors.$LightApplicationBackground;
      }
    }
  }
}

.dark-theme {
  .manage-visualizations-body {
    background-color: colors.$DarkApplicationPanelBackground;
    border: 0.5px solid colors.$DarkApplicationInputBorder;

    .list-container {
      background-color: colors.$Grey90;

      .avt-react-components--input {
        &::placeholder {
          color: colors.$DarkApplicationSecondaryText;
        }
      }

      .list-item--header {
        .avt-react-components--button {
          background-color: colors.$Grey85;
          color: colors.$DarkApplicationPrimaryText;
        }
      }

      .dropdown-font {
        color: colors.$DarkApplicationPrimaryText;
      }

      .empty-header-column {
        background: colors.$Grey85;
      }
    }
  }
}
