.site-detail-view-component {
  padding-left: 4rem;
}

.no-side-margin {
  padding-left: 0 !important;
}

.base-view {
  padding-left: 4rem;
  padding-top: 1px;

  .panel {
    padding: 0 0 1rem;
    min-height: calc(100vh - 1px);
    display: flex;
    flex-direction: column;
  }

  .loading-container {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
