@use '@iq/react-components/dist/colors.scss' as colors;

.date-time-picker {
  /*Timerange widget for Export signals popup*/

  .select-period-component {
    .header {
      background-color: #fff;
      min-height: 38px;
      width: 100%;
      justify-content: flex-start !important;

      &:hover {
        border-color: #b3b3b3 !important;
      }

      .label {
        display: flex;
        flex: 1;
        height: 21px;
        align-items: center;
      }

      > div:last-child {
        margin-left: 8px !important;
        margin-right: 0;
        position: relative;
      }
    }

    .date-picker {
      width: 50%;

      .SingleDatePicker {
        width: 100%;
      }

      .SingleDatePickerInput {
        display: flex;
        justify-content: space-between;

        input.DateInput_input {
          color: colors.$LightApplicationPrimaryText;
        }
      }
    }

    .time-picker {
      width: 50%;
      display: flex;
      justify-content: space-between;

      &__time {
        margin-top: 0;
      }
    }
  }
}

.dark-theme {
  .date-time-picker {
    .date-picker {
      input.DateInput_input {
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }
}
