@use '@iq/react-components/dist/colors.scss' as colors;

.theme-swtich-component {
  .avt-react-components--button.design-text.activity-primary {
    &.theme-dark,
    &.theme-light {
      color: colors.$Grey50;
    }
  }
  .theme-switch {
    .avt-react-components--icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .avt-react-components--button:nth-child(1) svg {
      margin-left: -1px;
    }
  }
}
