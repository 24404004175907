@use '@iq/react-components/dist/colors.scss' as colors;

.date-picker {
  .SingleDatePickerInput {
    display: flex;
    align-items: center;
    background-color: colors.$Grey0;
    border: 1px solid colors.$Grey20;
    border-radius: 0.3rem;
    padding: 0.466rem 0.5rem;

    &:focus-within {
      border-color: colors.$StatusBlue;
      box-shadow: 0 0 0 1px colors.$StatusBlue;
    }
  }

  .SingleDatePicker_picker {
    background-color: transparent;
  }

  .DateInput {
    width: 7rem;
    height: 20px;
  }

  input.DateInput_input {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    background-color: transparent;
    padding: 0;
    color: colors.$LightApplicationPrimaryText;
    caret-color: transparent;

    &__small {
      line-height: 1rem;
    }

    &__focused {
      border-bottom: none;
    }

    &:focus,
    &:focus-visible,
    &:active {
      outline: none;
    }

    &::-moz-selection {
      background-color: transparent;
      color: colors.$StatusBlue;
    }

    &::selection {
      background-color: transparent;
      color: colors.$StatusBlue;
    }
  }

  .SingleDatePickerInput_calendarIcon {
    cursor: pointer;
    padding: 0;
    margin: 0;
    line-height: 1rem;
    position: relative;

    .SingleDatePickerInput_calendarIcon_svg,
    svg {
      fill: colors.$LightApplicationPrimaryText;
    }

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      .SingleDatePickerInput_calendarIcon_svg,
      svg {
        fill: colors.$StatusBlue;
      }
    }
  }
}

.dark-theme {
  .date-picker {
    .SingleDatePickerInput {
      background-color: colors.$Grey90;
      border: 1px solid colors.$Grey70;

      &:focus-within {
        border-color: colors.$StatusBlue;
        box-shadow: 0 0 0 1px colors.$StatusBlue;
      }
    }

    .DateInput_input {
      color: colors.$DarkApplicationPrimaryText;
      background-color: colors.$Grey90;
    }

    .SingleDatePickerInput_calendarIcon {
      .SingleDatePickerInput_calendarIcon_svg,
      svg {
        fill: colors.$DarkApplicationPrimaryText;
      }

      &:focus,
      &:active {
        outline: none;
      }

      &:hover {
        .SingleDatePickerInput_calendarIcon_svg,
        svg {
          fill: colors.$StatusBlue;
        }
      }
    }

    input::-webkit-calendar-picker-indicator {
      filter: invert(90%);
    }
  }
}
