@use '@iq/react-components/dist/colors.scss' as colors;

.import-sites-modal {
  position: relative;
  p {
    margin: 10px 0;
  }
  button + p, input + p {
    margin-left: 15px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
}


.light-theme {
  .import-sites-modal {
    .muted-text {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme {
  .import-sites-modal {
    .muted-text {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}
