@use '@iq/react-components/dist/colors.scss' as colors;

.site-list-view-component {
  width: 100vw;
  height: calc(100vh - 67px);
  position: relative;
  overflow: hidden;
  top: calc(40px + 1.8rem);

  .sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 28rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .site-list {
      flex: 1 1 100%;
      padding-bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;

      .site {
        padding: 1.5rem 2rem;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid transparent;
        }

        .thumbnail {
          width: 2.8rem;
          height: 2.8rem;
          border-radius: 0.25rem;
          border: 3px solid transparent;
        }

        .info {
          margin-left: 1rem;

          .location {
            margin-top: 0.4rem;
            font-size: 0.9rem;
          }
        }

        .avt-react-components--icon,
        .site-item-menu {
          margin-left: auto;
          display: none;
        }

        &:hover {
          .avt-react-components--icon,
          .site-item-menu {
            display: block;
            opacity: 0.75;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .user-badge-component {
      margin-top: auto;
      padding: 2rem;
      box-sizing: border-box;

      .user-avatar {
        width: 2.8rem;
        height: 2.8rem;
        margin: 0 0.25rem;
      }

      .user-info-text {
        padding-left: 1rem;
      }
    }

    .panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid transparent;
      > div:nth-child(2) {
        margin-right: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .map-view {
    position: absolute;
    left: 28rem;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.dark-theme {
  .site-list-view-component {
    .sidebar {
      background: colors.$Grey85;
      color: colors.$DarkApplicationPrimaryText;

      .site-list {
        .site {
          color: colors.$DarkApplicationTertiaryText;
          border-color: colors.$DarkApplicationBorder;

          .thumbnail {
            border-color: colors.$DarkApplicationBorder;
          }

          .name {
            color: colors.$DarkApplicationPrimaryText;
          }
        }
      }

      .panel {
        border-bottom-color: colors.$DarkApplicationBorder;
      }
    }
  }
}

.light-theme {
  .site-list-view-component {
    .sidebar {
      background: colors.$LightApplicationPanelBackground;
      color: colors.$LightApplicationPrimaryText;

      .site-list {
        .site {
          color: colors.$LightApplicationTertiaryText;
          border-color: colors.$LightApplicationBorder;

          .thumbnail {
            border-color: colors.$LightApplicationBorder;
          }

          .name {
            color: colors.$LightApplicationPrimaryText;
          }
        }
      }

      .panel {
        border-bottom-color: colors.$LightApplicationBorder;
      }
    }
  }
}
