@use '@iq/react-components/dist/colors.scss' as colors;

.map-component {
  height: 100%;
  width: 100%;
  min-height: 250px; // must be greater than 2x padding in map
  min-width: 250px; // must be greater than 2x padding in map

  > div {
    height: 100% !important;
    width: 100% !important;
  }

  .site-marker {
    width: 24px;
    height: 24px;
    cursor: pointer;
    color: colors.$StatusRed;

    &.blue {
      color: colors.$StatusBlue;
    }

    &.green {
      color: colors.$StatusGreen;
    }

    &.yellow {
      color: colors.$StatusYellow;
    }

    &.red {
      color: colors.$StatusRed;
    }

    svg {
      pointer-events: none;
    }
  }

  .map-controls-wrapper {
    position: absolute;
    bottom: 8rem;
    right: 4rem;
  }

  .preview-marker {
    pointer-events: none;
    width: 400px;
    height: 400px;
  }

  .mapboxgl-popup-content {
    padding: 1.5rem;
    border-radius: 0.25rem;
  }

  .map-popup {
    display: flex;
    align-items: flex-start;

    .thumbnail {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 0.25rem;
      margin-right: 1.5rem;
      border: 1px solid transparent;
    }

    .site-name {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .property {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;

      .name {
        width: 6rem;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 0.5rem;
      }
    }
  }

  .no-sites-overlay {
    position: absolute;
    z-index: 10;
    display: flex;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: 0.3s;
  }
}

.dark-theme {
  .map-component {
    .mapboxgl-popup-content,
    .map-popup {
      background-color: colors.$Grey85;
      color: colors.$DarkApplicationPrimaryText;

      .thumbnail {
        background-color: colors.$DarkApplicationBorder;
        border-color: colors.$DarkApplicationBorder;
      }
    }

    .mapboxgl-popup-tip {
      border-top-color: colors.$DarkApplicationPanelBackground;
    }

    .value {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}

.light-theme {
  .map-component {
    .thumbnail {
      background-color: colors.$LightApplicationBorder;
    }
    .value {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}
