@use '@iq/react-components/dist/colors.scss' as colors;

.manage-integrations-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: visible;
  padding: 1rem 1rem 0.5rem;

  .list-container {
    padding-bottom: 1rem;
    background-color: colors.$Grey0;
    border-radius: .25rem;
    height: 100%;

    .list-item--header {
      display: flex;

      .avt-react-components--button {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        border-radius: 0;
        font-weight: 500;
        padding-left: 0;
      }
    }

    .sync-column {
      color: colors.$StatusGreen;
    }

    .ellipsed-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty-header-column {
      width: 7rem;
      display: flex;
    }
  }
}

.light-theme {
  .manage-integrations-body {
    background-color: colors.$Grey0;

    .list-item--header {
      .avt-react-components--button {
        background-color: colors.$LightApplicationBackground;
        color: colors.$LightApplicationPrimaryText;
      }
    }

    .empty-header-column {
      background-color: colors.$LightApplicationBackground;
    }
  }
}

.dark-theme {
  .integrations-header {
    .avt-react-components--input {
      &::placeholder {
        color: colors.$DarkApplicationSecondaryText;
      }
    }
  }

  .manage-integrations-body {
    background-color: colors.$DarkApplicationPanelBackground;
    border: 0.5px solid colors.$DarkApplicationInputBorder;

    .list-container {
      background-color: colors.$Grey90;

      .list-item--header {
        .avt-react-components--button {
          background-color: colors.$Grey85;
          color: colors.$DarkApplicationPrimaryText;
        }
      }

      .empty-header-column {
        background: colors.$Grey85;
      }
    }
  }
}
