@use '@iq/react-components/dist/colors.scss' as colors;

.settings-component {
  margin: 0 auto;
  padding-left: 4rem;
  padding-bottom: 4rem;

  .heading {
    margin: 4rem 2rem 1rem;
  }

  .item-group {
    padding: 2rem 4rem;
    width: calc(100% - 8rem);
    display: flex;
    flex-wrap: nowrap;

    &:nth-child(2) {
      margin-top: 4.5rem;
    }

    &--heading {
      padding: 1rem 2rem 2rem 0;
      font-size: 1.5rem;
      width: 8rem;
      flex-grow: 0;
      flex-shrink: 0;

      div {
        padding: 0.1rem 0.25rem;
        border-left: 5px solid colors.$StatusBlue;
      }
    }

    &--items {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 17rem;
    height: 14rem;
    margin: 1rem;
    border-radius: 0.2rem;
    cursor: pointer;

    color: colors.$Grey70;

    &--header {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      padding: 2rem 1.5rem;
    }

    &--icon {
      padding-right: 0.5rem;
      color: colors.$Grey80;
      box-sizing: border-box;
    }

    &--text {
      font-size: 1.15rem;
      flex: 0 1 auto;
      color: #000000;
    }

    &--description {
      padding: 0 1.5rem 2rem;
      font-size: 0.9rem;
      color: colors.$Grey50;
    }

    a {
      text-decoration: none;
      color: black;
    }

    a:visited {
      text-decoration: none;
      color: black;
    }
  }
}

.light-theme {
  .settings-component {
    .item-group {
      .item {
        background: colors.$LightApplicationPanelBackground;
        border: 1px solid #d2d2d2;
      }
    }
  }
}

.dark-theme {
  .settings-component {
    .item-group {
      .item {
        background: colors.$DarkApplicationPanelBackground;
        border: 1px solid #d2d2d2;

        .item--icon,
        .item--text {
          color: #ffffff;
        }
      }
    }
  }
}
