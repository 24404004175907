@use '@iq/react-components/dist/colors.scss' as colors;

.layout-import-panel {
  display: flex;
  flex-direction: column;

  .layout-file-input-error {
    color: #ff0000;
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
  }

  .layout-file-input-wrapper {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    &.hide {
      pointer-events: none;
      opacity: 0.4;
    }

    input[type='file'] {
      display: none;
    }

    .layout-file-input {
      display: flex;
      align-items: center;
      font-weight: 500;
      box-sizing: border-box;
      border: 1px solid transparent;
      white-space: nowrap;
      cursor: pointer;
      line-height: 1.3rem;
      border-radius: 0.25rem;
      height: 2.2rem;
      padding: 0 0.6rem;
      font-size: 1rem;
      background: colors.$UIBlue;
      color: #ffffff;
    }

    span {
      flex-grow: 1;
      line-height: normal;
      padding-left: 1rem;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .layout-list {
    margin-bottom: 2rem;
  }

  .layout-import-panel--actions {
    display: flex;
    justify-content: space-between;
  }
}
