@use '@iq/react-components/dist/colors.scss' as colors;

.file-table-component {
  .file-table-table {
    display: table;
    width: 100%;

    .file-table-headers,
    .file-table-row {
      display: table-row;
    }

    .table-header,
    .table-cell {
      display: table-cell;
      vertical-align: top;

      &.select-column {
        width: 1.5rem;
      }
    }

    .table-header {
      padding: 0.5rem 0 0.5rem 0.5rem;
      font-weight: 500;

      &.created-at-column {
        .avt-react-components--icon.xs {
          margin: 0 0.5rem;
        }
      }

      &.filename-column {
        .avt-react-components--icon.xs {
          margin-left: 5px;
        }
      }

      button {
        width: 100%;

        &:focus {
          outline: 0;
          box-shadow: none;
        }
        &:active:focus {
          outline: 0;
          box-shadow: none;
        }

        .hoverableIcon {
          color: transparent;
        }

        &:hover {
          .hoverableIcon {
            color: inherit;
            opacity: 0.5;
          }
        }
      }
    }

    .table-cell {
      padding: 0 0.5rem;

      &.select-column {
        padding-top: 0.7rem;
        width: 1.5rem;

        button {
          &:focus {
            outline: 0;
            box-shadow: none;
          }
          &:active:focus {
            outline: 0;
            box-shadow: none;
          }
        }

        .checkbox {
          cursor: pointer;

          &.checked {
            color: colors.$StatusBlue;
          }
        }
      }

      &.filename-column {
        .filetype-icon-component {
          width: 1.2rem;
          margin-right: 0.5rem;
          flex-shrink: 0;
        }

        .filename {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex: 1 1 100%;
          cursor: pointer;
          padding-top: 0.5rem;
        }

        .filename-text {
          word-break: break-all;
          white-space: normal;
          max-width: 100%;
          display: block;
          margin-right: 0.5rem;
        }
      }

      &.type-column {
        .type-badge {
          display: block;
          border-radius: 0.2rem;
          padding: 0.75rem 0;
        }
      }

      &.size-column {
        min-width: 5rem;
        white-space: nowrap;
        span {
          display: block;
          padding: 0.75rem 0;
        }
      }

      &.created-at-column {
        width: 5rem;
        span {
          display: block;
          padding: 0.75rem 0;
        }
      }

      &.actions-column {
        .header {
          padding: 0;
        }

        div:first-child {
          padding-top: 0.2rem;
        }
      }
    }
  }
}

.light-theme .file-table-component {
  .file-table-table {
    > :nth-child(odd) {
      background: colors.$Grey5;
    }
    > :nth-child(even) {
      background: none;
    }
  }

  .file-table-headers {
    :not(:last-child) {
      border-right: 0.2rem solid colors.$Grey0;
    }

    .table-header {
      color: colors.$LightApplicationSecondaryText;
    }
  }
}

.dark-theme .file-table-component {
  .file-table-table {
    > :nth-child(odd) {
      background: colors.$Grey85;
    }
    > :nth-child(even) {
      background: none;
    }
  }

  .file-table-headers {
    :not(:last-child) {
      border-right: 0.2rem solid colors.$Grey90;
    }

    .table-header {
      color: colors.$DarkApplicationSecondaryText;
    }
  }
}
// .icon-test {
//   margin: 0 0.5rem;
// }
