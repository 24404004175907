@use '@iq/react-components/dist/colors.scss' as colors;

.visualizations-panel-component {
  display: grid;
  grid-gap: 1rem;
  overflow: hidden;

  // Widths

  &.width-small {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

    .visualization-wrapper {
      &:not(:last-child) {
        padding-right: 1rem;
        border-right: 1px solid transparent;
      }
    }
  }

  &.width-medium-small {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }

  &.width-medium {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  &.width-large {
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  }

  // Heights

  .visualization-wrapper {
    height: 140px;
  }

  &.height-medium-small .visualization-wrapper {
    height: 170px;
  }

  &.height-medium .visualization-wrapper {
    height: 200px;
  }

  &.height-large .visualization-wrapper {
    height: 300px;
  }
}

.empty-visualization {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.dark-theme .visualizations-panel-component.width-small {
  .visualization-wrapper {
    border-color: colors.$Grey85;
  }
}

.light-theme .visualizations-panel-component.width-small {
  .visualization-wrapper {
    border-color: colors.$Grey10;
  }
}
