@use '@iq/react-components/dist/colors.scss' as colors;

.file-edit-modal {
  display: flex;
  flex-direction: column;
  min-width: 17rem;
  min-height: 300px;
  position: relative;
  justify-content: space-between;
  width: 100%;

  .file-upload-item-wrapper {
    overflow: scroll;
  }

  .bottom-toolbar {
    position: relative;

    .row {
      display: flex;
      margin: 5px 0;
      flex-direction: row;
      button {
        margin: 0 5px;
      }
    }

    .delete-confirm-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.light-theme {
  .file-edit-modal {
    .delete-confirm-overlay {
      background: colors.$LightApplicationBackground;
    }
  }
}

.dark-theme {
  .file-edit-modal {
    .delete-confirm-overlay {
      background: colors.$DarkApplicationBackground;
    }
  }
}
