@use '@iq/react-components/dist/colors.scss' as colors;

.inline-editor-component {
  display: flex;
  flex-direction: row;
  align-items: center;

  .controls {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      margin: 0 0.5rem;
    }
  }

  .error {
    color: colors.$StatusRed;
  }
}
