@use '@iq/react-components/dist/colors.scss' as colors;

.styled-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;

  height: 54px;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  &__header {
    padding: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: calc(100% - 2rem);
    height: 24px;

    &__label {
      display: flex;
      align-items: center;
      min-width: 9rem;
      margin-right: 1rem;

      > .type-label-component {
        width: 100%;
      }
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__actions {
      display: flex;
      align-items: center;
      margin-left: auto;

      > *:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__body {
    display: none;
    padding: 1rem;
    width: calc(100% - 2rem);
  }

  &.open {
    height: auto;
    padding-top: 0.75rem;

    > .styled-item__content > .styled-item__body {
      display: block;
    }

    .button-row {
      display: flex;
      align-items: center;

      button:not(:last-child) {
        margin-right: 1rem;
      }

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      &.spaced {
        justify-content: space-between;
      }
    }
  }
}

.dark-theme {
  .styled-item {
    background: colors.$Grey85;

    a {
      color: colors.$DarkApplicationTertiaryText;
    }
  }
}

.light-theme {
  .styled-item {
    background: colors.$LightApplicationPanelBackground;
    border: 1px solid colors.$LightApplicationBorder;

    a {
      color: colors.$LightApplicationTertiaryText;
    }
  }
}
