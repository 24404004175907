.panel-settings-modal {
  &__actions {
    justify-content: space-between;
    display: flex;
    margin-top: 2rem;
  }

  &__primary-buttons {
    display: flex;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 1rem;
    }
  }
}