@use '@iq/react-components/dist/colors.scss' as colors;

.list-item {
  width: 100%;
  display: flex;
  min-height: 3.4rem;

  &--clickable {
    cursor: pointer;
    flex-wrap: wrap;
  }

  &__col {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 0.2rem;
    padding: 0.45rem 1.2rem;
    box-sizing: border-box;
    line-height: 1.3rem;

    .avt-react-components--icon {
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    .blocked-item-container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .dropdown-menu {
      border-radius: 0.3rem;
      padding: 0.1rem 0.5rem;
      font-size: 0.9rem;
    }

    .blocked-item {
      display: inline-block;
      margin: 0.5rem 0.5rem 0 0;
      width: fit-content;
      border-radius: 0.3rem;
      padding: 0.1rem 0.5rem;
      font-size: 0.9rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 1.5rem;

      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  }

  .actions {
    display: flex;
    justify-content: space-evenly;
    width: 7rem;
    align-items: center;
    padding-top: 0.2rem;

    &--header {
      justify-content: flex-start;
      align-items: center;
      margin-right: 0;
      width: 7rem;
    }
  }

  &-accordion-content {
    width: 100%;
    display: flex;
  }
}

.list-item--header {
  height: 3.2rem;

  &__col {
    font-weight: 500;
    align-items: center;

    .avt-react-components--button.variation-with-icon-left .avt-react-components--icon,
    .avt-react-components--button.variation-with-icon-right .avt-react-components--icon {
      width: 12px;
      height: 12px;
    }
  }

  .avt-react-components--button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    font-weight: 500;
    padding: 0;
  }
}

.list-item--empty-list {
  width: 100%;
  min-height: 10rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.light-theme {
  .list-item {
    &:nth-child(even),
    &.list-item--header {
      .list-item__col,
      .actions {
        background-color: colors.$Grey5;

        .blocked-item,
        .dropdown-menu {
          background-color: colors.$Grey20;
        }
      }
    }

    &:nth-child(odd):not(.list-item--header) {
      .list-item__col,
      .actions {
        background-color: colors.$Grey0;

        .blocked-item,
        .dropdown-menu {
          background-color: colors.$Grey10;
        }
      }
    }
  }
}

.dark-theme {
  .list-container {
    border: 0.5px solid colors.$Grey75;
  }

  .list-item {
    &:nth-child(even),
    &.list-item--header {
      .list-item__col,
      .actions {
        background: colors.$Grey85;

        .blocked-item,
        .dropdown-menu {
          background-color: colors.$Grey90;
        }
      }
    }

    &:nth-child(odd):not(.list-item--header) {
      .list-item__col,
      .actions {
        background: colors.$Grey90;

        .blocked-item,
        .dropdown-menu {
          background-color: colors.$Grey80;
        }
      }
    }

    &.styled-item {
      background: rgba(#fff, 0.05);

      .json-editor-component {
        .prop-table .row,
        .key,
        .value {
          border: 1px solid colors.$Grey70;
        }
      }
    }
  }
}
