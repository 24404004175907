.hero-section-component {
  min-height: 15rem;
  height: 45vh;

  &.hidden {
    display: none;
  }

  &.full-height {
    height: calc(100vh - 80px);
  }

  &.align-height {
    .section-content {
      .column-component:last-child {
        height: 100%;

        .column-content {
          height: 100%;

          .panel-component.components-component-type .panel-header {
            margin: 0;
          }
        }
      }
    }
  }
}
