.no-grants-view-component {
  font-family: arial, sans-serif;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: url('/opt/src/images/login.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  color: #333;
  padding-top: 15vh;
  box-sizing: border-box;
  height: 100vh;

  .no-grants-view-panel {
    margin: 0 auto;
    padding: 1rem 2rem 2rem;
    background: #fff;
    max-width: 25rem;

    button {
      display: block;
      width: 100%;
      text-align: center;
      margin-top: 2rem;
    }

    p {
      margin: 0.5rem 0;
    }

    img {
      width: 75%;
      padding: 0.5rem 0;
    }
  }
}
