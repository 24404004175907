@use '@iq/react-components/dist/colors.scss' as colors;

.signals-header {
  width: 100%;
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem;
  }

  input {
    width: 15rem;

    &:nth-child(2) {
      min-height: 36px;
    }
  }

  .source-select,
  .filter-select {
    width: 15rem;
    font-weight: 400;
    font-size: 1rem;

    .popout-select {
      .avt-react-components--button {
        min-height: 36px;
      }
    }
  }
}

.signals-header,
.manage-variables-body {
  .avt-react-components--icon {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
}

.manage-variables-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0 1rem;

  .signals {
    margin: 1rem 0;
    font-size: 0.75rem;
  }

  .selection-toggler {
    display: flex;
    margin-bottom: 1rem;

    > :not(:last-child) {
      margin-right: 0.5rem;
    }

    .select-all-label {
      display: flex;
      align-items: center;
      height: 0rem;
      padding: 1rem 0rem;

      .select-all {
        cursor: pointer;

        &.checked {
          color: black;
        }
      }
    }
  }

  .list-container {
    background-color: #fff;
    border-radius: 0.25rem;

    .list-item--header {
      display: flex;
    }

    .ellipsed-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .empty-header-column {
      width: 7rem;
      display: flex;
    }
  }

  .list-item__col:nth-child(1) {
    justify-content: center;
  }

  .panel-pagination {
    margin: 1rem 0;
  }
}

.light-theme {
  .signals-header {
    .popout-select {
      .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
        background-color: colors.$Grey0;
        color: colors.$LightApplicationPrimaryText;
      }
    }
  }

  .manage-variables-body {
    background-color: colors.$Grey0;

    .list-item--header {
      .avt-react-components--button {
        background-color: colors.$LightApplicationBackground;
        color: colors.$LightApplicationPrimaryText;
      }
    }

    .empty-header-column {
      background-color: colors.$LightApplicationBackground;
    }
  }
}

.dark-theme {
  .signals-header {
    .avt-react-components--input {
      &::placeholder {
        color: colors.$DarkApplicationSecondaryText;
      }
    }

    .popout-select {
      .avt-react-components--button.design-outlined.activity-tertiary.popout-select--trigger {
        background-color: colors.$Grey90;
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }

  .manage-variables-body {
    background-color: colors.$DarkApplicationPanelBackground;
    border: 0.5px solid colors.$DarkApplicationInputBorder;

    .list-container {
      background-color: colors.$Grey90;

      .list-item--header {
        .avt-react-components--button {
          background-color: colors.$Grey85;
          color: colors.$DarkApplicationPrimaryText;
        }
      }

      .empty-header-column {
        background: colors.$Grey85;
      }
    }
  }
}
