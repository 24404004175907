@use 'sass:color';
@use '@iq/react-components/dist/colors.scss' as colors;

.file-detail {
  margin-bottom: 20px;
  position: relative;
  padding: 20px;
  border: 1px solid colors.$Grey10;

  .button-row {
    margin-top: 10;
    margin-bottom: 10;
    display: flex;
    flex-direction: row;

    button {
      margin-right: 5px;
    }
  }

  .item-uploaded-overlay,
  .item-uploading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .file-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: space-around;

    .file-info-row {
      display: flex;

      .error-text {
        padding: 0.5rem 0 0 0.5rem;
        font-size: 0.9rem;
        color: red;
      }
    }

    .filetype {
      .filetype-icon-component {
        width: 1.5rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
      }
    }

    .filename {
      font-size: 1.1rem;
      font-weight: 600;
      margin: auto 0.5rem auto 0;
      word-break: break-word;
    }

    .filesize {
      font-size: 1rem;
      margin: auto 0;
    }

    .delete-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
  }

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;

    label {
      font-size: 1rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      line-height: 200%;
      font-weight: bold;
      padding-bottom: 0.8rem;
    }

    .select-container {
      width: 100%;
    }

    button {
      display: inline-block;
      margin: auto 0 auto 0.25rem;
    }

    .error-text {
      padding-top: 0.8rem;
      font-size: 0.9rem;
      color: colors.$StatusRed;
    }
  }

  .confirm-delete-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    .row {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 50rem) {
    .row {
      flex-direction: column;
    }
  }
  @media (max-width: 30rem) {
    .file-info-row {
      flex-direction: column;
      div {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.light-theme .file-detail {
  .file-detail {
    .item-uploaded-overlay,
    .item-uploading-overlay,
    .confirm-delete-overlay {
      background: color.adjust(colors.$LightApplicationBackground, $alpha: 0.8);
    }
  }
}

.dark-theme .file-detail {
  border: 2px solid colors.$DarkApplicationBorder;

  .file-detail {
    .item-uploaded-overlay,
    .item-uploading-overlay,
    .confirm-delete-overlay {
      background: color.adjust(colors.$DarkApplicationBackground, $alpha: 0.8);
    }
  }
}
