@use '@iq/react-components/dist/colors.scss' as colors;

.loading-container {
  width: 100%;
  height: 100%;
  min-height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.model-upload-info {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin-bottom: 1rem;
  line-height: 1rem;

  .avt-react-components--icon {
    color: colors.$StatusBlue !important;
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 0.4rem;
    flex-shrink: 0;
  }

  &--hidden {
    display: none;
  }
}

.avt-react-components--button.design-regular.activity-primary {
  &.button-create {
    background-color: colors.$StatusBlue;

    &--basic {
      background-color: colors.$StatusBlue;
    }

    &--advanced {
      background-color: colors.$WizardYellow;
    }
  }
}

.light-theme {
  .model-upload-info {
    color: colors.$LightApplicationSecondaryText;
  }
}

.dark-theme {
  .model-upload-info {
    color: colors.$DarkApplicationSecondaryText;
  }
}
