@use '@iq/react-components/dist/colors.scss' as colors;

.input-title {
  text-transform: capitalize;
  font-weight: 500;
}

.input-tooltip-top-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tooltip-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  .avt-react-components--icon {
    margin-left: 0.5rem;
  }

  .description-list li:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .tooltip {
    max-width: 30rem;
    background-color: colors.$LightApplicationBackground;
  }
}

.toggle-active,
.input-tooltip-description {
  color: colors.$LightApplicationSecondaryText;
}

.toggle-inactive {
  color: colors.$Grey50;
}

.input-tooltip-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;

  .toggle-text {
    margin-left: 1rem;
    width: 4rem;
  }
}

.dark-theme {
  .toggle-active,
  .input-tooltip-description {
    color: colors.$DarkApplicationSecondaryText;
  }

  .tooltip-container {
    .tooltip {
      border: 1px solid colors.$DarkApplicationSecondaryText;
      background-color: colors.$DarkApplicationBackground;
    }
  }
}