.column-component {
  min-height: 100%;
  // height: 0;
  // overflow-y: hidden; changed to accommodate popout selects extending beyond last panel
  overflow-y: visible;
  max-width: 100%;

  .column-title {
    font-weight: 600;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .column-content {
    height: 100%;
    .panel-component:last-child {
      &.files-component-type {
        .drop-wrapper {
          min-height: 26rem;
        }
      }
    }
  }
}
