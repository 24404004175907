@use '@iq/react-components/dist/colors.scss' as colors;
@use '../../../../scss/_variables.scss' as vars;

.bookmark-panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 25%;
  min-width: 384px;
  max-width: 512px;
  padding: 1rem;
  border-radius: 0.25rem;
  animation: bookmark-panel-slideUp 0.25s forwards;
  box-sizing: border-box;
  z-index: 1;

  .bookmark-panel-title {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  form {
    > .row:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .avt-react-components--button {
      margin: 0 0.5rem;
    }
  }

  .column {
    display: flex;
    flex: 1;
    padding: 5px;
    flex-direction: column;
    max-width: 100%;
    box-sizing: border-box;
  }

  strong.label {
    margin-bottom: 0.2rem;
  }

  input,
  textarea {
    font-size: 1rem;
    border-radius: 3px;
    padding: 0.3rem;
  }

  .video-time-row {
    align-items: center;

    input {
      max-width: 50px;
    }
  }
}

.light-theme {
  .bookmark-panel {
    background-color: colors.$LightApplicationPanelBackground;
    box-shadow: vars.$BoxShadowLight;

    input,
    textarea {
      border: 1px solid colors.$LightApplicationBorder;
    }
  }
}

.dark-theme {
  .bookmark-panel {
    background-color: colors.$DarkApplicationPanelBackground;
    box-shadow: vars.$BoxShadowDark;

    input,
    textarea {
      border: 1px solid colors.$DarkApplicationBorder;
    }
  }
}

@keyframes bookmark-panel-slideUp {
  from {
    transform: translate(-50%, -25%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
