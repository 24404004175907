@use '@iq/react-components/dist/colors.scss' as colors;

.setup-wizard-wrapper {
  max-width: 90rem !important;
  height: 50rem;
  overflow: hidden;
  border-radius: 10px !important;
  container-type: size;

  .simple-modal-body {
    height: 100%;
    display: grid;
    grid-template: 'wizard-info wizard-component' 100% / 30% 70%;

    > .avt-react-components--icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }

  &.full .simple-modal-body {
    display: block;
  }
}

.setup-wizard {
  grid-area: wizard-info;
  display: flex;
  flex-direction: column;
}

.wizard-info {
  background-color: colors.$StatusBlue;
  padding: 4rem;

  &--advanced {
    background-color: colors.$WizardYellow;
    height: 100%;
    padding: 0;
  }

  &--basic {
    background-color: colors.$StatusBlue;
    height: 100%;
    padding: 0;
  }

  &__header {
    .cta-header {
      font-size: 2.5rem;
      line-height: 3rem;

      strong {
        font-weight: bold;
      }
    }

    &--basic,
    &--advanced {
      margin: 3rem 2rem 7rem;
      display: flex;
      align-items: center;

      svg {
        pointer-events: all;
        cursor: pointer;
      }

      .step-header {
        font-size: 1.75rem;
        margin-left: 0.5rem;
      }
    }

    &--advanced {
      background-color: colors.$WizardYellow;
    }
  }
}

.setup-wizard-component {
  grid-area: wizard-component;
  padding: 4rem;
  display: flex;
  flex-direction: column;

  &--advanced {
    .step-navigator__button-next {
      background-color: colors.$WizardYellow !important;
    }
  }

  .role-select-column {
    width: 100%;
  }

  .site-admin--member-view__content .dropdown-menu {
    width: 100%;

    .dropdown-menu--trigger .dropdown-menu--trigger-content-left {
      width: 8rem;
    }
  }
}

.wizard-info-init {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  height: 100%;

  p {
    line-height: 1.25rem;
  }

  .cta-image {
    height: 20rem;
    width: 100%;
  }

  .cta-image-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .avt-react-components--button {
    padding: 0rem 0.6rem;
    height: 2.2rem;
  }
}

.step-indicator-container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.dark-theme {
  .wizard-info__header {
    &--basic,
    &--advanced {
      color: colors.$LightApplicationPrimaryText;
    }
  }

  .cta-image {
    background-image: url('../../../../images/site-wizard-dark-mode.svg');
    background-repeat: no-repeat;
  }

  .wizard-info {
    .avt-react-components--button {
      color: white;
      background: colors.$DarkApplicationPanelBackground !important;
    }
  }
}

.light-theme {
  .wizard-info__header {
    &--basic,
    &--advanced {
      color: white;
    }
  }

  .cta-image {
    background-image: url('../../../../images/site-wizard.svg');
    background-repeat: no-repeat;
  }
  .wizard-info {
    .cta-header,
    p {
      color: white;
    }

    .avt-react-components--button {
      color: colors.$StatusBlue !important;
      background-color: colors.$Grey0;
    }
  }
}
