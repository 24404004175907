.file-link {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;

  button {
    font-weight: 400;
    line-height: 3;

    img {
      max-width: 3em;
      max-height: 3em;
      margin-right: 0.5rem;
      border: 2px solid transparent;
    }
  }
}
