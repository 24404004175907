@use '@iq/react-components/dist/colors.scss' as colors;

.wizard-step-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  &__header {
    width: 100%;

    h1 {
      font-size: 2rem;
      margin-bottom: 2rem;
    }

    &--wizard-select {
      font-weight: bold;
    }
  }
}

.wizard-step-main {
  &__top {
    width: 100%;
    display: flex;

    &--spaced {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    &--framed {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      border-radius: 0.5rem;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0 1rem;
    }
  }
}

.light-theme .wizard-step-main {
  &__body {
    &--framed {
      background-color: white;
    }
  }
}

.dark-theme .wizard-step-main {
  &__body {
    &--framed {
      background-color: colors.$DarkApplicationPanelBackground;
      border: 0.5px solid colors.$DarkApplicationInputBorder;
    }
  }
}
