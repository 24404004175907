.layout-item.styled-item {
  margin: auto;
  margin-bottom: 0.5rem;

  > .styled-item__content > .styled-item__header {
    .updated {
      opacity: 0.7;
      font-family: monospace;
      font-size: 0.9rem;
    }

    .avt-react-components--icon {
      transform: rotate(0);
      transition: transform 0.1s linear;
    }
  }

  &.open {
    > .styled-item__content > .styled-item__header {
      .avt-react-components--icon {
        transform: rotate(0.25turn);
      }
    }
  }
}
