@use '@iq/react-components/dist/colors.scss' as colors;

.create-model-wrapper {
  #component-select {
    margin-bottom: 1rem;
  }

  .avt-react-components--label {
    font-weight: 500;
  }
}

.invalid-file-container {
  .info {
    margin: 1rem 0;
  }

  .invalid-file {
    margin: 1rem 0;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}

.model-file-info {
  display: flex;
  background-color: colors.$StatusOrange;
  color: white;
  border-radius: 0.25rem;
  margin-top: 1.5rem;
  padding: 0.5rem;
  align-items: center;
  .avt-react-components--icon {
    margin: 0.25rem;
    margin-right: 0.5rem;
  }
}

.dark-theme {
  .rjsf {
    padding: 0 !important;
  }
}
