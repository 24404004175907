@use '@iq/react-components/dist/colors.scss' as colors;

.notifications--panel-toggler {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  .pill-component {
    height: 0.8rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.6rem;
    left: 0;
    padding: 0.2rem;

    .text {
      margin: 0;
      padding: 0 0.2rem;
    }
  }
}

.notifications--overlay {
  z-index: 1002;
  position: fixed;
  top: 1rem;
  left: 50vw;
  transform: translateX(-50%);
  width: 30rem;

  &:empty,
  &.hidden {
    display: none;
  }

  .collapser {
    padding-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .notification-wrapper {
    .avt-react-components--button {
      &.clear,
      &.expand {
        border-color: colors.$DarkApplicationPrimaryText;
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }
}

.dark-theme {
  .notifications--panel-toggler.muted {
    color: colors.$Grey50;
  }
  .notification-wrapper {
    .avt-react-components--button {
      &.clear,
      &.expand {
        border-color: colors.$DarkApplicationPrimaryText;
        color: colors.$DarkApplicationPrimaryText;
      }
    }
  }
}

.light-theme {
  .notifications--panel-toggler.muted {
    color: colors.$Grey50;
  }
}
